import axios from "axios";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";
const APIURL = "/api/chat/";

export function fetch({ commit }, id) {
  const method = "post";
  const data = { action: "get", id };
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, method, APIURL, data);
  }
  return new Promise((resolve, reject) => {
    axios({ method, url: APIURL, data })
      .then((resp) => {
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(
            `[ ${LOG_LVL_LIST.DEBUG} ]	`,
            APIURL,
            method,
            "SUCCESS",
            resp
          );
        }
        const output = resp.data;
        commit("FETCH_ALL", output);
        resolve();
      })
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(
            `[ ${LOG_LVL_LIST.ERROR} ]	`,
            APIURL,
            err,
            method,
            "RESPONSE",
            err.response
          );
        }
        reject();
      });
  });
}

export function send({ commit }, data) {
  return new Promise((resolve, reject) => {
    const method = "POST";
    if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
      console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, method, APIURL, data);
    }
    axios({ method, url: APIURL, data })
      .then((resp) => {
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(
            `[ ${LOG_LVL_LIST.DEBUG} ]	`,
            APIURL,
            method,
            "SUCCESS",
            resp
          );
        }
        const data = resp.data;
        commit("addMe", data);
        resolve();
      })
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(
            `[ ${LOG_LVL_LIST.ERROR} ]	`,
            APIURL,
            err,
            method,
            "RESPONSE",
            err.response
          );
        }
        reject();
      });
  });
}
