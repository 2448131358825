<template lang="pug">
  .view-wrap
    .view-block.view-block--two
      .view-block-icon
        svg(class='icon-link icon')
          use(xlink:href='@/assets/images/sprite/sprite-inline.svg#link')
      .view-block-title Ваша реферальная ссылка
      //- copy link field
      .get-link-field(:class="{'data-copied': dataCopied}")
        //- text to copy
        span.get-link-text-link(v-if="!dataCopied") {{ user.invite.link }}
        //- link copied message
        .link-copied-message(v-else)
          svg.icon.icon-check
            use(xlink:href="@/assets/images/sprite/sprite-inline.svg#check")
          span.link-copied-text Ссылка скопирована
        //- copy button
        button.get-link-btn(
          v-clipboard="user.invite.link"
          @success="handleClipboardSuccess"
          data-title="Скопировать ссылку"
        )
          svg.icon.icon-copy
            use(xlink:href="@/assets/images/sprite/sprite-inline.svg#copy")

    .view-block.view-block--one(
      v-for='(item, index) in blocks'
      :key="index"
      :class="'view-block--' + item.class"
      @click="$router.push('/' + item.link)"
    )
      .view-block-icon
        svg(:class="['icon-' + item.icon, 'icon' ]")
            use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + item.icon")
      .view-block-title {{ item.title }}
      .view-block-desc {{ item.desc }}
      .view-block-sub(v-if="item.sub") {{ item.sub }}

    .view-block.view-block--news(v-if="lastNews" @click="$router.push('/news')")
      .view-block-pic
        img(src="lastNews.img" alt="")
      .view-block-icon
        svg(class='icon-news icon')
          use(xlink:href='@/assets/images/sprite/sprite-inline.svg#news')
      .view-block-sub Свежая новость
      .view-block-title {{ lastNews.title }}
      .view-block--news-action
        .view-block--news-item
          svg(class='icon-eye icon')
            use(xlink:href='@/assets/images/sprite/sprite-inline.svg#eye')
          span {{ lastNews.show }}
        .view-block--news-item
          svg(class='icon-clock icon')
            use(xlink:href='@/assets/images/sprite/sprite-inline.svg#clock')
          span
            timeago(:datetime="new Date(lastNews.time)")

    .view-block.view-block--two
      .view-block-icon
        svg(class='icon-transaction icon')
          use(xlink:href='@/assets/images/sprite/sprite-inline.svg#transaction')
      .view-block-title Транзакции
      .view-block-empty(v-if="transactionsShort.length <= 0")
        img(src="@/assets/images/view-event.svg" alt="Транзакций нет")
        p У вас пока нет транзакций
      .view-block-lines(v-else)
        .view-block-line(v-for='(item, index) in transactionsShort' :key='item.id')
          .view-block-line-icon
            svg(:class="['icon-' + item.icon, 'icon' ]")
              use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + item.icon")
          .view-block-line-info
            p {{ item.title }}
            span {{ item.product }}
          .view-block-line-value(:class="{plus: item.isDebet}")
            | {{ item.isDebet ? '+' : '-' }} {{ balanceFormat(item.sum) }} ₽

    .view-block.view-block--two
      .view-block-icon
        svg(class='icon-bell icon')
          use(xlink:href='@/assets/images/sprite/sprite-inline.svg#bell')
      .view-block-title Последние события
      .view-block-empty(v-if="notifyArrayShort.length <= 0")
        p У вас пока нет уведомлений
      Notification(v-else v-for="( item, index) in notifyArrayShort" :key="index" v-bind="{index, ...item}")
</template>

<script>
import Notification from "/src/components/notifications/notification";
import { mapGetters } from "vuex";
import Utils from "/src/helper.js";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export default {
  components: { Notification },
  data() {
    return {
      dataCopied: false,
      pageName: "Обзор",
    };
  },
  methods: {
    balanceFormat: function (n) {
      return Utils.balanceFormat(n);
    },
    handleClipboardSuccess() {
      this.dataCopied = true;
      setTimeout(() => {
        this.dataCopied = false;
      }, 3000);
    },
    changeTitle(pagename) {
      this.$emit("changeTitle", pagename);
    },
    emitCloseMenu() {
      this.$emit("closeMenu");
    },
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("rank", ["rank"]),
    ...mapGetters("notifications", ["notifyArrayShort"]),
    ...mapGetters("news", ["newsArray"]),
    ...mapGetters("wallet", ["preview", "forecast", "transactionsShort"]),
    lastNews() {
      if (this.newsArray.length <= 0) {
        return null;
      }
      return this.newsArray[0];
    },
    rankIcon() {
      const rankItem = this.rank(this.user.rank);
      return rankItem ? rankItem.icon : this.rank("beginner").icon;
    },
    blocks() {
      const today = new Date(2022, this.forecast.month - 1, 1);
      const month = today.toLocaleString("ru-RU", { month: "long" });
      return [
        {
          class: "orange",
          icon: "achievement",
          title: "Ранг",
          desc: this.rank(this.user.rank)
            ? this.rank(this.user.rank).title
            : this.rank("beginner").title,
          link: "rating",
        },
        {
          class: "green",
          icon: "visitor",
          title: "Посещений",
          desc: this.balanceFormat(this.user.visits) || 0,
          link: "statistics",
        },
        {
          class: "blue",
          icon: "group",
          title: "Привлечено пользователей",
          desc: this.balanceFormat(this.user.invite.count) || 0,
          sub: this.user.invite.lastuser
            ? "Новый пользователь: " + this.user.invite.lastuser
            : "",
          link: "structure",
        },
        {
          class: "purple",
          icon: "wallet",
          title: "Кошелёк",
          desc: this.preview.balance,
          sub: Number.isInteger(this.forecast.month)
            ? `Прогноз на ${month}: ${this.preview.forecast}`
            : "",
          link: "wallet",
        },
      ];
    },
  },
  created() {
    this.emitCloseMenu();
    Promise.all([
      this.$store.dispatch("updateUser"),
      this.$store.dispatch("notifications/fetch"),
    ]).catch((err) => {});
    Promise.all([
      this.$store.dispatch("wallet/fetch"),
      this.$store.dispatch("news/fetch"),
    ])
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(
            `[ ${LOG_LVL_LIST.ERROR} ]	`,
            "update user data error",
            err
          );
        }
      })
      .finally(() => {
        this.$store.commit("loaderOff");
      });
  },
  beforeRouteEnter(to, from, next) {
    document.title = to.meta.title;
    next((vm) => {
      vm.changeTitle(vm.pageName);
    });
  },
};
</script>
