<template lang="pug">
    .details
        .details-item( v-for="( typeItem, index ) in types" :key="index")
          .details-item-desc
              p.details-item-title {{ typeItem.title }}
              p.details-item-subtitle Минимальная выплата
              p.details-item-value {{ typeItem.min }} ₽
              p.details-item-subtitle Комиссия за вывод
              p.details-item-value {{ typeItem.tax }}
              p.details-item-subtitle Срок выплаты
              p.details-item-value {{ typeItem.lag }}
          svg(:class="['icon-' + typeItem.icon, 'icon' ]")
              use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + typeItem.icon")
          .details-edit(v-if="accountBytype(typeItem.type)")
              .details-edit-btn(@click="edit(accountBytype(typeItem.type).id)")
                  svg(class="icon icon-pen")
                      use(xlink:href="@/assets/images/sprite/sprite-inline.svg#pen")
                  | Редактировать
          .details-edit(v-else)
            .details-edit-btn(@click="addAccount(index)")
                svg.icon.icon-plus
                    use(xlink:href="@/assets/images/sprite/sprite-inline.svg#plus")
                | Добавить

</template>

<script>
import { mapGetters } from "vuex";
const STORE = "wallet";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(STORE, ["accountList", "accountBytype", "types"]),
    isAccountList() {
      return Array.isArray(this.accountList) && this.accountList.length > 0;
    },
  },
  methods: {
    edit(id) {
      this.$router.push("/wallet/details-edit/" + id);
    },
    addAccount(index) {
      this.$router.push("/wallet/details-create/" + index);
    },
  },
  created() {
    this.$store
      .dispatch(`${STORE}/getByKey`, "types")
      .then(() => {
        this.$store
          .dispatch(`${STORE}/getByKey`, "accountList")
          .catch((err) => {
            if (isLogLevel(LOG_LVL_LIST.ERROR)) {
              console.log(`[ ${LOG_LVL_LIST.ERROR} ]	`, STORE, "fetch", err);
            }
          })
          .finally(() => {
            this.$store.commit("loaderOff");
          });
      })
      .catch(() => {});
  },
  mounted() {},
};
</script>

<style></style>
