import axios from "axios";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";
const APIURL = "/api/deals/";

export function fetch({ commit }, { filters, more = false }) {
  const method = "POST";
  const data = { ...filters };
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, method, APIURL, filters, more);
  }
  return new Promise((resolve, reject) => {
    axios({ method, url: APIURL, data })
      .then((resp) => {
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(
            `[ ${LOG_LVL_LIST.DEBUG} ]	`,
            APIURL,
            method,
            "SUCCESS",
            resp
          );
        }
        if (more) {
          commit("ADD", resp.data);
        } else {
          commit("FETCH_ALL", resp.data);
        }
        resolve();
      })
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(
            `[ ${LOG_LVL_LIST.ERROR} ]	`,
            APIURL,
            err,
            method,
            "RESPONSE",
            err.response
          );
        }
        reject();
      });
  });
}
