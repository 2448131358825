import Vue from "vue";

import VueRouter from "vue-router";
import Main from "@/views/Main.vue";

import store from "@/store/index.js";

// authorization

// account login process
import LogIn from "@/views/LogIn";
import LogInForm from "@/components/login-reg/LogInForm";
import LogInEmail1 from "@/components/login-reg/LogInEmail1";
import LogInEmail2 from "@/components/login-reg/LogInEmail2";
import LogInPhone1 from "@/components/login-reg/LogInPhone1";
import LogInPhone2 from "@/components/login-reg/LogInPhone2";

// account registration process
import Registration from "@/views/Registration.vue";
import RegForm from "@/components/login-reg/RegForm";
import RegForm1 from "@/components/login-reg/RegForm1";
import RegForm2 from "@/components/login-reg/RegForm2";

// password recovery
import PwdRecovery from "@/components/login-reg/PwdRecovery";
import CheckEmail from "@/components/login-reg/CheckEmail.vue";

// /authorization
import NotFound from "@/views/404.vue";
import Agreement from "@/views/Agreement.vue";
import News from "@/views/News.vue";
import Promo from "@/views/Promo";
import Achievements from "../views/Achievements.vue";
import Rating from "../views/Rating.vue";
import Notifications from "../views/Notifications.vue";
import NotificationOne from "../components/NotificationOne.vue";
import View from "../views/View.vue";
import Statistics from "../views/Statistics.vue";
import Deals from "@/views/Deals.vue";

// programs
import Programs from "@/views/Programs";
import ProgramTabs from "@/components/programs/ProgramTabs";
import Products from "@/components/programs/Products";
import Applications from "@/components/programs/Applications";
import Application from "@/components/programs/Application";
import AppCompleted from "@/components/programs/AppCompleted";

// support
import Support from "@/views/Support.vue";
import SupportTabs from "@/components/support/SupportTabs";
import MyFAQ from "@/components/support/MyFAQ.vue";
import FAQ from "@/components/support/FAQ.vue";
import SupportForm from "@/components/support/SupportForm.vue";
import SupportChat from "@/components/support/SupportChat.vue";
// /support

// structure
import Structure from "@/views/Structure.vue";
// /structure

// profile
import Profile from "@/views/Profile.vue";
import Settings from "@/components/profile/Settings.vue";
import Account from "@/components/profile/Account.vue";
import PersonalityEditing from "@/components/profile/PersonalityEditing";
import PersonalityEditingStep1 from "@/components/profile/PersonalityEditingStep1.vue";
import PersonalityEditingStep2 from "@/components/profile/PersonalityEditingStep2.vue";
import PersonalityEditingStep3 from "@/components/profile/PersonalityEditingStep3.vue";
import PersonalityEditSuccess from "@/components/profile/PersonalityEditSuccess.vue";
import AddressEditStep1 from "@/components/profile/AddressEditStep1.vue";
import AddressEditStep2 from "@/components/profile/AddressEditStep2.vue";
import AddressEditSuccess from "@/components/profile/AddressEditSuccess.vue";
import SettingsEmail from "@/components/profile/SettingsEmail.vue";
import SettingsPhone from "@/components/profile/SettingsPhone";
import SettingsPassword from "@/components/profile/SettingsPassword";
// /profile

// wallet
import Wallet from "@/views/Wallet";
import WalletTabs from "@/components/wallet/WalletTabs";
import Balance from "@/components/wallet/Balance";
import Payments from "@/components/wallet/Payments";
import Details from "@/components/wallet/Details";
import DetailsEdit from "@/components/wallet/DetailsEdit";
// /wallet

Vue.use(VueRouter);

const routes = [
  // Пользовательское соглашение
  {
    path: "/agreement",
    name: "Agreement",
    component: Agreement,
    meta: { title: "Пользовательское соглашение" },
  },
  // Main
  {
    path: "/",
    // name: 'MainRouter',
    component: Main,
    meta: {
      requiresAuth: process.env.NODE_ENV === "production",
    },
    children: [
      {
        path: "",
        name: "View",
        component: View,
        meta: { title: "Обзор" },
      },
      {
        path: "profile",
        component: Profile,
        children: [
          {
            path: "",
            component: Account,
            meta: { title: "Профиль" },
          },
          {
            path: "settings",
            component: Settings,
            meta: { title: "Настройки" },
          },
        ],
      },
      {
        path: "person-editing",
        component: PersonalityEditing,
        meta: { title: "Редактирование профиля" },
      },
      {
        path: "person-edit-step-1",
        component: PersonalityEditingStep1,
        meta: { title: "Редактирование профиля" },
      },
      {
        path: "person-edit-step-2",
        component: PersonalityEditingStep2,
        meta: { title: "Редактирование профиля" },
      },
      {
        path: "person-edit-step-3",
        component: PersonalityEditingStep3,
        meta: { title: "Редактирование профиля" },
      },
      {
        path: "person-edit-success",
        component: PersonalityEditSuccess,
        meta: { title: "Редактирование профиля" },
      },
      {
        path: "address-edit-step-1",
        component: AddressEditStep1,
        meta: { title: "Редактирование профиля" },
      },
      {
        path: "address-edit-step-2",
        component: AddressEditStep2,
        meta: { title: "Редактирование профиля" },
      },
      {
        path: "address-edit-success",
        component: AddressEditSuccess,
        meta: { title: "Редактирование профиля" },
      },
      {
        path: "settings-email",
        component: SettingsEmail,
        meta: { title: "Редактирование профиля" },
      },
      {
        path: "settings-phone",
        component: SettingsPhone,
        meta: { title: "Редактирование профиля" },
      },
      {
        path: "settings-password",
        component: SettingsPassword,
        meta: { title: "Редактирование профиля" },
      },
      {
        path: "promo",
        component: Promo,
        meta: { title: "Промо" },
      },
      {
        path: "news",
        component: News,
        meta: { title: "Лента" },
      },
      {
        path: "support",
        component: Support,
        meta: { title: "Поддержка" },
        children: [
          {
            path: "",
            component: FAQ,
            meta: { title: "Поддержка - Часто задаваемые вопросы" },
          },
          {
            path: "questions",
            component: MyFAQ,
            meta: { title: "Поддержка - Мои вопросы" },
          },
        ],
      },
      {
        path: "ask-question",
        component: SupportForm,
        meta: { title: "Создать вопрос" },
      },
      {
        path: "questions/:id",
        component: SupportChat,
        meta: { title: "Вопрос" },
      },
      {
        path: "structure",
        component: Structure,
        meta: { title: "Моя структура" },
      },
      {
        path: "achievements",
        name: "Achievements",
        component: Achievements,
        meta: { title: "Достижения" },
      },
      {
        path: "rating",
        name: "Rating",
        component: Rating,
        meta: { title: "Рейтинги" },
      },
      {
        path: "notifications",
        name: "Notifications",
        component: Notifications,
        meta: { title: "Уведомления" },
      },
      {
        path: "notification/:id",
        name: "NotificationOne",
        component: NotificationOne,
        meta: { title: "Уведомления" },
      },
      {
        path: "wallet",
        component: Wallet,
        meta: { title: "Кошелек" },
        children: [
          {
            path: "",
            redirect: "balance",
            component: WalletTabs,
            children: [
              {
                path: "balance",
                component: Balance,
                meta: { title: "Баланс" },
              },
              {
                path: "payments",
                component: Payments,
                meta: { title: "Выплаты" },
              },
              {
                path: "details",
                component: Details,
                meta: { title: "Реквизиты" },
              },
            ],
          },
          {
            path: "details-create/:id",
            component: DetailsEdit,
            meta: { title: "Добавить реквизиты" },
          },
          {
            path: "details-edit/:id",
            component: DetailsEdit,
            meta: { title: "Реквизиты редактирование" },
          },
        ],
      },
      {
        path: "programs",
        component: Programs,
        meta: { title: "Программы" },
        children: [
          {
            path: "",
            component: ProgramTabs,
            children: [
              {
                path: "",
                component: Products,
                meta: { title: "Продукты" },
              },
              {
                path: "applications",
                component: Applications,
                meta: { title: "Заявки" },
              },
            ],
          },
          {
            path: "application/:id",
            component: Application,
            meta: { title: "Оформление заявки" },
          },
          {
            path: "application-completed",
            component: AppCompleted,
            meta: { title: "Заявка оформлена" },
          },
        ],
      },
      {
        path: "statistics",
        component: Statistics,
        meta: { title: "Статистика" },
      },
      {
        path: "deals",
        component: Deals,
        meta: { title: "Сделки" },
      },
    ],
  },
  // /login
  {
    path: "/login",
    component: LogIn,
    meta: {
      requiresNoAuth: process.env.NODE_ENV === "production",
    },
    // meta: { title: 'Вход' },
    children: [
      {
        path: "",
        component: LogInForm,
        meta: { title: "Вход" },
      },
      {
        path: "/login-email1",
        component: LogInEmail1,
        meta: { title: "Вход" },
      },
      {
        path: "/login-email2",
        component: LogInEmail2,
        meta: { title: "Вход" },
      },
      {
        path: "/login-phone1",
        component: LogInPhone1,
        meta: { title: "Вход" },
      },
      {
        path: "/login-phone2",
        component: LogInPhone2,
        meta: { title: "Вход" },
      },
    ],
  },
  // /invite/:id
  {
    path: "/invite/:id",
    component: Registration,
    meta: {
      requiresNoAuth: process.env.NODE_ENV === "production",
    },
    // meta: { title: 'Вход' },
    children: [
      {
        path: "/",
        component: RegForm,
        meta: { title: "Регистрация" },
      },
    ],
  },
  // /registration
  {
    path: "/registration",
    component: Registration,
    meta: {
      requiresNoAuth: process.env.NODE_ENV === "production",
    },
    // meta: { title: 'Вход' },
    children: [
      {
        path: "/",
        component: RegForm,
        meta: { title: "Регистрация" },
      },
      {
        path: "/registration-form",
        component: RegForm1,
        meta: { title: "Регистрация" },
      },
      {
        path: "/registration-form-code",
        component: RegForm2,
        meta: { title: "Регистрация" },
      },
    ],
  },
  // /pwd-recovery
  {
    path: "/pwd-recovery",
    component: PwdRecovery,
    meta: {
      title: "Восстановление пароля",
      requiresAuth: true,
    },
  },
  // 404
  { path: "*", component: NotFound, meta: { title: "Страница не найдена" } },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  // linkActiveClass: 'active-link-test',
});

// router.beforeEach((to, from, next) => {
//   store.commit("loaderOn");
//   next();
// });

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  // store.commit("loaderOn");
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      store.commit("loaderOn");
      next();
      return;
    }
    next("/login");
  } else if (to.matched.some((record) => record.meta.requiresNoAuth)) {
    if (store.getters.isLoggedIn) {
      next("/");
      return;
    }
    next();
  } else {
    next();
  }
});

export default router;
