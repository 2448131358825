<template lang="pug">

    .banners(v-if="banners")
        .banners-head
            .banners-head-filters
                select.banners-select.select-item(v-model="selectedBanner")
                    option(v-for="(item, index) in banners.items" :key="index" :value="index") {{ item.name }}
                select.banners-select.select-item(v-model="selectedSize" v-if="bannerSelect.sizes")
                    option(v-for="(item, index) in bannerSelect.sizes" :key="index" :value="index") {{ (`${item.w}x${item.h}`) }}
            // {{{
            a.button.banners-download-btn(
                v-if="banners.archive"
                :href="banners.archive"
                download
            )
                svg.icon.icon-download
                    use(xlink:href="@/assets/images/sprite/sprite-inline.svg#download")
                | Скачать все баннеры
            //}}}
        .banners-content
            .banners-picture.kw-fix(:style="{ width: bannerSizeSelect.w + 'px', height: bannerSizeSelect.h + 'px' }")
                img(:src="bannerSizeSelect.file" :alt="bannerSelect.name + '-' + bannerSizeSelect.size")

            .banners-code.promo-code(:class="{'data-copied': dataCopied}")
                code.promo-code-field(v-if="!dataCopied") {{ bannerSizeSelect.code }}
                .code-copied-message(v-else)
                    svg.icon.icon-check
                        use(xlink:href="@/assets/images/sprite/sprite-inline.svg#check")
                    span.code-copied-text Код скопирован
                button.promo-code-copy-btn(
                    data-title='Скопировать код'
                    v-clipboard="bannerSizeSelect.code"
                    @success="handleClipboardSuccess"
                )
                    svg.icon.icon-copy
                        use(xlink:href="@/assets/images/sprite/sprite-inline.svg#copy")
        // {{{
        .banners-links.promo-instruction-links
            .banners-links-item.promo-instruction-item(v-if="banners.manual")
                span
                    svg.icon.icon-tutorial
                        use(xlink:href="@/assets/images/sprite/sprite-inline.svg#tutorial")
                a.link(:href="banners.manual" target="_blank") Руководство по использованию
            .banners-links-item.promo-instruction-item(v-if="banners.rules")
                span
                    svg.icon.icon-sheet
                        use(xlink:href="@/assets/images/sprite/sprite-inline.svg#sheet")
                a.link(:href="banners.rules" target="_blank") Правила проведения рекламных кампаний
        //}}}
</template>

<script>
const STORE = "promo";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      selectedBanner: 0,
      selectedSize: 0,
      dataCopied: false,
    };
  },
  computed: {
    ...mapGetters(STORE, ["banners"]),
    bannerSelect() {
      return this.banners.items[this.selectedBanner];
    },
    bannerSizeSelect() {
      return this.bannerSelect.sizes[this.selectedSize];
    },
  },
  watch: {
    selectedBanner() {
      this.selectedSize = 0;
    },
  },
  methods: {
    handleClipboardSuccess() {
      this.dataCopied = true;
      setTimeout(() => {
        this.dataCopied = false;
      }, 3000);
    },
  },
};
</script>

<style lang="scss">
.banners-picture {
  &.kw-fix {
    max-width: unset;
    img {
      max-width: 100%;
    }
  }
}
</style>
