<template lang="pug">
  .structure-list(v-if="levelArray.length > 0")
    .structure-list-block(v-for="(level, index) of levelArray" :key="index")
      .structure-list-title Уровень {{index + 1}}
      .structure-list-table
        //- table head {{{
        .structure-list-table-thead(v-if="!isMobile")
          .structure-list-table-th
            .structure-list-table-th-wrap
              svg.icon.icon-person
                use(xlink:href="@/assets/images/sprite/sprite-inline.svg#person")
              span Имя
          .structure-list-table-th
            .structure-list-table-th-wrap
              svg.icon.icon-star
                use(xlink:href="@/assets/images/sprite/sprite-inline.svg#star")
              span Ранг
          .structure-list-table-th
            .structure-list-table-th-wrap
              svg.icon.icon-person
                use(xlink:href="@/assets/images/sprite/sprite-inline.svg#person")
              span Пригласивший
          .structure-list-table-th
            .structure-list-table-th-wrap
              svg.icon.icon-group
                use(xlink:href="@/assets/images/sprite/sprite-inline.svg#group")
              span Привёл
        // }}}
        //- table body
        .structure-list-table-tbody
          template(v-for="(child, trIndex) of level")
            //- table head {{{
            .structure-list-table-thead(v-if="isMobile")
              .structure-list-table-th
                .structure-list-table-th-wrap
                  svg.icon.icon-person
                    use(xlink:href="@/assets/images/sprite/sprite-inline.svg#person")
                  span Имя
              .structure-list-table-th
                .structure-list-table-th-wrap
                  svg.icon.icon-star
                    use(xlink:href="@/assets/images/sprite/sprite-inline.svg#star")
                  span Ранг
              .structure-list-table-th
                .structure-list-table-th-wrap
                  svg.icon.icon-person
                    use(xlink:href="@/assets/images/sprite/sprite-inline.svg#person")
                  span Пригласивший
              .structure-list-table-th
                .structure-list-table-th-wrap
                  svg.icon.icon-group
                    use(xlink:href="@/assets/images/sprite/sprite-inline.svg#group")
                  span Привёл
            // }}}
            //- table rows
            .structure-list-table-tr
              .structure-list-table-td
                .structure-list-table-td-wrap
                  .structure-list-table-pic(:style="{background: child.iconColor}") {{ child.avatar ? '' : getFirstUserLetter(child.name) }}
                    img(v-if="child.avatar" :src="child.avatar" :alt="getFirstUserLetter(child.name)")
                  span {{ child.name }}
              .structure-list-table-td(:class="`structure-list-table-td--${rank(child.rank).key}`")
                .structure-list-table-td-wrap
                  svg(:class="['icon', `icon-` + rank(child.rank).icon]")
                    use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + rank(child.rank).icon")
                  span {{ rank(child.rank).title }}
              .structure-list-table-td
                .structure-list-table-td-wrap(v-if="child.parent && child.parent.name")
                  .structure-list-table-pic(:style="{background: child.parent.iconColor}") {{ child.parent.avatar ? '' : getFirstUserLetter(child.parent.name) }}
                    img(v-if="child.parent.avatar" :src="child.parent.avatar" :alt="getFirstUserLetter(child.parent.name)")
                  span {{ child.parent.name }}
              .structure-list-table-td
                .structure-list-table-td-wrap
                  span {{ leedCount(child) }}

  <div class="structure-empty" v-else>
    <img alt="structure empty" src="@/assets/images/structure-empty.svg" />
    <span>Здесь появится ваша структура</span>
  </div>

</template>

<script>
import onResize from "@/mixins/onResize";
const STORE = "structure";
import { mapGetters } from "vuex";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export default {
  name: "StructureList",
  mixins: [onResize],
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    getFirstUserLetter(str) {
      return str ? str.slice(0, 1).toUpperCase() : "";
    },
    leedCount(item) {
      return item?.children && Array.isArray(item.children)
        ? item.children.length
        : 0;
    },
  },
  computed: {
    ...mapGetters("rank", ["rank"]),
    ...mapGetters(STORE, ["levelArray"]),
  },
  created() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  mounted() {
    if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
      console.log(
        `[ ${LOG_LVL_LIST.DEBUG} ]	`,
        "structure list",
        this.levelArray
      );
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
