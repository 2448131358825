export default {
  data() {
    return {};
  },
  methods: {
    compareDate(a, b) {
      let date1 = new Date(a.date);
      let date2 = new Date(b.date);
      // new are first
      if (date1 < date2) return 1;
      if (date1 > date2) return -1;
      return 0;
      // old are first
      // if (date1 < date2) return -1;
      // if (date1 > date2) return 1;
      // return 0;
    },
  },
};
