export const LOG_LVL_CODES = Object.freeze({
  NONE: -1,
  ERROR: 10,
  WARN: 20,
  DEBUG: 30,
  INFO: 40,
  ALL: 100,
});
export const LOG_LVL_LIST = Object.assign(
  {},
  ...Object.entries({ ...LOG_LVL_CODES }).map(([a, b]) => ({ [a]: a }))
);

export const LOGLEVELCODE = LOG_LVL_CODES[process.env.VUE_APP_LOGLEVEL];
export const LOGLEVEL = process.env.VUE_APP_LOGLEVEL;

// console.log("LOGLEVEL: ", LOGLEVEL);
// console.log("LOGLEVELCODE : ", LOGLEVELCODE);

export const isLogLevel = function (level = LOGLEVEL) {
  const checkLevelCode = LOG_LVL_CODES[level];
  return LOGLEVELCODE >= checkLevelCode && checkLevelCode > 0;
};
