<script>
import { Pie, mixins } from "vue-chartjs";
import ChartDataLabels from "chartjs-plugin-datalabels";
const { reactiveProp } = mixins;

export default {
  extends: Pie,
  mixins: [reactiveProp],
  props: {
    options: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    // Chart.plugins.register(ChartDataLabels);
    this.addPlugin(ChartDataLabels);
    this.renderChart(this.chartData, this.options);
  },
};
</script>
