<template lang="pug">
  .social
    a.social-link(v-if="vk" :href="vk" target="_blank")
      svg.icon.icon-vkontakte
        use(xlink:href="@/assets/images/sprite/sprite-inline.svg#vkontakte")
    a.social-link(v-if="tg" :href="tg" target="_blank")
      svg.icon.icon-telegram
        use(xlink:href="@/assets/images/sprite/sprite-inline.svg#telegram")
    a.social-link(v-if="fb" :href="fb" target="_blank")
      svg.icon.icon-facebook
        use(xlink:href="@/assets/images/sprite/sprite-inline.svg#facebook")
    a.social-link(v-if="inst" :href="inst" target="_blank")
      svg.icon.icon-instagram
        use(xlink:href="@/assets/images/sprite/sprite-inline.svg#instagram")

</template>

<script>
const STORE = "social";
import { mapGetters } from "vuex";

export default {
  name: "Social",
  computed: {
    ...mapGetters(STORE, ["vk", "fb", "tg", "inst"]),
  },
  created() {
    this.$store.dispatch(`${STORE}/fetch`);
  },
};
</script>

<style scoped></style>
