<template>
  <div class="structure-map" ref="structureMap">
    <div class="structure-map-btns">
      <div class="structure-map-zoom-btns">
        <button
          @click="$refs.tree.zoomIn()"
          class="structure-map-btn structure-map-btn--zoom-plus"
        >
          <svg class="icon icon-plus">
            <use xlink:href="@/assets/images/sprite/sprite-inline.svg#plus" />
          </svg>
        </button>
        <button
          @click="$refs.tree.zoomOut()"
          class="structure-map-btn structure-map-btn--zoom-minus"
        >
          <svg class="icon icon-minus">
            <use xlink:href="@/assets/images/sprite/sprite-inline.svg#minus" />
          </svg>
        </button>
      </div>

      <button
        @click="$refs.tree.restoreScale()"
        class="structure-map-btn structure-map-btn--def-scale"
      >
        <svg class="icon icon-sight">
          <use xlink:href="@/assets/images/sprite/sprite-inline.svg#sight" />
        </svg>
      </button>
      <div class="structure-map-screen-btns">
        <button
          v-if="!isFullScreen"
          @click="openFullscreen($refs.structureMap)"
          class="structure-map-btn"
        >
          <svg class="icon icon-fullscreen">
            <use
              xlink:href="@/assets/images/sprite/sprite-inline.svg#fullscreen"
            />
          </svg>
        </button>
        <button
          v-if="isFullScreen"
          @click="closeFullscreen"
          class="structure-map-btn"
        >
          <svg class="icon icon-smallscreen">
            <use
              xlink:href="@/assets/images/sprite/sprite-inline.svg#smallscreen"
            />
          </svg>
        </button>
      </div>
    </div>
    <vue-tree
      v-if="structure"
      ref="tree"
      style="width: 100%; height: 100%"
      :dataset="structure"
      :config="treeConfig"
      linkStyle="straight"
    >
      <template v-slot:node="{ node, collapsed }">
        <div
          class="user-card"
          :style="{ border: collapsed ? '2px solid #cf894e' : '' }"
        >
          <div class="user-card-photo-wrap">
            <img
              v-if="node.avatar"
              class="user-card-photo"
              :src="node.avatar"
              alt="фото пользователя"
            />
            <span
              v-else
              class="user-card-first-letter"
              :style="{ backgroundColor: node.iconColor }"
            >
              {{ getFirstUserLetter(node.name) }}
            </span>
          </div>
          <div :class="['user-card-info', rank(node.rank).key]">
            <svg :class="['icon', `icon-` + rank(node.rank).icon]">
              <use
                :xlink:href="
                  require('@/assets/images/sprite/sprite-inline.svg') +
                  '#' +
                  rank(node.rank).icon
                "
              />
            </svg>
            <span class="user-card-name">{{ node.name }}</span>
          </div>
        </div>
      </template>
    </vue-tree>

    <div class="structure-empty" v-else>
      <img alt="structure empty" src="@/assets/images/structure-empty.svg" />
      <span>Здесь появится ваша структура</span>
    </div>
    <!-- if fullscreen is not supported -->
    <transition name="popup-fade">
      <div v-if="fscreenMsg" class="popup popup-fscreen">
        <p class="message fscreen-message">
          Для перехода в полноэкранный режим воспользуйтесь встроенной функцией
          системы
        </p>
        <div @click="fscreenMsg = false" class="button">Закрыть</div>
      </div>
    </transition>
  </div>
</template>

<script>
import fscreen from "fscreen";
const STORE = "structure";
import { mapGetters } from "vuex";
import VueTree from "/home/kvanton/projects/Sources/js/tree-chart/packages/vue-tree-chart";
export default {
  name: "treemap",
  components: { VueTree },
  data() {
    return {
      fscreenMsg: false,
      treeConfig: { nodeWidth: 140, nodeHeight: 120, levelHeight: 160 },
      isFullScreen: false,
    };
  },
  methods: {
    getFirstUserLetter(str) {
      return str.slice(0, 1).toUpperCase();
    },
    openFullscreen(elem) {
      if (fscreen.fullscreenEnabled) {
        fscreen.requestFullscreen(elem);
      } else {
        this.fscreenMsg = true;
      }
      // console.log('fullscreen element =', fscreen.fullscreenElement);
    },
    closeFullscreen() {
      fscreen.exitFullscreen();
      // console.log('fullscreen element =', fscreen.fullscreenElement);
    },
    fullScreenHandler() {
      if (fscreen.fullscreenElement !== null) {
        this.isFullScreen = true;
        // console.log('Entered fullscreen mode');
      } else {
        this.isFullScreen = false;
        // console.log('Exited fullscreen mode');
      }
    },
  },
  computed: {
    ...mapGetters("rank", ["rank"]),
    ...mapGetters(STORE, ["structure"]),
  },
  created() {},
  mounted() {
    fscreen.addEventListener("fullscreenchange", this.fullScreenHandler, false);
    const evCache = new Array();
    let prevDiff = -1;
    let debonce = false;
    const tree = this.$refs.tree;
    console.log("mounted: ", tree.$el);
    const remove_event = (ev) => {
      // Remove this event from the target's cache
      for (var i = 0; i < evCache.length; i++) {
        if (evCache[i].pointerId == ev.pointerId) {
          evCache.splice(i, 1);
          break;
        }
      }
    };
    const pointerdown_handler = (ev) => {
      // The pointerdown event signals the start of a touch interaction.
      // This event is cached to support 2-finger gestures
      evCache.push(ev);
      // console.log("pointerDown", ev);
    };
    const pointerup_handler = (ev) => {
      // console.log(ev.type, ev);
      // Remove this pointer from the cache and reset the target's
      // background and border
      remove_event(ev);
      // ev.target.style.background = "white";
      // ev.target.style.border = "1px solid black";

      // If the number of pointers down is less than two then reset diff tracker
      if (evCache.length < 2) {
        prevDiff = -1;
      }
    };
    const pointermove_handler = (ev) => {
      if (debonce) {
        return;
      }
      // This function implements a 2-pointer horizontal pinch/zoom gesture.
      //
      // If the distance between the two pointers has increased (zoom in),
      // the target element's background is changed to "pink" and if the
      // distance is decreasing (zoom out), the color is changed to "lightblue".
      //
      // This function sets the target element's border to "dashed" to visually
      // indicate the pointer's target received a move event.
      // console.log("pointerMove", ev);
      // ev.target.style.border = "dashed";

      // Find this event in the cache and update its record with this event
      for (var i = 0; i < evCache.length; i++) {
        if (ev.pointerId == evCache[i].pointerId) {
          evCache[i] = ev;
          break;
        }
      }

      // If two pointers are down, check for pinch gestures
      if (evCache.length == 2) {
        ev.stopPropagation();
        // Calculate the distance between the two pointers
        var curDiff = Math.abs(evCache[0].clientX - evCache[1].clientX);

        if (prevDiff > 0) {
          if (curDiff > prevDiff) {
            debonce = true;
            // The distance between the two pointers has increased
            // console.log("Zoom in", tree);
            // ev.target.style.background = "pink";
            tree.zoomIn();
            setTimeout(() => {
              debonce = false;
            }, 10);
          }
          if (curDiff < prevDiff) {
            debonce = true;
            // The distance between the two pointers has decreased
            // console.log("Zoom out", tree);
            // ev.target.style.background = "lightblue";
            tree.zoomOut();
            setTimeout(() => {
              debonce = false;
            }, 10);
          }
        }

        // Cache the distance for the next move event
        prevDiff = curDiff;
      }
    };
    const el = this.$refs.tree.$el;
    // el.onpointerdown = pointerdown_handler;
    // el.onpointermove = pointermove_handler;
    // el.onpointerup = pointerup_handler;
    // el.onpointercancel = pointerup_handler;
    // el.onpointerout = pointerup_handler;
    // el.onpointerleave = pointerup_handler;
  },
  beforeDestroy() {
    fscreen.removeEventListener(
      "fullscreenchange",
      this.fullScreenHandler,
      false
    );
  },
};
</script>
<style lang="scss"></style>
