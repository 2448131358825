<template lang="pug">
  form.form(@submit.prevent :class="classForm")

    <slot></slot>

    //- code field
    .form-code-field
      span.form-code-message Введите код из {{ sourceText }}
      .form-code-inputs.kw-code(
        :class="{invalid: invalidCode}"
      )
        .kw-code__wrap
          input.form-code-input(
            v-for="n in 6"
            :key="n"
            :ref="'input_' + n"
            :name="'digit' + n"
            :type="inputType"
            :pattern="inputType == 'number' ? '^[0-9]*' : ''"
            placeholder=" "
            autocomplete="off"
            :maxlength="maxlength"
            @input="codeInputHandler($event, n, 6)"
            @keydown="codeInputNext($event, n, 6)"
          )

        //- code error message
        <input-error :showVar="invalidCode">
          <p>{{ invalidMsg }}</p>
          <p>Пожалуйста, попробуйте еще раз</p>
        </input-error>


    //- resend code button
    button.button.button-resend-code(
      :class="{'button-disabled': resendLock || loadingResend, 'button-loading': loadingResend}"
      :disabled="resendLock || loadingResend"
      @click="resendCode"
    )
      svg.icon.icon-refresh
        use(xlink:href="@/assets/images/sprite/sprite-inline.svg#refresh")
      span.kw-btn-wrap
        span.main-text Отправить код повторно
        span.resend-time(v-if="resendLock") можно через&nbsp;
          span.resend-seconds {{ seconds }}
          |  сек


    //- complete registration button
    button.button.button-enter(
      :class="{'button-disabled': !isCodeValidated, 'button-loading': loading}"
      :disabled="!isCodeValidated || loading"
      @click="checkServerData"
    )
      <inline-svg v-if="loading" class="loader" :src="require('@/assets/images/sprite/loader.svg')"></inline-svg>
      span.button-content(v-else)
        svg.icon.icon-add
          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#add")
        | {{ buttonText }}

</template>
<script>
import axios from "axios";
import codeInputHandler from "@/mixins/iviteCode";
import codeInputNext from "@/mixins/iviteCode";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export default {
  name: "TwofaForm",
  mixins: [codeInputHandler],
  props: {
    inputType: {
      type: String,
      default: "text",
    },
    classForm: {
      type: String,
      default: "registration-form",
    },
    isSms: {
      type: Boolean,
      default: true,
    },
    buttonText: {
      type: String,
      default: "Завершить регистрацию",
    },
  },
  data() {
    return {
      invalidMsg: "Неверно указан код.",
      invalidCode: false,
      loading: false,
      loadingResend: false,
      isEmail: false,
      seconds: 60,
      resendLock: false,
    };
  },
  computed: {
    enteredCode() {
      return parseInt(this.codeInputs.join(""));
    },
    isCodeValidated() {
      return this.codeInputs.join("").length === 6 ? true : false;
    },
    sourceText() {
      if (this.isSms) {
        return "смс";
      } else {
        return "e-mail";
      }
    },
  },
  methods: {
    clearCode() {
      for (let i = 1; i <= 6; i++) {
        this.$refs["input_" + i][0].value = "";
      }
    },
    restartTimer(timecount = 60) {
      this.seconds = timecount;
      if (!this.resendLock) {
        this.resendLock = true;
        let timer = setInterval(() => {
          if (this.seconds > 1) {
            this.seconds--;
          } else {
            clearInterval(timer);
            this.resendLock = false;
          }
        }, 1000);
      }
    },
    resendCode() {
      const url = "/api/twofareset/";
      this.loading = true;
      this.loadingResend = true;
      this.clearCode();
      const login = this.$store.state.enteredLogin;
      const data = { login };
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "POST", url, data);
      }
      axios({ method: "POST", url, data })
        .then((resp) => {
          this.loading = false;
          this.loadingResend = false;
          this.restartTimer();
          if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
            console.log(
              `[ ${LOG_LVL_LIST.DEBUG} ]	`,
              url,
              "POST SUCCESS: ",
              resp
            );
          }
        })
        .catch((err) => {
          this.loadingResend = false;
          this.restartTimer(5);
          if (isLogLevel(LOG_LVL_LIST.ERROR)) {
            console.log(
              `[ ${LOG_LVL_LIST.ERROR} ]	`,
              url,
              err,
              "POST RESPONSE: ",
              err.response
            );
          }
          const data = err?.response?.data;
          this.invalidMsg = "Неверно указан код.";
          if (data) {
            switch (data.status) {
              case 404:
                this.$emit("error", { code: 404, msg: data.statusText });
                break;
              case 403:
                break;
            }
          } else {
          }
        });
    },
    checkServerData() {
      console.log("checkServerData");
      if (this.isCodeValidated && !this.loading) {
        this.loading = true;
        this.loadingResend = true;
        const code = this.enteredCode;
        const login = this.$store.state.enteredLogin;
        const data = { login, code };
        const url = "/api/twofa/";
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "POST", url, data);
        }
        axios({ method: "POST", url, data })
          .then((resp) => {
            if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
              console.log(
                `[ ${LOG_LVL_LIST.DEBUG} ]	`,
                url,
                "POST SUCCESS: ",
                resp
              );
            }
            this.$store.commit("auth_success", { ...resp.data });
            this.$nextTick(() => {
              this.loading = false;
              this.loadingResend = false;
              this.$router.push("/");
            });
          })
          .catch((err) => {
            this.loading = false;
            this.loadingResend = false;
            this.invalidCode = true;
            if (isLogLevel(LOG_LVL_LIST.ERROR)) {
              console.log(
                `[ ${LOG_LVL_LIST.ERROR} ]	`,
                url,
                err,
                "POST RESPONSE: ",
                err.response
              );
            }
          });
      }
    },
  },
  watch: {},
  mounted() {
    this.restartTimer();
    this.resendCode();
  },
};
</script>

<style lang="scss">
.kw-btn-wrap {
  display: flex;
  flex-direction: column;
}
.form-code-inputs {
  &.kw-code {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    .kw-code__wrap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      flex-wrap: nowrap;
    }
    &-input {
      &.form-code-input {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        min-width: 0;
      }
    }
  }
}
</style>
