export default {
  data() {
    return {
      maxlength: 1,
      codeInputs: ["", "", "", "", "", ""],
    };
  },
  methods: {
    codeInputHandler(event, input) {
      let val = event.target.value;
      // console.log('val = ', val, 'val.length = ', val.length);
      val = val.replace(/\D/g, "");
      this.$refs["input_" + input].value = val;
      if (val.length >= this.maxlength) {
        val = val.substr(0, this.maxlength);
        this.$refs["input_" + input].value = val;
        let next = this.$refs["input_" + (input + 1)];
        if (next) {
          next.focus();
        } else {
          event.preventDefault();
        }
      }
      this.$set(this.codeInputs, input - 1, val);
    },
    codeClear(event, input) {
      this.$refs["input_" + input].value = "";
      this.$set(this.codeInputs, input - 1, "");
    },
  },
};
