export default {
  data() {
    return {
      maxlength: 1,
      codeInputs: ["", "", "", "", ""],
    };
  },
  methods: {
    codeInputHandler(event, input, limit = 6) {
      let val = event.target.value;
      val = val.replace(/^\W/g, "");
      this.$refs["input_" + input].value = val;
      if (val.length >= this.maxlength) {
        val = val.substr(0, this.maxlength);
        this.$refs["input_" + input].value = val;
      }
      this.codeInputNext(event, input, limit);
      event.preventDefault();
      this.$set(this.codeInputs, input - 1, val);
    },
    codeInputNext(event, input, limit = 6) {
      let next = null;
      if (event.code === "Backspace") {
        next = this.$refs["input_" + (input - 1)];
        event.target.value = "";
        event.preventDefault();
      } else {
        let val = event.target.value;
        if (input < limit && val.length > 0) {
          next = this.$refs["input_" + (input + 1)];
        }
        if (input == limit && val.length > 0) {
          this.$nextTick(() => {
            this.checkServerData();
          });
        }
      }
      if (next && next.length > 0) {
        console.log("next", next);
        next[0].focus();
      }
    },
  },
};
