// import state from "./state";
import * as getters from "./getters";
import * as mutations from "./mutations";
import * as actions from "./actions";

const state = function () {
  return {
    keys: ["products", "applications"],
    status: ["inprogres", "done", "failure"],
    products: [],
    applications: [],
    // products: [
    //   {
    //     id: 1,
    //     title: "Покупка квартир",
    //     icon: "building",
    //     type: "apartments-buying",
    //     img: "",
    //     referralLink: "https://opn.group/erp/register/q2456ds",
    //     text: "Управление брендом без оглядки на авторитеты транслирует баинг и селлинг. Контент по-прежнему устойчив к изменениям спроса.",
    //   },
    //   {
    //     id: 2,
    //     title: "Продажа квартир",
    //     type: "apartments-selling",
    //     referralLink: "https://opn.group/erp/register/q2456ds",
    //     text: "Управление брендом без оглядки на авторитеты транслирует баинг и селлинг. Контент по-прежнему устойчив к изменениям спроса.",
    //   },
    //   {
    //     id: 3,
    //     title: "Покупка под ремонт",
    //     icon: "repair",
    //     type: "renovation-buying",
    //     referralLink: "https://opn.group/erp/register/q2456ds",
    //     text: "Управление брендом без оглядки на авторитеты транслирует баинг и селлинг. Контент по-прежнему устойчив к изменениям спроса.",
    //   },
    //   {
    //     id: 4,
    //     title: "Продажа под ремонт",
    //     icon: "repair",
    //     type: "renovation-selling",
    //     referralLink: "https://opn.group/erp/register/q2456ds",
    //     text: "Управление брендом без оглядки на авторитеты транслирует баинг и селлинг. Контент по-прежнему устойчив к изменениям спроса.",
    //   },
    //   {
    //     id: 5,
    //     title: "Ремонт квартир",
    //     icon: "repair1",
    //     type: "apartments-renovation",
    //     referralLink: "https://opn.group/erp/register/q2456ds",
    //     text: "Управление брендом без оглядки на авторитеты транслирует баинг и селлинг. Контент по-прежнему устойчив к изменениям спроса.",
    //   },
    //   {
    //     id: 6,
    //     title: "Инвестирование",
    //     icon: "investment",
    //     type: "investment",
    //     referralLink: "https://opn.group/erp/register/q2456ds",
    //     text: "Управление брендом без оглядки на авторитеты транслирует баинг и селлинг. Контент по-прежнему устойчив к изменениям спроса.",
    //   },
    //   {
    //     id: 7,
    //     title: "Хоум стейджинг",
    //     referralLink: "https://opn.group/erp/register/q2456ds",
    //     text: "Управление брендом без оглядки на авторитеты транслирует баинг и селлинг. Контент по-прежнему устойчив к изменениям спроса.",
    //   },
    // ],
    // applications: [
    //   {
    //     id: "L-32907",
    //     icon: "building",
    //     title: "Продажа новостроек",
    //     date: "2021-12-29",
    //     status: "inprogres",
    //   },
    //   {
    //     id: "L-32823",
    //     icon: "repair",
    //     title: "Покупка под ремонт",
    //     date: "2021-12-29",
    //     status: "done",
    //   },
    //   {
    //     id: "L-32726",
    //     icon: "investment",
    //     title: "Инвестирование",
    //     date: "2021-12-29",
    //     status: "failure",
    //   },
    //   {
    //     id: "L-32721",
    //     icon: "repair",
    //     title: "Покупка под ремонт",
    //     date: "2021-12-29",
    //     status: "inprogres",
    //   },
    //   {
    //     id: "L-32691",
    //     icon: "repair1",
    //     title: "Услуга ремонта",
    //     date: "2021-12-29",
    //     status: "done",
    //   },
    //   {
    //     id: "L-32528",
    //     icon: "repair",
    //     title: "Покупка под ремонт",
    //     date: "2021-12-29",
    //     status: "failure",
    //   },
    // ],
  };
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
