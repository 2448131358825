import axios from "axios";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export function getPassport({ commit }) {
  const url = "/api/passport/";
  const method = "GET";
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, method, url);
  }

  return axios({ method, url })
    .then((resp) => {
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(
          `[ ${LOG_LVL_LIST.DEBUG} ]	`,
          url,
          method,
          "RESPONSE",
          resp.data
        );
      }
      commit("savePasport", resp.data);
    })
    .catch((err) => {
      if (isLogLevel(LOG_LVL_LIST.ERROR)) {
        console.log(
          `[ ${LOG_LVL_LIST.ERROR} ]	`,
          url,
          err,
          "GET RESPONSE: ",
          err.response
        );
      }
    });
}

export function upload({ commit }, data) {
  const url = "/api/upload_passport/";
  const method = "POST";
  const headers = { "Content-Type": "multipart/form-data" };
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, method, url, data);
  }
  return new Promise((resolve, reject) => {
    axios({ method, url, data, headers })
      .then((resp) => {
        const files = resp?.data || [];
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(
            `[ ${LOG_LVL_LIST.DEBUG} ]	`,
            url,
            method,
            resp,
            "SUCCESS",
            files
          );
        }
        commit("setFiels", files);
        resolve();
      })
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(
            `[ ${LOG_LVL_LIST.ERROR} ]	`,
            url,
            err,
            "POST RESPONSE: ",
            err.response
          );
        }
        reject();
      });
  });
}
