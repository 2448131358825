// import state from "./state";
import * as getters from "./getters";
import * as mutations from "./mutations";
import * as actions from "./actions";

const state = function () {
  return {
    allIdArray: [],
    stuffAmount: 0,
    news: [
      // {
      //   id: 1,
      //   title: "Здесь не будет много слов.",
      //   desc: `<p>Просто посмотри, сколько может приносить всего ОДИН ГОД инвестиций с OPN PARTNER.</p><p> Напомни, почему ты ещё не в команде?</p>`,
      //   file: "/Hard West - digital comic book.pdf",
      //   link: "/Hard West - digital comic book.pdf",
      //   show: "928",
      //   time: "2022-02-04T17:00:00",
      //   isread: false,
      // },
      // {
      //   id: 2,
      //   title: "Правительство предложило изменить статус таунхаусов",
      //   show: "1928",
      //   isread: false,
      // },
      // {
      //   id: 3,
      //   title:
      //     "Риелторы назвали районы Москвы с максимально подешевевшими новостройками",
      //   desc: `<p>С начала года цена «квадрата» в массовом сегменте сильнее всего снизилась в районах Замоскворечье, Южное Чертаново и Измайлово</p>`,
      //   img: "https://app.opnpartner.ru/images/news-3.jpg",
      //   show: "28",
      //   time: "2022-02-04T17:00:00",
      //   isread: false,
      // },
      // {
      //   id: 4,
      //   title: "Правительство предложило изменить статус таунхаусов",
      //   desc: `<p>В Госдуму внесли законопроект, предлагающий трактовать каждый блок таунхауса как отдельный жилой дом</p>`,
      //   img: "/assets/images/news-4.jpg",
      //   show: "928",
      //   time: "2022-02-04T17:00:00",
      //   isread: false,
      // },
      // {
      //   id: 5,
      //   title:
      //     "Риелторы назвали районы Москвы с максимально подешевевшими новостройками",
      //   desc: `<p>С начала года цена «квадрата» в массовом сегменте сильнее всего снизилась в районах Замоскворечье, Южное Чертаново и Измайлово</p>`,
      //   img: "/assets/images/news-5.jpg",
      //   show: "928",
      //   time: "2022-02-04T17:00:00",
      //   isread: false,
      // },
    ],
  };
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
