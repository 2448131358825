<template lang="pug">
  .support-tabs
      .support-tabs-menu
          router-link.support-tabs-menu-item( v-for='(tab, index) in tabs'
            :key="index"
            :to="`/main/support/` + tab.path"
            )
            svg(class="icon" :class="`icon-` + tab.icon")
              use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + tab.icon")
            span {{ tab.title }}
      .support-tabs-content
        <router-view/>

</template>

<script>
export default {
  data() {
    return {
      tabs: [
        {
          icon: "question",
          title: "Часто задаваемые вопросы",
          component: "FAQ",
          path: "faq",
        },
        {
          icon: "comment",
          title: "Мои вопросы",
          component: "MyFAQ",
          path: "questions",
        },
        {
          icon: "comment",
          title: "Мои вопросы",
          component: "MyFAQEmpty",
          path: "questions-empty",
        },
      ],
    };
  },
  methods: {},
};
</script>
