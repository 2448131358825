export default {
  data() {
    return {};
  },
  methods: {
    changeTitle(pagename) {
      this.$emit("changeTitle", pagename);
    },
  },
};
