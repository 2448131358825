<template lang="pug">
  .form-document-item(:data-image="active ? 'active' : ''")
    input.form-input-file(
      ref="input"
      type='file'
      :multiple="multiple"
      :accept="accept"
      @change="previewImage"
    )
    .form-document-type
      svg.icon.icon-user
        use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + icon")
      p {{ title }}
    .form-document-loaded
      span.form-document-remove-btn(@click='removeImg')
        svg.icon.icon-trash
          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#trash")
      img.form-document-img(
        :alt="dataAlt || alt"
        :src='src'
      )
</template>
<script>
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";
export default {
  name: "KwUpload",
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "user",
    },
    alt: {
      type: String,
      default: "Фото документа",
    },
    value: {
      required: true,
    },
    title: {
      type: String,
      default: "Загрузить основную страницу",
    },
    accept: {
      type: Array,
      default: () => ["image/jpeg", "image/png", "application/pdf"],
    },
    acceptImage: {
      type: Array,
      default: () => ["image/jpeg", "image/png"],
    },
    min: {
      type: Number,
      default: 2400,
    },
    max: {
      type: Number,
      default: 8388608,
    },
  },
  data() {
    return {
      active: false,
      src: "",
      dataAlt: "",
    };
  },
  computed: {},
  methods: {
    removeImg(event) {
      this.active = false;
      this.src = "";
      this.dataAlt = "";
    },
    validFileType(file) {
      return this.accept.includes(file.type);
    },
    validFile(file) {
      return (
        file &&
        this.validFileType(file) &&
        file.size >= this.min &&
        file.size <= this.max
      );
    },
    previewImage(event) {
      const input = event.target;
      if (this.validFile(input.files[0])) {
        this.$emit("input", event.target.files[0]);
      } else {
        this.dataAlt = "Неверный размер файла или тип";
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(
            `[ ${LOG_LVL_LIST.DEBUG} ]	`,
            "err",
            "Неверный размер файла или тип"
          );
        }
        // input.value = "";
      }
      this.active = true;
    },
    syncValue() {
      if (
        this.value &&
        (typeof this.value === "string" || this.value instanceof String) &&
        this.value.length > 0
      ) {
        this.active = true;
        this.src = this.value;
      } else if (this.accept.includes(this.value?.type)) {
        if (this.acceptImage.includes(this.value?.type)) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.src = e.target.result;
          };
          reader.readAsDataURL(this.value);
        }
        this.dataAlt = this.value.name;
      } else {
        this.active = false;
        this.src = "";
      }
    },
  },
  watch: {
    value(v) {
      console.log("watch value", v);
      this.syncValue();
    },
  },
  created() {
    this.syncValue();
  },
};
</script>

<style lang="scss"></style>
