// import state from "./state";
import * as getters from "./getters";
import * as mutations from "./mutations";
import * as actions from "./actions";

const state = function () {
  return {
    array: [
      // {
      //   id: "T-32906",
      //   title: "Не получается загрузить документ или архив",
      //   isread: true,
      //   lastmsg: {
      //     username: "OPN Parthner",
      //     avatar: "/images/profile-opn.svg",
      //     date: "2021-10-17",
      //   },
      // },
      // {
      //   id: "T-32908",
      //   title: "Не получается загрузить документ или архив",
      //   isread: false,
      //   lastmsg: {
      //     username: "OPN Parthner",
      //     date: "2021-10-17",
      //   },
      // },
    ],
  };
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
