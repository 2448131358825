<script>
// import { Line } from "vue-chartjs";
import { Line, mixins, Scale } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: {
    options: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
