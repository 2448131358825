<template lang="pug">
    .profile-edit
        .profile-edit-wrapper 
            .profile-edit-head
                .profile-edit-title
                    .profile-edit-title-icon
                        svg.icon.icon-profile
                            use(xlink:href="@/assets/images/sprite/sprite-inline.svg#profile")
                    span Личность
                <profile-back-btn/>
            .profile-progressbar-wrapper
                .profile-progressbar
                    .progressbar-current
                    .progressbar-body
                        .progressbar-step-item._current
                            .progressbar-circle 1
                            span Личная информация
                        .progressbar-step-item
                            .progressbar-circle 2
                            span Детали документа
                        .progressbar-step-item
                            .progressbar-circle 3
                            span Загрузка документа
            .profile-personality-form-block
                form.profile-personality-form
                    .form-fullname
                        .form-field(
                            :class="{focused: nameFieldFocused, filled: nameFieldFilled, invalid: !nameFieldFilled}"
                          )
                            label.form-label() Имя
                            input.form-input(
                                type="text"
                                maxlength='16'
                                name="name"
                                v-model="nameField"
                                @focus="nameFieldFocused = true"
                                @blur="onBlurInputHandler"
                                )
                        .form-field(
                            :class="{focused: surnameFieldFocused, filled: surnameFieldFilled, invalid: !surnameFieldFilled}"
                            )
                            label.form-label( 
                                ) Фамилия
                            input.form-input(
                                type="text"
                                maxlength='16'
                                name="surname"
                                v-model="surnameField"
                                @focus="surnameFieldFocused = true"
                                @blur="onBlurInputHandler"
                                )
                        .form-field(
                            :class="{focused: middlenameFieldFocused, filled: middlenameFieldFilled, invalid: !middlenameFieldFilled}"
                            )
                            label.form-label( 
                                ) Отчество
                            input.form-input(
                                type="text"
                                maxlength='16'
                                v-model="middlenameField"
                                name="middle_name"
                                @focus="middlenameFieldFocused = true"
                                @blur="onBlurInputHandler"
                                )
                    .form-row
                      .form-date-select.form-date-select--birth(
                          :class="{invalid: !(selectedDay && selectedMonth && selectedYear)}"
                        )
                            select(v-model="selectedDay")
                                option(
                                    v-for="day in daysInMonth"
                                    :key="day"
                                    ) {{ day }}
                            select(v-model="selectedMonth")
                                option(
                                    v-for="(month, index) in monthsList"
                                    :key="month.monthName"
                                    :value="index + 1"
                                    ) {{ month.monthName }}
                            select(v-model="selectedYear")
                                option(
                                    v-for="(item, year) in (100 - 14)"
                                    :key="year"
                                    ) {{ currentDate - year }}

                      .form-single-select()
                            select(
                                v-model="selectedCountry"
                                name="country"
                                :class="{invalid: !selectedCountry}"
                                )
                                option(
                                    v-for="country in countries"
                                    :key="country"
                                    ) {{ country }}
        .profile-edit-btn-wrapper
          button.button.profile-edit-continue-btn.profile-edit-continue-btn--full(
            :class="{'button-disabled': errors}"
            @click="nextStep"
          )
                | Продолжить
                svg.icon.icon-arrow-right
                    use(xlink:href="@/assets/images/sprite/sprite-inline.svg#arrow-right")
</template>

<script>
import { mapGetters } from "vuex";
import validateEmailBool from "@/mixins/validateEmailBool";
export default {
  mixins: [validateEmailBool],
  data() {
    return {
      nameField: "",
      surnameField: "",
      middlenameField: "",
      selectedDay: null,
      selectedMonth: null,
      selectedYear: null,
      selectedCountry: "",

      nameFieldFocused: false,
      surnameFieldFocused: false,
      middlenameFieldFocused: false,

      monthsList: [
        { monthName: "Январь", days: "31" },
        { monthName: "Февраль", days: "28" },
        { monthName: "Март", days: "31" },
        { monthName: "Апрель", days: "30" },
        { monthName: "Май", days: "31" },
        { monthName: "Июнь", days: "30" },
        { monthName: "Июль", days: "31" },
        { monthName: "Август", days: "31" },
        { monthName: "Сентябрь", days: "30" },
        { monthName: "Октябрь", days: "31" },
        { monthName: "Ноябрь", days: "30" },
        { monthName: "Декабрь", days: "31" },
      ],
      countries: ["Россия", "Беларусь", "Казахстан"],
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("personEdit", ["personaldata"]),
    currentDate() {
      return new Date().getFullYear() - 14;
    },
    nameFieldFilled() {
      return !(this.nameField === "");
    },
    surnameFieldFilled() {
      return !(this.surnameField === "");
    },
    middlenameFieldFilled() {
      return !(this.middlenameField === "");
    },
    errors() {
      return !(
        this.nameFieldFilled &&
        this.surnameFieldFilled &&
        this.middlenameFieldFilled &&
        this.selectedDay &&
        this.selectedMonth &&
        this.selectedYear &&
        this.selectedCountry
      );
    },
    daysInMonth() {
      return new Date(this.selectedYear, this.selectedMonth, 0).getDate();
    },
  },
  methods: {
    onBlurInputHandler() {
      this.nameFieldFocused = false;
      this.surnameFieldFocused = false;
      this.middlenameFieldFocused = false;
    },
    nextStep() {
      const data = {
        name: this.nameField,
        surname: this.surnameField,
        fathername: this.middlenameField,
        birthday: `${this.selectedYear}-${this.selectedMonth
          .toString()
          .padStart(2, "0")}-${this.selectedDay.toString().padStart(2, "0")}`,
        country: this.selectedCountry,
      };
      this.$store.commit("personEdit/savePersonalData", data);
      this.$router.push(`/person-edit-step-2`);
    },
    syncUserData() {
      this.nameField = this.personaldata.name || this.user.name;
      this.surnameField = this.personaldata.surname || this.user.surname;
      this.middlenameField =
        this.personaldata.fathername || this.user.fathername;
      const birthday = this.personaldata.birthday
        ? new Date(this.personaldata.birthday || "")
        : new Date(this.user.birthday || "");
      this.selectedDay = birthday.getDate() || null;
      this.selectedMonth = birthday.getMonth() + 1 || null;
      this.selectedYear = birthday.getFullYear() || null;
      this.selectedCountry = this.personaldata.country || this.user.country;
    },
  },
  watch: {
    user() {
      this.syncUserData();
    },
  },
  created() {
    this.$store.commit("loaderOff");
  },
  mounted() {
    this.syncUserData();
  },
};
</script>

<style lang="scss" scoped>
.form-date-select {
  &.invalid {
    box-shadow: 0px 0px 0px 4px #ffc8bc;
  }
}
select.invalid {
  box-shadow: 0px 0px 0px 4px #ffc8bc;
}
</style>
