export default {
  data() {
    return {};
  },
  methods: {
    emitCloseMenu() {
      this.$emit("closeMenu");
    },
  },
};
