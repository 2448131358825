// import state from "./state";
import * as getters from "./getters";
import * as mutations from "./mutations";
import * as actions from "./actions";

const state = function () {
  return {
    array: [
      // {
      //   place: 2,
      //   name: "Алексей В.",
      //   rank: "specialist",
      //   value: 19,
      //   iconColor: "#eaa745",
      //   avatar: "",
      // },
      // {
      //   place: 1,
      //   name: "Максим Д.",
      //   rank: "expert",
      //   value: 21300,
      //   iconColor: "#69bb55",
      //   avatar: require("@/assets/images/profile-photo.png"),
      // },
      // {
      //   place: 3,
      //   name: "Эдуард Ф.",
      //   rank: "newcomer",
      //   value: 18,
      //   iconColor: "#ff8845",
      //   avatar: "",
      // },
      // {
      //   place: 4,
      //   name: "Виктор Ш.",
      //   rank: "beginner",
      //   value: 14,
      //   iconColor: "#eaa745",
      //   avatar: "",
      // },
      // {
      //   place: 5,
      //   name: "Диана Ц.",
      //   rank: "beginner",
      //   value: 10,
      //   iconColor: "#eaa745",
      //   avatar: "",
      // },
      // {
      //   place: 6,
      //   name: "Дмитрий Щ.",
      //   rank: "beginner",
      //   value: 8,
      //   iconColor: "#eaa745",
      //   avatar: "",
      // },
    ],
  };
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
