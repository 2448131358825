<template lang="pug">
  form.form.support-form(@submit.prevent)
    .support-form-header
      .support-block-head
        .support-block-head-icon
          svg(class="icon icon-comment")
            use(xlink:href="@/assets/images/sprite/sprite-inline.svg#comment")
        p Создать вопрос
      .support-back.button(
        @click='$router.push("/support/questions")'
        )
        svg(class="icon icon-arrow-left")
          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#arrow-left")
        | Мои вопросы
    .support-form-inner
      .form-field
        label.form-label Кратко опишите вопрос
        input.form-input(
          v-model="title"
          type="text"
          name="title"
          @focus="onFocusInputHandler"
          @blur="onBlurInputHandler"
          @input="checkForm"
        )
      .form-field
        label.form-label Изложите подробной суть вопроса
        textarea.form-input(
          v-model="question"
          rows="5"
          name="description"
          @focus="onFocusInputHandler"
          @blur="onBlurInputHandler"
          @input="checkForm"
        )


    button.support-form-btn.button-disabled(@click="submit")
      svg.icon.icon-comment
        use(xlink:href="@/assets/images/sprite/sprite-inline.svg#comment")
      | Задать вопрос

</template>

<script>
const STORE = "chatlist";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";
export default {
  name: "SupportForm",
  data() {
    return {
      canSubmit: true,
      title: "",
      question: "",
    };
  },
  methods: {
    onFocusInputHandler(event) {
      event.target.parentElement.classList.add("focused");
      event.target.focus();
    },
    onBlurInputHandler(event) {
      let inputValue = event.target.value;
      if (inputValue === "") {
        event.target.parentElement.classList.remove("focused");
      }
    },
    checkForm() {
      let count = 0,
        fields = document
          .querySelector(".support-form")
          .getElementsByClassName("form-input");

      for (let i = 0; i < fields.length; i++) {
        if (fields[i].value !== "") {
          count++;
        }
      }
      if (count === fields.length) {
        document
          .querySelector(".support-form-btn")
          .classList.remove("button-disabled");
      } else {
        document
          .querySelector(".support-form-btn")
          .classList.add("button-disabled");
      }
    },
    submit() {
      const { title, question } = this;
      this.$store
        .dispatch(`${STORE}/create`, { title, question })
        .then(() => {
          this.$router.push("/questions/0");
        })
        .catch(() => {});
    },
  },
  created() {
    this.$store.commit("loaderOff");
  },
};
</script>
