export default {
  data() {
    return {};
  },
  methods: {
    replaceNumberForInput(value) {
      let val = '';
      const x = value
        .replace(/\D/g, '')
        .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

      if (!x[3]) {
        val = x[1] === '7' ? '+' + x[1] + ' ' + x[2] : '+7 ' + x[1] + x[2];
      } else {
        val = !x[3]
          ? '+' + x[1] + ' ' + x[2]
          : '+' +
            x[1] +
            ' ' +
            x[2] +
            '-' +
            x[3] +
            (x[4] ? '-' + x[4] : '') +
            (x[5] ? '-' + x[5] : '');
      }
      return val;
    },
  },
};
