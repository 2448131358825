<template lang="pug">
  button.settings-back.button(
    @click="$router.push(`/profile/settings`)"
  )
    svg(class="icon icon-arrow-left")
      use(xlink:href="@/assets/images/sprite/sprite-inline.svg#arrow-left")
    | Настройки
</template>

<script>
export default {
  name: 'settings-back-btn',
};
</script>

<style></style>
