<template lang="pug">
  .product
    .product-block(v-for="(item, index) in products" :key="index")
      .product-pic(:class="{'product-pic--grey-bg': !img(item)}")
        img(
          v-if="img(item)"
          :src="img(item)"
          alt="picture")
        img(
          v-else
          src="@/assets/images/question.svg" 
          alt="picture")
      .product-info
        .product-title
          p {{ item.title }}
        .product-desc.text
          p {{ item.text }}
        .product-get-link(:class="{'_is-menu-opened': linkMenuOpnd === index}")
          button.product-get-link-btn(@click="showGetLinkMenu(index)") Получить ссылку продукта

          .product-get-link-menu
            .get-link-field(:class="{'data-copied': dataCopied === index}")
              span.get-link-text-link(v-if="dataCopied !== index") {{ item.referralLink }}

              //- link copied message
              .link-copied-message(
                v-else-if="dataCopied === index"
                )
                svg.icon.icon-check
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#check")
                span.link-copied-text Ссылка скопирована

              //- copy button
              button.copy-link-btn(
                v-clipboard="item.referralLink"
                @success="handleClipboardSuccess(index)"
                data-title="Скопировать ссылку"
              )
                svg.icon.icon-copy
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#copy")


        button-base(@click="submit(index)") Создать заявку

    //- menu mask
    .menu-mask(
      v-if="menuMaskShow"
      @click="closeLinkMenu"
      )
</template>

<script>
const STORE = "programs";
import { mapGetters } from "vuex";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export default {
  data() {
    return {
      timeout: null,
      linkMenuOpnd: null,
      dataCopied: null,
      menuMaskShow: false,
    };
  },
  computed: {
    ...mapGetters(STORE, ["products"]),
  },
  methods: {
    submit(index) {
      this.$router.push("/programs/application/" + index);
    },
    img(item) {
      if (item.type) {
        return require(`@/assets/images/${item.type}.png`);
      } else if (item.img) {
        return item.img;
      } else {
        return null;
      }
    },
    showGetLinkMenu(index) {
      if (this.linkMenuOpnd === null || this.linkMenuOpnd !== index) {
        this.linkMenuOpnd = index;
        this.menuMaskShow = true;
      } else if (this.linkMenuOpnd === index) {
        this.linkMenuOpnd = null;
        this.menuMaskShow = false;
      }
    },
    handleClipboardSuccess(index) {
      this.dataCopied = index;
      if (this.dataCopied !== null) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.dataCopied = null;
      }, 3000);
    },
    closeLinkMenu() {
      this.linkMenuOpnd = null;
      this.menuMaskShow = false;
    },
  },
  created() {
    this.$store.commit("loaderOff");
  },
  mounted() {},
};
</script>
