<template lang="pug">
  
  form.login-form.form(@submit.prevent)

    //- login field
    .form-field(:class="{validated: isLoginFieldValidated, focused: ( logFieldFocused || loginField), invalid: invalidLogin}")
      label.form-label() E-mail или телефон
      imask-input.form-input(
          v-model:typed  = "loginField"
          type="text",
        :unmask="true"
        :mask="mask"
        :prepare="prepareMask"
          @focus="logFieldFocused = true"
          @blur="onBlurInputHandler"
          @input="validateLoginField"
      )
        
      //- login error message
      <input-error :showVar="invalidLogin">
        <p>{{ errorEmail }}</p>
      </input-error>


    //- password field
    .form-field(
      :class="{validated: isPwdFieldValidated, focused: ( pwdFieldFocused || pwdField), invalid: invalidPwd}"
      )
      label.form-label() Введите пароль
      input.form-input.form-input-pwd(
          v-model="pwdField"
          type="password",
          name="password"
          @focus="pwdFieldFocused = true"
          @blur="onBlurInputHandler"
          @input="passwordInputHandler"
        )
      .form-btn-eye(
        @click='changeInputType'
      )
        svg.icon.icon-eye-close(v-if="isPwdHide")
          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#eye-close")
        svg.icon.icon-eye-open(v-else)
          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#eye-open")

      //- password error message
      <input-error :showVar="invalidPwd">
        <p>Неверный пароль</p>
        //- <p>Не корректный пароль</p>
      </input-error>


    //- remember me and remind password fields
    .form-actions
      .form-checkbox-field.form-remember-pwd
        input(type="checkbox" id="remember-pwd" v-model="rememberMe")
        label(for="remember-pwd") Запомнить меня
      router-link.link(
        v-if="isLoginFieldValidated"
        to="/pwd-recovery"
        ) Напомнить пароль

      
    //- enter button
    button.button.button-enter(
      :class="{'button-disabled': errors, 'button-loading': loading}"
      :disabled="errors || loading"
      @click="checkServerData"
      )
      <inline-svg v-if="loading" class="loader" :src="require('@/assets/images/sprite/loader.svg')"></inline-svg>
      span.button-content(v-else)
        svg.icon.icon-enter
          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#enter")
        | Войти 
    
</template>

<script>
import validateEmailBool from "@/mixins/validateEmailBool";
import replaceNumberForInput from "@/mixins/replaceNumberForInput";
import changeInputType from "@/mixins/changeInputType";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";
import { IMaskComponent } from "vue-imask";

export default {
  components: {
    "imask-input": IMaskComponent,
  },
  mixins: [validateEmailBool, replaceNumberForInput, changeInputType],
  data() {
    return {
      mask: [
        {
          mask: "+{7}(000)000-00-00",
        },
        {
          mask: /^\S*@?\S*$/,
        },
      ],
      errorEmail: "Введен неверный E-mail или телефон",
      rememberMe: false,
      logFieldFocused: false,
      pwdFieldFocused: false,
      isLoginFieldValidated: false,
      isPwdFieldValidated: false,
      loginField: "",
      pwdField: "",
      // loginValue: "",
      invalidLogin: false,
      invalidPwd: false,
      loading: false,
      // emailLoginProceed: false,
    };
  },
  methods: {
    prepareMask(appended, masked) {
      if (appended === "8" && masked.value === "") {
        return "";
      }
      return appended;
    },
    onBlurInputHandler() {
      if (this.loginField === "") {
        this.logFieldFocused = false;
      }
      if (this.pwdField === "") {
        this.pwdFieldFocused = false;
      }
    },
    validateLoginField() {
      // console.log("validateLoginField", this.loginField);
      this.invalidLogin = false;
      this.isLoginFieldValidated = false;
      const regCheckForPhone = /^\+?7?\s?\(?\d{3}.*/;
      const loginValue = this.loginField.replace(/\D/g, "").substr(0, 11);
      if (
        regCheckForPhone.test(this.loginField.slice(0, 7)) &&
        loginValue.length >= 3
      ) {
        // console.log('the login field must be the phone number');
        // this.loginField = this.replaceNumberForInput(this.loginField);
        if (loginValue.length === 11) {
          this.isLoginFieldValidated = true;
          this.$store.commit("changeEnteredLogin", this.loginField);
        } else {
          this.isLoginFieldValidated = false;
        }
      } else if (this.loginField.length >= 5) {
        // console.log('the login field must be the email');
        if (this.validateEmailBool(this.loginField)) {
          this.isLoginFieldValidated = true;
        } else {
          this.isLoginFieldValidated = false;
        }
      }
    },
    passwordInputHandler() {
      this.invalidPwd = false;
      if (
        this.pwdField.length === 0 ||
        this.pwdField.length < 8 ||
        this.pwdField.length > 36
      ) {
        this.isPwdFieldValidated = false;
      } else {
        this.isPwdFieldValidated = true;
      }
    },
    checkServerData() {
      if (!this.errors && !this.loading) {
        this.loading = true;
        const login = this.loginField;
        const password = this.pwdField;
        const rememberMe = this.rememberMe;
        this.$store
          .dispatch("login", { login, password, rememberMe })
          .then(() => {
            this.loading = false;
            this.$router.push("/");
          })
          .catch((err) => {
            if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
              console.log(
                `[ ${LOG_LVL_LIST.DEBUG} ]	`,
                "LOGIN ERR",
                err?.response,
                err?.response?.data
              );
            }
            const data = err?.response?.data;
            this.loading = false;
            if (data) {
              switch (data.status) {
                case 404:
                  this.invalidLogin = true;
                  break;
                case 403:
                  this.invalidPwd = true;
                  break;
              }
            } else {
              this.invalidLogin = true;
              this.invalidPwd = true;
            }
          });
      }
    },
  },
  computed: {
    errors() {
      return !this.isLoginFieldValidated || !this.isPwdFieldValidated
        ? true
        : false;
    },
  },
  mounted() {
    if (this.$store.state.enteredLogin) {
      this.logFieldFocused = true;
      this.loginField = this.$store.state.enteredLogin;
      this.validateLoginField();
      // if (this.emailLoginProceed && this.validateEmailBool(this.loginField)) {
      //   this.isLoginFieldValidated = true;
      // }
    }
  },
};
</script>

<style></style>
