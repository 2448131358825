<template lang="pug">
  .wallet
    .wallet-tabs
      .wallet-tabs-menu
        router-link.wallet-tabs-menu-item(v-for='(tab, index) in tabs' :key="index" :to="'/wallet/' + tab.path")
            svg(:class="['icon-' + tab.icon, 'icon' ]")
                use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + tab.icon")
            span {{ tab.title }}
    .wallet-tabs-content
      <router-view/>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      tabs: [
        {
          icon: "wallet",
          title: "Баланс",
          path: "balance",
        },
        {
          icon: "payments",
          title: "Выплаты",
          path: "payments",
        },
        {
          icon: "details",
          title: "Реквизиты",
          path: "details",
        },
      ],
    };
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style></style>
