<template lang="pug">
   .video(v-if="video")
      .video-filters
         select.select-item(v-model="selectedBanner")
            option(v-for="(item, index) in video.items" :key="index" :value="index") {{ item.name }}
         //- select.select-item(v-model="selectedSize" v-if="bannerSelect.sizes")
         //-    option(v-for="(item, index) in bannerSelect.sizes" :key="index" :value="index") {{ (`${item.w}x${item.h}`) }}

      // {{{
      .video-content
         .video-slider-wrapper
            swiper(class="swiper" :options="swiperOption" ref="videoSwiper" @slideChange="handleSlideChange")
               swiper-slide(v-for="(item, index) in bannerSelect.sizes" :key="index")
                  .swiper-slide-inner(v-html="item.iframe")

            .swiper-button-prev(slot="button-prev")
               svg.icon.icon-arrow-left
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#arrow-left")
            .swiper-button-next(slot="button-next")
               svg.icon.icon-arrow-left
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#arrow-left")
      // }}}

      // {{{
      .promo-code(:class="{'data-copied': dataCopied}")
         code.promo-code-field(v-if="!dataCopied") {{ bannerSizeSelect.code }}
         .code-copied-message(v-else)
            svg.icon.icon-check
               use(xlink:href="@/assets/images/sprite/sprite-inline.svg#check")
            span.code-copied-text Код скопирован
         button.promo-code-copy-btn(
            data-title='Скопировать код'
            v-clipboard="bannerSizeSelect.code"
            @success="handleClipboardSuccess"
         )
            svg.icon.icon-copy
               use(xlink:href="@/assets/images/sprite/sprite-inline.svg#copy")
      //}}}

      // {{{
      .promo-instruction-links
         .promo-instruction-item
            span
               svg.icon.icon-tutorial
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#tutorial")
            a.link(:href="video.manual" target="_blank") Руководство по использованию
         .promo-instruction-item
            span
               svg.icon.icon-sheet
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#sheet")
            a.link(:href="video.rules" target="_blank") Правила проведения рекламных кампаний
      // }}}
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
const STORE = "promo";
import { mapGetters } from "vuex";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      selectedBanner: 0,
      selectedSize: 0,
      dataCopied: false,
      swiperOption: {
        slidesPerView: "auto",
        // slidesPerView: 1,
        spaceBetween: 10,
        centeredSlides: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed: {
    ...mapGetters(STORE, ["video"]),
    bannerSelect() {
      return this.video.items[this.selectedBanner];
    },
    bannerSizeSelect() {
      return this.bannerSelect.sizes[this.selectedSize];
    },
    swiper() {
      return this.$refs["videoSwiper"].$swiper;
    },
  },
  watch: {
    selectedBanner() {
      this.selectedSize = 0;
    },
    selectedSize(val) {
      this.swiper.slideTo(val, 500, false);
    },
  },
  methods: {
    handleSlideChange(index) {
      this.selectedSize = this.swiper.activeIndex;
    },
    handleClipboardSuccess() {
      this.dataCopied = true;
      setTimeout(() => {
        this.dataCopied = false;
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
// .video-slider-wrapper {
//   width: 100%;
//   // width: 800px;
//   height: 400px;
// }
</style>
