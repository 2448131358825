<template lang="pug">
    .form-field(:class="{focused: fieldFocused, filled: value}")
        label.form-label {{ title }}
        input.form-input(
            :type="inputType"
            :name="id"
            :value="value"
            @input="$emit('input', $event.target.value)"
            @focus="fieldFocused = true"
            @blur="fieldFocused = false"
        )
</template>

<script>
export default {
  name: "KwInput",
  props: {
    value: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    inputType: {
      type: String,
      default: "text",
    },
  },
  data() {
    return {
      fieldFocused: false,
    };
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style></style>
