<template lang="pug">
  .support-tabs-faq
    .support-block(v-for="(block, index) in blocks" :key="index")
      .support-block-head
        .support-block-head-icon
          svg.icon(:class="'icon-' + (block.icon || 'question')")
            use(:xlink:href="iconSprite + '#' + (block.icon || 'question')")
        p {{ block.title }}
      .support-block-content
        .accordion(
          v-for="(item, itemIndex) in block.questions"
          :key="itemIndex"
          :class="{active: (itemIndex == 0)}"
        )
          .accordion-head(@click="openAccord") {{ item.ask }}
            svg(class="icon icon-arrow-down")
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#arrow-down")
          .accordion-body
            .text(v-html="item.answer" v-if="item.answer")
            .text(v-if="item.link")
              router-link(:to="item.link.to" tag="button").button {{ item.link.title }}
</template>

<script>
import { mapGetters } from "vuex";
const STORE = "faq";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export default {
  data() {
    return {
      iconSprite: require("@/assets/images/sprite/sprite-inline.svg"),
    };
  },
  computed: {
    ...mapGetters(STORE, ["blocks"]),
  },
  methods: {
    openAccord(e) {
      e.currentTarget.parentElement.classList.toggle("active");
    },
  },
  created() {
    this.$store.commit("loaderOff");
  },
  mounted() {},
};
</script>
