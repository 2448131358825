<template lang="pug">
  .rating-lists
    .rating-lists-tab
      .rating-lists-tab-item(
        v-for='(tab, index) in tabs'
        :key="index"
        @click="selectType = tab.icon"
        :class="[{active: selectType===tab.icon }]"
      )
        svg(class="icon" :class="`icon-` + tab.icon")
          use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + tab.icon")
        span {{ tab.title }}
    RatingList(:typeSeason="selectType" :season="season")
</template>
<script>
import RatingList from "./RatingList";

export default {
  name: "RatingSeason",
  props: {
    season: {
      type: Number,
      required: true,
    },
  },
  components: {
    RatingList,
  },
  data() {
    return {
      selectType: "hunter",
      isHidden: true,
      tabs: [
        {
          icon: "hunter",
          title: "Лига охотников",
        },
        {
          icon: "earner",
          title: "Лига добытчиков",
        },
      ],
    };
  },
  methods: {},
};
</script>
