import Vue from "vue";
// import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export function FETCH_ALL(state, data) {
  state.news = [];
  addNews(state, data);
}

export function addNews(state, data) {
  if (!Array.isArray(state.news)) {
    state.news = [];
  }
  if (Array.isArray(data)) {
    state.news = [...state.news, ...data]; //state.news.concat(data);
    data.forEach((item) => {
      state.allIdArray.push(item.id);
    });
  } else if (Array.isArray(data.news)) {
    Vue.set(state, "news", data.news);
    state.pageCount = data?.pageCount ? data.pageCount : 1;
  } else {
    state.news.push(data);
    state.pageCount = data?.pageCount ? data.pageCount : 1;
  }
}

export function read(state) {
  state.news.forEach((item) => {
    item.isread = true;
  });
}
