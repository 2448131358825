<template lang="pug">
  .support-question
    .support-question-block
      .support-question--empty(v-if="chatlist.length == 0")
        img(src="@/assets/images/faq.svg" alt="faq empty")
        span Здесь появятся ваши вопросы
      .support-question-one(
        v-else
        v-for="(item, index) in chatlist"
        :key="index"
        :class="{'unread': (!item.isread)}"
        @click="open(index)"
      )
        .support-question-icon
          svg(class="icon icon-comment")
            use(xlink:href="@/assets/images/sprite/sprite-inline.svg#comment")
        .support-question-content
          .support-question-desc
            p {{ item.title }}
            span {{ item.id }}
          .support-question-info
            .support-question-info-pic
              img(v-if="item.lastmsg.avatar" :src="item.lastmsg.avatar" :alt="item.lastmsg.username")
              .profile-icon.kw-fix(v-else)
                .profile-user-letter.kw-fix {{ firstUserLetter(item.lastmsg.username) }}
            .support-question-info-data
              p {{ item.lastmsg.username }}
              span {{ date(index) }}
    router-link(to="/ask-question").support-question-btn
      svg.icon.icon-comment
        use(xlink:href="@/assets/images/sprite/sprite-inline.svg#comment")
      | Задать вопрос
</template>

<script>
import Utils from "/src/helper.js";
import { mapGetters } from "vuex";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

const STORE = "chatlist";
export default {
  data() {
    return {
      dateOptions: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
    };
  },
  computed: {
    ...mapGetters(STORE, ["chatlist"]),
  },
  methods: {
    firstUserLetter: Utils.firstUserLetter,
    open(index) {
      this.$router.push("/questions/" + index);
    },
    date(index) {
      const item = this.chatlist[index];
      return (
        new Date(item.lastmsg.date)?.toLocaleDateString(
          "ru-RU",
          this.dateOptions
        ) || ""
      );
    },
    // changeProp() {
    //   // this.$emit('change', false)
    //   this.onChange({
    //     isHidden: false
    //   })
    // }
  },
  created() {
    this.$store
      .dispatch(`${STORE}/fetch`)
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(`[ ${LOG_LVL_LIST.ERROR} ]	`, STORE, "fetch", err);
        }
      })
      .finally(() => {
        this.$store.commit("loaderOff");
      });
  },
};
</script>
<style lang="scss">
.profile-icon {
  &.kw-fix {
    max-height: 100%;
    max-width: 100%;
    padding: 0;
  }
}
.profile-user-letter {
  &.kw-fix {
    margin: 0;
    font-size: 1.6rem;
    line-height: 1.6;
  }
}
</style>
