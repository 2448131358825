<template lang="pug">
  .detail-page
    .detail-page-wrapper 
        .detail-page-head
            .detail-page-title
                .detail-page-title-icon
                    svg.icon.icon-investment
                        use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + typeBytype(account.type).icon")
                span {{ typeBytype(account.type).title }}
            <back-btn :path="'/wallet/details'">Реквизиты</back-btn>
        .detail-page-form-block
            form.detail-page-form
                .form-fullname
                    .form-field(:class="{focused: nameFieldFocused, filled: nameField}")
                        label.form-label() Имя
                        input.form-input(
                            type="text"
                            maxlength='16'
                            name="name"
                            v-model="nameField"
                            @focus="nameFieldFocused = true"
                            @blur="onBlurInputHandler"
                        )
                    .form-field(:class="{focused: surnameFieldFocused, filled: surnameField}")
                        label.form-label() Фамилия
                        input.form-input(
                            type="text"
                            maxlength='16'
                            name="surname"
                            v-model="surnameField"
                            @focus="surnameFieldFocused = true"
                            @blur="onBlurInputHandler"
                        )
                    .form-field(:class="{focused: middlenameFieldFocused, filled: middlenameField}")
                        label.form-label() Отчество
                        input.form-input(
                            type="text"
                            maxlength='16'
                            v-model="middlenameField"
                            name="middle_name"
                            @focus="middlenameFieldFocused = true"
                            @blur="onBlurInputHandler"
                        )
                .form-row.kw-row
                  KwInput(
                    v-for="( field, index ) in typeItem.fields"
                    :key="field.id"
                    v-model="otherFields[field.id]"
                    :title="field.title"
                    :id="field.id"
                    :type="field.type"
                  )
    .detail-page-btn-wrapper
      button.button(@click="save")
        svg.icon.icon-check
          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#check")
        | Сохранить


</template>

<script>
import KwInput from "/src/components/wallet/kwinput";

import { mapGetters } from "vuex";
const STORE = "wallet";

export default {
  components: {
    KwInput,
  },
  data() {
    return {
      id: "",
      nameField: "",
      surnameField: "",
      middlenameField: "",
      nameFieldFocused: false,
      surnameFieldFocused: false,
      middlenameFieldFocused: false,
      otherFields: {},
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(STORE, ["accountListItem", "types", "typeBytype"]),
    isCreate() {
      if (this.$route?.path.includes("details-create")) {
        return true;
      } else {
        return false;
      }
    },
    typeItem() {
      if (this.isCreate) {
        if (this.types.hasOwnProperty(this.$route?.params?.id)) {
          return this.types[this.$route?.params?.id];
        } else {
          return this.types[0] || {};
        }
      } else {
        return this.typeBytype(this.account.type) || {};
      }
    },
    account() {
      let item = null;
      if (this.isCreate) {
        item = this.typeItem;
      } else {
        item = this.accountListItem(this.$route?.params?.id);
      }
      if (item) {
        this.id = item.id;
        return item;
      } else {
        return {
          title: "Добавить реквизиты",
          icon: "ancient-building",
        };
      }
    },
  },
  methods: {
    save() {
      this.$store
        .dispatch(`${STORE}/editAccount`, {
          id: this.isCreate ? "" : this.id,
          type: this.account.type,
          name: this.nameField,
          surname: this.surnameField,
          fathername: this.middlenameField,
          accountFields: { ...this.otherFields },
        })
        .then(() => {
          this.$router.push("/wallet/details");
        })
        .catch(() => {});
    },
    onBlurInputHandler() {
      this.nameFieldFocused = false;
      this.surnameFieldFocused = false;
      this.middlenameFieldFocused = false;
    },
    userSync() {
      this.nameField = this.user?.name || "";
      this.surnameField = this.user?.surname || "";
      this.middlenameField = this.user?.fathername || "";
    },
    getAccountFieldValueById(id) {
      return this.account.fields.find((item) => item.id == id).value;
    },
  },
  watch: {
    user() {
      this.userSync();
    },
  },
  mounted() {
    if (Array.isArray(this.typeItem?.fields)) {
      this.typeItem.fields.forEach((item) => {
        if (this.isCreate) {
          this.$set(this.otherFields, item.id, "");
        } else {
          this.$set(
            this.otherFields,
            item.id,
            this.getAccountFieldValueById(item.id)
          );
        }
      });
    }
    this.userSync();
    this.$store.commit("loaderOff");
  },
};
</script>

<style lang="scss">
.kw-row {
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
