export function enabled(state) {
  return state.enabled === true;
}

export function unread(state) {
  for (const item of state.array) {
    if (item.isread == false) {
      return true;
    }
  }
  return false;
}

export function notifyArray(state) {
  return Array.isArray(state?.array) ? state.array : [];
}

export function notifyArrayShort(state) {
  const all = notifyArray(state);
  if (all.length == 0) {
    return all;
  }
  return all.slice(0, 3);
}

export function notifyItem(state) {
  return (index) => {
    if (
      state.array.hasOwnProperty(index) &&
      state.array[index].hasOwnProperty("title")
    ) {
      return state.array[index];
    } else {
      return null;
    }
  };
}
