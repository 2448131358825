export function vk(state) {
  return state.vk || "";
}
export function fb(state) {
  return state.fb || "";
}
export function tg(state) {
  return state.tg || "";
}
export function inst(state) {
  return state.inst || "";
}
