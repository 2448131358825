<template lang="pug">
  .statistic-source
    .statistic-empty.statistic-block(v-if="sourcesData.length <= 0")
      img(src="@/assets/images/source-empty.svg" alt="empty")
      p Здесь появится ваша статистика по источникам
    .statistic-block(v-if="sourcesChartData.datasets")
      form.statistic-sort
        .statistic-sort-day(ref="sortDay")
          .statistic-sort-day-one(@click="today") Сегодня
          .statistic-sort-day-one(@click="yesteday") Вчера
          .statistic-sort-day-one.active(@click="timeClear") Месяц

        //- datepicker
        .balance-form-calendar.form-calendar.filter-item
          date-picker(
            v-model="time"
            @clear="timeClear"
            value-type="YYYY-MM-DD"
            format="D MMM YYYY"
            type="date"
            :lang="lang"
            :editable="false"
            title-format="DD-MM-YYYY"
            range
            range-separator=" - "
            :clearable="false"
            :class="{'no-date': !time}"
            placeholder="Выберите период"
          )
            svg.icon.icon-calendar(slot="icon-calendar")
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#calendar")


      .statistic-chart
        SourceChart(:chartData="sourcesChartData" :options="chartOptions")


    //- statistic table
    .statistic-table(v-if="sourcesData.length > 0")
      .statistic-table-thead
        .statistic-table-th
          .statistic-table-th-wrap
            svg.icon.icon-source
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#source")
            span Источник
        .statistic-table-th
          .statistic-table-th-wrap
            svg.icon.icon-visitor
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#visitor")
            span Переходов
        .statistic-table-th
          .statistic-table-th-wrap
            svg.icon.icon-person-plus
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#person-plus")
            span Регистраций
        .statistic-table-th
          .statistic-table-th-wrap
            svg.icon.icon-bag
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#bag")
            span Сделок
        .statistic-table-th
          .statistic-table-th-wrap
            svg.icon.icon-revenue
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#revenue")
            span Доход
        .statistic-table-th
          .statistic-table-th-wrap
            svg.icon.icon-filter
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#filter")
            span Конверсия


      .statistic-table-tbody
        template(v-for="(row, index) in sourcesData")
          .statistic-table-thead(v-if="isMobile")
            .statistic-table-th
              .statistic-table-th-wrap
                svg.icon.icon-source
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#source")
                span Источник
            .statistic-table-th
              .statistic-table-th-wrap
                svg.icon.icon-visitor
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#visitor")
                span Переходов
            .statistic-table-th
              .statistic-table-th-wrap
                svg.icon.icon-person-plus
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#person-plus")
                span Регистраций
            .statistic-table-th
              .statistic-table-th-wrap
                svg.icon.icon-bag
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#bag")
                span Сделок
            .statistic-table-th
              .statistic-table-th-wrap
                svg.icon.icon-revenue
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#revenue")
                span Доход
            .statistic-table-th
              .statistic-table-th-wrap
                svg.icon.icon-filter
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#filter")
                span Конверсия

          .statistic-table-tr
            .statistic-table-td
              .statistic-table-td-wrap
                .statistic-table-pic
                  img(src="@/assets/images/instagram.jpg" alt="source photo")
                span {{ row.source }}
            .statistic-table-td
              .statistic-table-td-wrap
                span {{ row.switch }}
            .statistic-table-td
              .statistic-table-td-wrap
                span {{ row.reg }}
            .statistic-table-td
              .statistic-table-td-wrap
                span {{ row.deals }}
            .statistic-table-td
              .statistic-table-td-wrap
                span {{ numberWithSpaces(row.sum) }} ₽
            .statistic-table-td
              .statistic-table-td-wrap
                span {{ row.percent }}%



</template>

<script>
import SourceChart from "./SourceChart";
import numberWithSpaces from "@/mixins/numberWithSpaces";
import compareDate from "@/mixins/compareDate";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

const STORE = "stat";
import { mapGetters } from "vuex";

export default {
  name: "Source",
  components: {
    SourceChart,
  },
  mixins: [numberWithSpaces, compareDate],
  data() {
    return {
      fetchDebonce: false,
      time: [],
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
      isMobile: false,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        showAllTooltips: true,
        plugins: {
          datalabels: {
            font: {
              size: 15,
            },
            color: "#ffffff",
            formatter: function (value, ctx) {
              let sum = 0;
              const dataArr = ctx.chart.data.datasets[0].data;
              dataArr.map((data) => {
                sum += data;
              });
              return ((value * 100) / sum).toFixed(2) + "%";
            },
          },
        },
        legend: {
          position: "bottom",
          labels: {
            fontColor: "#2B2E32",
            fontSize: 15,
            fontFamily: "'Inter', sans-serif",
            boxWidth: 7,
            usePointStyle: true,
            padding: 40,
          },
        },
        tooltips: {
          enabled: false,
          callbacks: {
            label: (tooltipItems, data) => {
              return tooltipItems.label + "%";
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(STORE, ["sourcesChartData", "sourcesData"]),
  },
  methods: {
    fetch() {
      if (this.fetchDebonce) {
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "FETCH DEBOUNCE REJECT");
        }
        return;
      }
      this.fetchDebonce = true;
      setTimeout(() => {
        this.$store
          .dispatch(`${STORE}/getByKey`, {
            key: "sources",
            filters: {
              range: this.time,
            },
          })
          .catch((err) => {
            if (isLogLevel(LOG_LVL_LIST.ERROR)) {
              console.log(`[ ${LOG_LVL_LIST.ERROR} ]	`, STORE, "fetch", err);
            }
          })
          .finally(() => {
            this.fetchDebonce = false;
            this.$store.commit("loaderOff");
          });
      }, 0);
    },
    onResize() {
      if (window.matchMedia("(max-width: 767px)").matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    changeActiveClass(e) {
      if (e) {
        const active = this.$refs["sortDay"].querySelectorAll(".active");
        active.forEach((item) => item.classList.remove("active"));
        e.target.classList.add("active");
      }
    },
    timeClear(e) {
      this.changeActiveClass(e);
      const ourDate = new Date();
      const pastDate = ourDate.getDate() - 30;
      ourDate.setDate(pastDate);
      this.time = [
        ourDate.toISOString().slice(0, 10),
        new Date().toISOString().slice(0, 10),
      ];
    },
    yesteday(e) {
      this.changeActiveClass(e);
      const ourDate = new Date();
      const pastDate = ourDate.getDate() - 1;
      ourDate.setDate(pastDate);
      this.time = [
        ourDate.toISOString().slice(0, 10),
        ourDate.toISOString().slice(0, 10),
      ];
    },
    today(e) {
      this.changeActiveClass(e);
      const today = new Date().toISOString().slice(0, 10);
      this.time = [today, today];
    },
  },
  watch: {
    time() {
      this.fetch();
    },
  },
  created() {
    this.$store.commit("loaderOn");
    this.onResize();
    window.addEventListener("resize", this.onResize);
    this.timeClear();
    this.fetch();
  },
  mounted() {},
  beforeUnmount() {
    this.$store.commit("loaderOff");
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
