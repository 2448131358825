// import state from "./state";
import * as getters from "./getters";
import * as mutations from "./mutations";
import * as actions from "./actions";

const state = function () {
  return {
    data: {},
    // levelList: [
    //   {
    //     id: 2,
    //     text: "Уровень 1",
    //   },
    //   {
    //     id: 3,
    //     text: "Уровень 2",
    //   },
    //   {
    //     id: 4,
    //     text: "Уровень 3",
    //   },
    //   {
    //     id: 5,
    //     text: "Уровень 4",
    //   },
    //   {
    //     id: 6,
    //     text: "Уровень 5",
    //   },
    // ],
    // productList: [
    //   {
    //     id: 1,
    //     text: "Продажа новостроек",
    //   },
    //   {
    //     id: 2,
    //     text: "Покупка под ремонт",
    //   },
    //   {
    //     id: 3,
    //     text: "Инвестирование",
    //   },
    //   {
    //     id: 4,
    //     text: "Продажа под ремонт",
    //   },
    //   {
    //     id: 5,
    //     text: "Услуга ремонта",
    //   },
    // ],
    // statusList: [
    //   {
    //     id: 2,
    //     text: "Выполняется",
    //     icon: "clock",
    //   },
    //   {
    //     id: 3,
    //     text: "Выполнено",
    //     icon: "check",
    //   },
    //   {
    //     id: 4,
    //     text: "Срыв",
    //     icon: "stop",
    //   },
    // ],
    // dealsByLevels: [
    //   {
    //     level: "Уровень 1",
    //     deals: [
    //       {
    //         title: "Инвестирование",
    //         productIcon: "investment",
    //         progress: 66.63,
    //         number: 28396,
    //         created: "2022-02-13",
    //         completion: "2022-02-24",
    //         income: 48000,
    //         statusId: 2,
    //         // statusIcon: "clock",
    //         user: {
    //           name: "Дмитрий Щ.",
    //           avatar: "",
    //           rank: "newcomer",
    //           iconColor: "#a6c242",
    //         },
    //       },
    //       {
    //         title: "Покупка под ремонт",
    //         productIcon: "repair",
    //         progress: 100,
    //         number: 28396,
    //         created: "2022-02-13",
    //         completion: "2022-02-24",
    //         income: 64000,
    //         statusId: 3,
    //         user: {
    //           name: "Диана Ц.",
    //           avatar: "/images/user3.png",
    //           rank: "specialist",
    //           iconColor: "#a6c242",
    //         },
    //       },
    //       {
    //         title: "Продажа новостроек",
    //         productIcon: "building",
    //         progress: 23.5,
    //         number: 28396,
    //         created: "2022-02-13",
    //         completion: "2022-02-24",
    //         income: 87000,
    //         statusId: 2,
    //         user: {
    //           name: "Ольга Д.",
    //           avatar: "/images/user4.png",
    //           rank: "beginner",
    //           iconColor: "#a6c242",
    //         },
    //       },
    //     ],
    //   },
    //   {
    //     level: "Уровень 2",
    //     deals: [
    //       {
    //         title: "Инвестирование",
    //         productIcon: "investment",
    //         progress: 13.3,
    //         number: 28396,
    //         created: "2022-02-13",
    //         completion: "2022-02-24",
    //         income: 18000,
    //         statusId: 2,
    //         user: {
    //           name: "Виктор Щ.",
    //           avatar: "",
    //           rank: "beginner",
    //           iconColor: "#FF8845",
    //         },
    //       },
    //       {
    //         title: "Покупка под ремонт",
    //         productIcon: "repair",
    //         progress: 100,
    //         number: 28396,
    //         created: "2022-02-13",
    //         completion: "2022-02-24",
    //         income: 9000,
    //         statusId: 3,
    //         user: {
    //           name: "Диана Ц.",
    //           avatar: "/images/user3.png",
    //           rank: "specialist",
    //           iconColor: "#a6c242",
    //         },
    //       },
    //       {
    //         title: "Продажа новостроек",
    //         productIcon: "building",
    //         progress: 23.5,
    //         number: 28396,
    //         created: "2022-02-13",
    //         completion: "2022-02-24",
    //         income: 26000,
    //         statusId: 4,
    //         user: {
    //           name: "Ольга Д.",
    //           avatar: "/images/user4.png",
    //           rank: "beginner",
    //           iconColor: "#a6c242",
    //         },
    //       },
    //       {
    //         title: "Инвестирование",
    //         productIcon: "investment",
    //         progress: 42.5,
    //         number: 28396,
    //         created: "2022-02-13",
    //         completion: "2022-02-24",
    //         income: 18000,
    //         statusId: 2,
    //         user: {
    //           name: "Дмитрий Щ.",
    //           avatar: "",
    //           rank: "newcomer",
    //           iconColor: "#A6C242",
    //         },
    //       },
    //     ],
    //   },
    //   {
    //     level: "Уровень 3",
    //     deals: [
    //       {
    //         title: "Инвестирование",
    //         productIcon: "investment",
    //         progress: 66.6,
    //         number: 28396,
    //         created: "2022-02-13",
    //         completion: "2022-02-24",
    //         income: 48000,
    //         statusId: 2,
    //         user: {
    //           name: "Дмитрий Щ.",
    //           avatar: "",
    //           rank: "newcomer",
    //           iconColor: "#A6C242",
    //         },
    //       },
    //     ],
    //   },
    // ],
  };
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
