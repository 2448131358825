export function array(state) {
  return Array.isArray(state?.array) ? state.array : [];
}

export function doneCount(state) {
  let count = 0;
  state.array.forEach((item) => {
    count += item.isdone === true;
  });
  return count;
}
