<template lang="pug">
    
    .promo
        .promo-tabs
            .promo-tabs-menu
                .promo-tabs-menu-item(
                    :class="{_active: currentPromoTab===promotab.component }"
                    v-for='(promotab, index) in promoTabs' :key="index"
                    @click='promoTabHandler(promotab.component)'
                ) 
                    svg(
                      :class="['icon-' + promotab.icon, 'icon' ]"
                    )
                        use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + promotab.icon")
                    span {{ promotab.title }}
        .promo-content
          component(
            :is="currentPromoTab"
          )
  
</template>

<script>
import Banners from "@/components/promo/Banners";
import Video from "@/components/promo/Video";
import changeTitle from "@/mixins/changeTitle";
import emitCloseMenu from "@/mixins/emitCloseMenu";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export default {
  components: {
    Banners,
    Video,
  },
  mixins: [changeTitle, emitCloseMenu],
  data() {
    return {
      promoTabs: [
        {
          title: "Баннеры",
          icon: "banner",
          component: "Banners",
        },
        {
          title: "Видео",
          icon: "video",
          component: "Video",
        },
      ],
      currentPromoTab: "Banners",
      pageName: "Промо",
    };
  },
  methods: {
    promoTabHandler(tabComponentName) {
      this.currentPromoTab = tabComponentName;
    },
  },
  created() {
    this.emitCloseMenu();
    this.$store
      .dispatch("promo/fetch")
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(`[ ${LOG_LVL_LIST.ERROR} ]	`, STORE, "fetch", err);
        }
      })
      .finally(() => {
        this.$store.commit("loaderOff");
      });
  },
  mounted() {},
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.changeTitle(vm.pageName);
    });
  },
};
</script>

<style></style>
