<template lang="pug">
  .rating
    .rating-tabs
      .rating-tabs-menu
        .rating-tabs-menu-item(
          v-for='(tab, index) in tabs'
          :key="index"
          @click="selectSeason = tab.season"
          :class="[{selected: selectSeason===tab.season}]"
        )
          svg(class="icon" :class="`icon-` + tab.icon")
            use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + tab.icon")
          span {{ tab.title }}

      .rating-tabs-content
        RatingSeason(:season="selectSeason")

</template>

<script>
import RatingSeason from "@/components/rating/RatingSeason";
import changeTitle from "@/mixins/changeTitle";
import emitCloseMenu from "@/mixins/emitCloseMenu";

export default {
  name: "Rating",
  components: {
    RatingSeason,
  },
  mixins: [changeTitle, emitCloseMenu],
  data() {
    return {
      pageName: "Рейтинги",
      selectSeason: 1,
      tabs: [
        {
          icon: "calendar",
          title: "Сезон I",
          season: 1,
        },
        {
          icon: "calendar",
          title: "Сезон II",
          season: 2,
        },
        {
          icon: "calendar",
          title: "Сезон III",
          season: 3,
        },
        {
          icon: "calendar",
          title: "Сезон IV",
          season: 4,
        },
      ],
    };
  },
  computed: {},
  methods: {},
  created() {
    this.emitCloseMenu();
    this.selectSeason = Math.floor((new Date().getMonth() + 3) / 3);
    this.$store.commit("loaderOff");
  },
  mounted() {},
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.changeTitle(vm.pageName);
    });
  },
};
</script>
