export function allTmpData(state) {
  return { ...state.personaldata, passport: { ...state.passport } };
}

export function personaldata(state) {
  return state.personaldata;
}

export function passport(state) {
  return state.passport;
}

export function address(state) {
  return state.address;
}
