<template lang="pug">
  button.profile-edit-back.button.button-grey(
    @click="$router.push(`/profile`)"
    )
    svg.icon.icon-arrow-left
        use(xlink:href="@/assets/images/sprite/sprite-inline.svg#arrow-left")
    | Профиль
</template>

<script>
export default {
  name: 'profile-back-btn',
};
</script>

<style></style>
