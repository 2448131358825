<template lang="pug">
  button.button.button-grey(
    @click="$router.push(path)"
  )
    svg(class="icon icon-arrow-left")
      use(xlink:href="@/assets/images/sprite/sprite-inline.svg#arrow-left")
    <slot></slot>
</template>

<script>
export default {
  name: "back-btn",
  props: {
    path: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.button {
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  flex-shrink: 0;
}
</style>
