<template lang="pug">

  .application-new
    .application-new-wrapper
      .application-new-success
        img(src="@/assets/images/sprite/appsuccess.svg")
        p После оформления заявки менеджер компании «ОПН» свяжется по контактным данным в течение рабочего дня


</template>

<script>
export default {
  created() {
    this.$store.commit("loaderOff");
  },
};
</script>

<style></style>
