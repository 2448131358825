<template lang="pug">
  .notification
    .notification-block
      Notification(v-for="( item, index) in notifyArray" :key="index" v-bind="{index, ...item}")
      button.notification-deleteAll(@click="removeAll")
        svg.icon.icon-trash
          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#trash")
        | Очистить все



</template>

<script>
import { mapGetters } from "vuex";
import Notification from "/src/components/notifications/notification";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export default {
  name: "Notifications",
  components: { Notification },
  data() {
    return {
      pageName: "Уведомления",
    };
  },
  computed: {
    ...mapGetters("notifications", ["notifyArray"]),
  },
  created() {
    this.emitCloseMenu();
  },
  methods: {
    goHome() {
      this.$router.push("/");
    },
    removeAll() {
      this.$store
        .dispatch("notifications/remove")
        .then(this.goHome)
        .catch(() => {});
    },
    emitCloseMenu() {
      this.$emit("closeMenu");
    },
  },
  created() {
    this.$emit("changeTitle", this.pageName);
    this.$store
      .dispatch("notifications/fetch")
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(`[ ${LOG_LVL_LIST.ERROR} ]	`, STORE, "fetch", err);
        }
      })
      .finally(() => {
        this.$store.commit("loaderOff");
      });
  },
  mounted() {},
};
</script>
