<template lang="pug">
    .profile-edit
        .profile-edit-wrapper 
            .profile-edit-head
                .profile-edit-title
                    .profile-edit-title-icon
                        svg.icon.icon-profile
                            use(xlink:href="@/assets/images/sprite/sprite-inline.svg#profile")
                    span Адрес
                <profile-back-btn/>
            .profile-progressbar-wrapper.profile-progressbar-wrapper--address
              .profile-progressbar
                .progressbar-current
                .progressbar-body
                    .progressbar-step-item._current
                        .progressbar-circle 1
                        span Адрес проживания
                    .progressbar-step-item
                        .progressbar-circle 2
                        span Загрузка документа
            .profile-personality-form-block
              form.profile-personality-form
                .form-row
                    .form-field.filled
                      label.form-label() Адрес
                      input.form-input(
                        id="suggest"
                        type="text"
                        name="address"
                        v-model="address"
                        @focus="onFocusInputHandler"
                        @blur="onBlurInputHandler"
                      )
            .profile-address-block
              //- .profile-address-map
                //- img(src="@/assets/images/map.png" alt="карта")

                //- v-if="mapshow"
              yandex-map(
                :zoom="zoom"
                :coords="coords"
                :settings="ymapsettings"
                :controls="['fullscreenControl', 'geolocationControl', 'zoomControl']"
                @map-was-initialized="ymapinit"
                @click="ymapclick"
              )
                //- ymap-marker(
                //-   marker-id="kw-ymap-marker-myplace"
                //-   :coords="myCoords"
                //-   :icon="myplaceIcon"
                //- )
                ymap-marker(
                  marker-id="kw-ymap-marker-address"
                  :coords="coords"
                  :icon="addressIcon"
                )
              //- .loading(v-else)
              //-   inline-svg(class="loader" :src="require('@/assets/images/page-loader.svg')")

                      
        .profile-edit-btn-wrapper
          button.button.profile-edit-continue-btn.profile-edit-continue-btn--full(
              @click="nextStep")
            | Продолжить
            svg.icon.icon-arrow-right
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#arrow-right")
            


</template>

<script>
import { yandexMap, ymapMarker, loadYmap } from "vue-yandex-maps";
import { mapGetters } from "vuex";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";
export default {
  components: { yandexMap, ymapMarker },
  data() {
    return {
      ymaps: null,
      address: "",
      mapshow: false,
      balloonContent: "",
      ymapsettings: {
        apiKey: process.env.VUE_APP_YMAP,
        lang: "ru_RU",
        version: "2.1",
      },
      zoom: 16,
      coords: [54.62896654088406, 39.731893822753904],
      // myCoords: [54.62896654088406, 39.731893822753904],
      myplaceIcon: {
        icon: "islands",
        color: "geolocation",
      },
      addressIcon: {
        icon: "islands",
        color: "orangePerson",
      },
    };
  },
  methods: {
    ymapclick(e) {
      this.coords = e.get("coords");
      this.reverseGeocoding(this.coords);
    },
    ymapinit(myMap) {
      // console.log("ymap init", myMap);
    },
    directGeocoding(address) {
      if (!this.ymaps) return Promise.reject("ymaps not defined");
      return this.ymaps
        .geocode(address, {
          /**
           * Опции запроса
           * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/geocode.xml
           */
          // Сортировка результатов от центра окна карты.
          // boundedBy: myMap.getBounds(),
          // strictBounds: true,
          // Вместе с опцией boundedBy будет искать строго внутри области, указанной в boundedBy.
          // Если нужен только один результат, экономим трафик пользователей.
          results: 1,
        })
        .then((res) => {
          // Выбираем первый результат геокодирования.
          const firstGeoObject = res.geoObjects.get(0);
          const coords = firstGeoObject.geometry.getCoordinates();
          // Координаты геообъекта.
          this.coords = coords;
          return Promise.resolve();
        })
        .catch((err) => {
          if (isLogLevel(LOG_LVL_LIST.ERROR)) {
            console.log(`[ ${LOG_LVL_LIST.ERROR} ]	`, "geocode", err);
          }
        });
    },
    reverseGeocoding(coords) {
      if (!this.ymaps) return Promise.reject("ymaps not defined");
      this.ymaps
        .geocode(coords)
        .then((res) => {
          const firstGeoObject = res.geoObjects.get(0);
          // задаем строку с адресом объекта.
          this.address = firstGeoObject.getAddressLine();

          if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
            console.log(
              `[ ${LOG_LVL_LIST.DEBUG} ]	`,
              "watch coords",
              coords,
              "address: " + this.address
            );
          }
        })
        .catch((err) => {
          if (isLogLevel(LOG_LVL_LIST.ERROR)) {
            console.log(
              `[ ${LOG_LVL_LIST.ERROR} ]	`,
              "watch coords",
              "geocode",
              err
            );
          }
        });
    },
    // Определяем адрес по координатам (обратное геокодирование).
    nextStep() {
      this.$store.commit("personEdit/saveAddress", this.address);
      this.$router.push(`/address-edit-step-2`);
    },
    onFocusInputHandler(event) {
      event.target.parentElement.classList.add("focused");
      event.target.focus();
    },
    onBlurInputHandler(event) {
      let inputValue = event.target.value;
      event.target.parentElement.classList.remove("focused");
      if (inputValue === "") {
        event.target.parentElement.classList.remove("filled");
      } else {
        event.target.parentElement.classList.add("filled");
      }
    },
  },
  computed: {
    ...mapGetters({ userAddress: "address" }),
    ...mapGetters("personEdit", { tmpAddress: "address" }),
  },
  watch: {
    userAddress() {
      this.address = this.tmpAddress || this.userAddress;
      this.directGeocoding(this.address).then(() => {
        this.mapshow = true;
      });
    },
  },
  created() {
    this.$store.commit("loaderOff");
  },
  async mounted() {
    await loadYmap({ ...this.ymapsettings });
    this.address = this.tmpAddress || this.userAddress;
    // здесь доступна переменная ymaps
    this.ymaps = ymaps;
    if (!this.address) {
      ymaps.geolocation
        .get({
          // Карта автоматически отцентрируется по положению пользователя.
          mapStateAutoApply: true,
        })
        .then((result) => {
          const coords = result.geoObjects.position;
          if (coords && coords.length > 0) {
            this.coords = coords;
            this.reverseGeocoding(coords);
            this.mapshow = true;
          }
        })
        .catch((err) => {
          if (isLogLevel(LOG_LVL_LIST.ERROR)) {
            console.log(
              `[ ${LOG_LVL_LIST.ERROR} ]	`,
              "monted",
              "geolocation",
              err
            );
          }
        });
    } else {
      this.directGeocoding(this.address)
        .then(() => {
          this.mapshow = true;
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // Подключаем поисковые подсказки к полю ввода.
    const suggestView = new ymaps.SuggestView("suggest");
    suggestView.events.add("select", (e) => {
      const displayName = e.get("item")?.displayName;
      if (displayName) {
        this.address = displayName;
      }
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(
          `[ ${LOG_LVL_LIST.DEBUG} ]	`,
          "suggestView",
          "event select",
          this.address
        );
      }
      this.directGeocoding(this.address).catch((err) => {
        console.log(err);
      });
    });
  },
};
</script>

<style lang="scss">
.profile-address-block {
  .ymap-container {
    width: 100%;
    height: 100%;
  }
}
</style>
