// import state from "./state";
import * as getters from "./getters";
import * as mutations from "./mutations";
import * as actions from "./actions";

const state = function () {
  return {
    enabled: false,
    array: [
      // {
      //   id: "1",
      //   title: "Средства успешно выведены",
      //   time: "Час назад",
      //   icon: "wallet",
      //   isread: false,
      //   msg: "Здравствуйте, объявление отклонено. Что делать в этом случае? Объявление ориентировано только на пользователей сообщества, кто в курсе о проекте.",
      // },
      // {
      //   id: "2",
      //   title: "Верификация прошла успешно",
      //   time: "3 часа назад",
      //   icon: "profile",
      //   isread: false,
      //   msg: "Здравствуйте, объявление отклонено. Что делать в этом случае? Объявление ориентировано только на пользователей сообщества, кто в курсе о проекте.",
      // },
      // {
      //   id: "3",
      //   title: "Средства успешно выведены",
      //   time: "Час назад",
      //   icon: "star",
      //   isread: false,
      //   msg: "Здравствуйте, объявление отклонено. Что делать в этом случае? Объявление ориентировано только на пользователей сообщества, кто в курсе о проекте.",
      // },
      // {
      //   title: "Средства успешно выведены",
      //   isread: true,
      // },
    ],
  };
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
