export default {
  balanceFormat: (n) => {
    if (Number.isInteger(n)) {
      if (n === 0) {
        return 0;
      }
      return String(n || "").replace(/(.)(?=(\d{3})+$)/g, "$1 ");
    } else if (Number(n) === n && n % 1 !== 0) {
      const parts = n.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    } else {
      return null;
    }
  },
  firstUserLetter(name) {
    return name.slice(0, 1).toUpperCase();
  },
};
