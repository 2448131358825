import Vue from "vue";
// import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export function FETCH_ALL(state, data) {
  if (data?.banners && data.banners.hasOwnProperty("items")) {
    Vue.set(state, "banners", data.banners);
  }
  if (data?.video && data.video.hasOwnProperty("items")) {
    Vue.set(state, "video", data.video);
  }
}
