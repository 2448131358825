export default {
  methods: {
    onBlurInputHandler(event) {
      let inputValue = event.target.value;
      if (inputValue === '') {
        event.target.parentElement.classList.remove('focused');
      }
    },
  },
};
