<template lang="pug">

  form.login-form.form(@submit.prevent)

    //- login field
    .form-field(:class="{validated: isLoginFieldValidated, focused: ( logFieldFocused || loginField), invalid: invalidLogin}")
      label.form-label() E-mail или телефон
      input.form-input(
          v-model = "loginField"
          type="text", 
          placeholder=""
          @focus="logFieldFocused = true"
          @blur="onBlurInputHandler"
          @input="validateLoginField"
        )

      //- login error message
      <input-error :showVar="invalidLogin">
        <p>Введен неверный E-mail или телефон</p>
      </input-error>


    //- login type buttons
    .login-type
        button.button(
          @click="pwdEntering"
        )
          svg.icon.icon-lock
            use(xlink:href="@/assets/images/sprite/sprite-inline.svg#lock")
          | По паролю
        button.button(
          @click="codeEntering"                    
        )
          svg.icon.icon-envelope
            use(xlink:href="@/assets/images/sprite/sprite-inline.svg#envelope")
          | По коду

</template>

<script>
import validateEmailBool from "@/mixins/validateEmailBool";
import replaceNumberForInput from "@/mixins/replaceNumberForInput";

export default {
  mixins: [validateEmailBool, replaceNumberForInput],
  data() {
    return {
      logFieldFocused: false,
      isLoginFieldValidated: false,
      loginField: "",
      loginValue: "",
      invalidLogin: false,
      emailLoginProceed: false,
    };
  },
  methods: {
    onBlurInputHandler() {
      if (this.loginField === "") {
        this.logFieldFocused = false;
      }
    },
    validateLoginField() {
      this.isLoginFieldValidated = false;
      this.invalidLogin = false;
      this.emailLoginProceed = false;
      let regCheckForPhone = /^\+?7?\s?\(?\d{3}.*/;
      this.loginValue = this.loginField.replace(/\D/g, "").substr(0, 11);
      // let regCheckForPhone = /^\d{3}/;
      // this.loginValue = this.loginField.replace(/\D/g, '');
      if (
        regCheckForPhone.test(this.loginField.slice(0, 7)) &&
        this.loginValue.length >= 3
      ) {
        // console.log('the login field must be the phone number');
        this.loginField = this.replaceNumberForInput(this.loginField);
        if (this.loginValue.length === 11) {
          // this.phoneLoginProceed = true;
          this.isLoginFieldValidated = true;
          // this.$store.commit("changeEnteredLogin", this.loginField);
          // this.$router.push('/login-email1');
        } else {
          this.isLoginFieldValidated = false;
        }
      } else if (this.loginField.length >= 5) {
        // console.log('the login field must be the email');
        this.emailLoginProceed = true;
      }
    },
    pwdEntering() {
      if (this.isLoginFieldValidated) {
        this.$router.push("/login-email2");
      } else if (
        this.emailLoginProceed &&
        this.validateEmailBool(this.loginField)
      ) {
        this.$store.commit("changeEnteredLogin", this.loginField);
        this.$router.push("/login-email2");
      } else {
        this.invalidLogin = true;
      }
    },
    codeEntering() {
      if (
        this.isLoginFieldValidated ||
        (this.emailLoginProceed && this.validateEmailBool(this.loginField))
      ) {
        this.$store.commit("changeEnteredLogin", this.loginField);
        this.$router.push("/login-phone2");
        // this.$router.push("/login-phone1");
      } else {
        this.invalidLogin = true;
      }
    },
  },
  computed: {},
  mounted() {
    if (this.$store.state.enteredLogin) {
      this.logFieldFocused = true;
      this.loginField = this.$store.state.enteredLogin;
      this.validateLoginField();
      if (this.emailLoginProceed && this.validateEmailBool(this.loginField)) {
        this.isLoginFieldValidated = true;
      }
    }
  },
};
</script>

<style></style>
