import Vue from "vue";
// import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export function FETCH_ALL(state, data) {
  Vue.set(state, "data", data);
}

export function SET_ARRAY_BY_KEY(state, { key, data }) {
  if (!state.keys.includes(key)) {
    return;
  }
  const output = Array.isArray(data) ? data : [];
  Vue.set(state, key, output);
}
