import axios from "axios";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export function fetch({ commit }) {
  const url = "/api/news/";
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "GET", url);
  }
  return new Promise((resolve, reject) => {
    axios({ method: "GET", url })
      .then((resp) => {
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, url, "GET SUCCESS: ", resp);
        }
        const data = resp.data;
        commit("FETCH_ALL", data);
        resolve();
      })
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(
            `[ ${LOG_LVL_LIST.ERROR} ]	`,
            url,
            err,
            "GET RESPONSE: ",
            err.response
          );
        }
        reject();
      });
  });
}

export function getMore({ commit, getters }, page) {
  const url = "/api/news/";
  const method = "POST";
  const data = { action: "get", page };
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, method, url, data);
  }
  return new Promise((resolve, reject) => {
    axios({ method, url, data })
      .then((resp) => {
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(
            `[ ${LOG_LVL_LIST.DEBUG} ]	`,
            url,
            method,
            "SUCCESS",
            resp
          );
        }
        const data = resp.data;
        commit("addNews", data);
        resolve();
      })
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(
            `[ ${LOG_LVL_LIST.ERROR} ]	`,
            url,
            err,
            "GET RESPONSE: ",
            err.response
          );
        }
        reject();
      });
  });
}

export function read({ commit, getters }) {
  return new Promise((resolve, reject) => {
    commit("read");
    const data = { idArray: getters.allIdArray };
    const url = "/api/news/";
    if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
      console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "PUT", url, data);
    }
    axios({ method: "PUT", url, data })
      .then((resp) => {
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, url, "PUT SUCCESS: ", resp);
        }
        resolve();
      })
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(
            `[ ${LOG_LVL_LIST.ERROR} ]	`,
            url,
            err,
            "PUT RESPONSE: ",
            err.response
          );
        }
        reject();
      });
  });
}
