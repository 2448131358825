<template lang="pug">
  .login
    .login-block
      a.logo(href="#")
          img(src="@/assets/images/logo.svg" alt="logo")
      .login-main
          .login-question-circle
            img(src="@/assets/images/question.svg")
          h1.title-h1 Проверьте почту 
          p.login-check-text Мы только что отправили вам электронное письмо на mail@mail.com, нажмите на ссылку, чтобы войти в систему.
         
          // registration question and link
          p.login-register-question Вы ещё не зарегистрированы? 
          router-link.login-register-link.link(to="/registration") Зарегистрироваться
            svg.icon.icon-arrow-right
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#arrow-right")
    .login-poster
        img(src="@/assets/images/sign-in.jpg")
        .login-poster-inner
            p.title-h1 Наколдуй свою структуру
            router-link(to="/registration").button Присоединиться
    
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style></style>
