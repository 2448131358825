<template lang="pug">
    .profile-edit
        .profile-edit-wrapper 
            .profile-edit-head
                .profile-edit-title
                    .profile-edit-title-icon.newcomer
                        svg.icon.icon-chevron
                            use(xlink:href="@/assets/images/sprite/sprite-inline.svg#chevron")
                    span Аватар и никнейм
                <profile-back-btn/>
            .profile-personality-form-block
                form.profile-personality-form(@submit.prevent)

                  //- user icon editing field 
                  .profile-icon-edit
                    .profile-icon
                      img.user-img(
                        ref="userImg"
                        v-show="showImg"
                        :src="avatar"
                      )
                      .profile-icon-no-image(v-show="!showImg")
                        .profile-user-letter {{firstUserLetter}}
                        .profile-photo-btn
                          svg.icon.icon-camera
                            use(xlink:href="@/assets/images/sprite/sprite-inline.svg#camera")
                      input.form-input-file(
                        ref="inputFile"
                        type='file'
                        :accept="accept"
                        @change="previewImage"
                      )

                  //- nickname field
                  .form-field(
                    :class="{focused: ( nickNameFieldFocused || nickNameField), filled: nickNameFieldFilled}"
                  )
                    label.form-label Никнейм
                    input.form-input(
                        type="text"
                        maxlength='16'
                        name="nickname"
                        v-model="nickNameField"
                        @focus="nickNameFieldFocused = true"
                        @blur="onBlurInputHandler"
                    )
                    
        .profile-edit-btn-wrapper
            button.button.profile-edit-save-btn(@click="save")
                svg.icon.icon-check
                    use(xlink:href="@/assets/images/sprite/sprite-inline.svg#check")
                | Сохранить
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export default {
  data() {
    return {
      showImg: false,
      accept: ["image/jpeg", "image/png"],
      nickNameField: "",
      nickNameFieldFocused: false,
      nickNameFieldFilled: true,
      nickNameFieldValidated: false,
    };
  },
  methods: {
    onBlurInputHandler() {
      this.nickNameFieldFocused = false;
      if (this.nickNameField === "") {
        this.nickNameFieldFilled = false;
      } else {
        this.nickNameFieldFilled = true;
      }
    },
    validFileType(file) {
      return this.accept.includes(file.type);
    },
    validFile(file) {
      return (
        file &&
        this.validFileType(file) &&
        file.size >= 3000 &&
        file.size <= 8388608
      );
    },
    previewImage(event) {
      const input = event.target;
      const imgTag = this.$refs["userImg"];
      imgTag.alt = input.files[0].name;
      this.showImg = true;
      if (this.validFile(input.files[0])) {
        const reader = new FileReader();
        reader.onload = (e) => {
          imgTag.src = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      } else {
        imgTag.alt = "Неверный размер файла или тип";
        input.value = "";
      }
    },
    sendAvatar() {
      const formData = new FormData();
      const file = this.$refs["inputFile"].files[0];
      const url = "/api/upload_meta/";
      if (this.validFile(file)) {
        formData.set("avatar", file);
      } else {
        setTimeout(() => {
          this.$router.push(`/profile`);
        }, 100);
        return;
      }
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "POST", url);
        for (let v of formData.entries()) {
          console.log(v);
        }
      }
      axios({
        method: "POST",
        url,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((resp) => {
          if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
            console.log(
              `[ ${LOG_LVL_LIST.DEBUG} ]	`,
              url,
              "POST SUCCESS",
              resp.data
            );
          }
          this.$store.commit("setUser", resp.data);
          setTimeout(() => {
            this.$router.push(`/profile`);
          }, 100);
        })
        .catch((err) => {
          if (isLogLevel(LOG_LVL_LIST.ERROR)) {
            console.log(
              `[ ${LOG_LVL_LIST.ERROR} ]	`,
              url,
              err,
              "POST RESPONSE",
              err.response
            );
          }
        });
    },
    save() {
      const data = { nickname: this.nickNameField };
      this.$store
        .dispatch("setMetaItem", data)
        .then(this.sendAvatar, this.sendAvatar);
    },
    syncStore() {
      this.showImg = this.avatar ? true : false;
      this.nickNameField = this.userNicknameStore;
    },
  },
  computed: {
    ...mapGetters(["user", "avatar"]),
    ...mapGetters({ userNicknameStore: "title" }),
    firstUserLetter() {
      return this.nickNameField
        ? this.nickNameField.slice(0, 1).toUpperCase()
        : "";
    },
  },
  watch: {
    userNicknameStore() {
      this.syncStore();
    },
  },
  created() {
    this.$store.commit("loaderOff");
  },
  mounted() {
    this.syncStore();
  },
};
</script>

<style lang="scss" scoped>
.form-field {
  margin-bottom: 0;
}
</style>
