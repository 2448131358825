import Vue from "vue";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";
const NAMESPACE = "notifications";

export function enable(state, status = true) {
  state.enabled = status;
}

// export function unread(state) {
//   state.isread = false;
// }

export function read(state, index = -1) {
  if (index === -1) {
    state.isread = true;
  } else {
    const item = state.array[index];
    item.isread = true;
    Vue.set(state.array, index, item);
  }
}

export function FETCH_ALL(state, data) {
  state = Object.assign(state, data);
}

export function CLEAR_ALL(state) {
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "CLEAR_ALL");
  }
  state.array = [];
}

export function REMOVE(state, index) {
  if (index?.namespace && index?.mutation) {
    return;
  }
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(
      `[ ${LOG_LVL_LIST.DEBUG} ]	`,
      "REMOVE",
      index,
      state.array[index]
    );
  }
  if (state.array.hasOwnProperty(index)) {
    Vue.delete(state.array, index);
  }
}

export function SOCKET_ADD(state, data) {
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "SOCKET ADD", data);
  }

  if (!Array.isArray(state.array)) {
    state.array = [];
  }
  if (data?.value) {
    state.array.push(data.value);
  }
}
