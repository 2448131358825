<template lang="pug">
  .statistic-attendance
    .statistic-empty.statistic-block(v-if="attendanceData.length <= 0")
      img(src="@/assets/images/attendance-empty.svg" alt="empty")
      p Здесь появится ваша статистика посещаемости

    .statistic-block(v-if="attendanceChartData.datasets")
      form.statistic-sort
        .statistic-sort-day(ref="sortDay")
          .statistic-sort-day-one(@click="today") Сегодня
          .statistic-sort-day-one(@click="yesteday") Вчера
          .statistic-sort-day-one.active(@click="timeClear") Месяц

        //- datepicker
        .balance-form-calendar.form-calendar.filter-item
          date-picker(
            v-model="time"
            @clear="timeClear"
            value-type="YYYY-MM-DD"
            format="D MMM YYYY"
            type="date"
            :lang="lang"
            :editable="false"
            title-format="DD-MM-YYYY"
            range
            range-separator=" - "
            :clearable="false"
            :class="{'no-date': !time}"
            placeholder="Выберите период"
          )
            svg.icon.icon-calendar(slot="icon-calendar")
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#calendar")
      .statistic-chart
        //- RevenueChart
        RevenueChart(:chartData="attendanceChartData" :options="chartOptions")

    .statistic-table(v-if="attendanceData.length > 0")
      // thead {{{
      .statistic-table-thead( :class="{'statistic-table-th--total': isMobile}")
        .statistic-table-th
          .statistic-table-th-wrap
            svg.icon.icon-calendar
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#calendar")
            span Период
        .statistic-table-th
          .statistic-table-th-wrap
            svg.icon.icon-visitor
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#visitor")
            span Переходов
        .statistic-table-th
          .statistic-table-th-wrap
            svg.icon.icon-person-plus
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#person-plus")
            span Регистраций
        .statistic-table-th
          .statistic-table-th-wrap
            svg.icon.icon-bag
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#bag")
            span Сделок
        .statistic-table-th
          .statistic-table-th-wrap
            svg.icon.icon-revenue
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#revenue")
            span Доход
        .statistic-table-th
          .statistic-table-th-wrap
            svg.icon.icon-filter
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#filter")
            span Конверсия
      .statistic-table-tbody

        template(v-for="(row, index) in attendanceData")
          // thead {{{
          .statistic-table-thead(v-if="isMobile")
            .statistic-table-th
              .statistic-table-th-wrap
                svg.icon.icon-calendar
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#calendar")
                span Период
            .statistic-table-th
              .statistic-table-th-wrap
                svg.icon.icon-bag
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#bag")
                span Сделок
            .statistic-table-th
              .statistic-table-th-wrap
                svg.icon.icon-revenue
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#revenue")
                span Доход
            .statistic-table-th
              .statistic-table-th-wrap
                svg.icon.icon-filter
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#filter")
                span Конверсия
          // }}}
          .statistic-table-tr
            .statistic-table-td
              .statistic-table-td-wrap
                span {{ dateFormat(row.date) }}
            .statistic-table-td
              .statistic-table-td-wrap
                span {{ row.switch }}
            .statistic-table-td
              .statistic-table-td-wrap
                span {{ row.reg }}
            .statistic-table-td
              .statistic-table-td-wrap
                span {{ row.deals }}
            .statistic-table-td
              .statistic-table-td-wrap
                span {{ numberWithSpaces(row.sum) }} ₽
            .statistic-table-td
              .statistic-table-td-wrap
                span {{ row.percent }}%
</template>

<script>
import RevenueChart from "./RevenueChart";
import numberWithSpaces from "@/mixins/numberWithSpaces";
import onResize from "@/mixins/onResize";

const STORE = "stat";
import { mapGetters } from "vuex";

export default {
  name: "Attendance",
  components: {
    RevenueChart,
  },
  mixins: [numberWithSpaces, onResize],
  data() {
    return {
      fetchDebonce: false,
      time: [],
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
      isMobile: false,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          // display: false,
          position: "bottom",
          labels: {
            // This more specific font property overrides the global property
            fontColor: "#2B2E32",
            fontSize: 15,
            fontFamily: "'Inter', sans-serif",
            // fontWeight: 500,
            boxWidth: 4,
            usePointStyle: true,
            padding: 50,
          },
        },
        scales: {
          yAxes: [
            {
              id: "deals",
              label: "Сделок",
              ticks: {
                fontFamily: "'Inter', sans-serif",
                fontColor: "#91989F",
                padding: 10,
                min: 0,
                callback: function (value, index, values) {
                  return value;
                },
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontFamily: "'Inter', sans-serif",
                fontColor: "#91989F",
                // fontSize: 11,
                padding: 10,
                callback: function (value, index, values) {
                  return (
                    new Intl.DateTimeFormat("ru-RU", {
                      dateStyle: "short",
                    }).format(new Date(value)) || value
                  );
                },
              },
            },
          ],
        },
        tooltips: {
          enabled: true,
          displayColors: false,
          callbacks: {
            label: (tooltipItem, data) => {
              return data.datasets[tooltipItem.datasetIndex].label === "Доход"
                ? tooltipItem.yLabel + " ₽"
                : tooltipItem.yLabel;
            },
            title: function () {},
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(STORE, ["attendanceData", "attendanceChartData"]),
  },
  watch: {
    time() {
      this.fetch();
    },
  },
  methods: {
    fetch() {
      if (this.fetchDebonce) {
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "FETCH DEBOUNCE REJECT");
        }
        return;
      }
      this.fetchDebonce = true;
      this.$store
        .dispatch(`${STORE}/getByKey`, {
          key: "attendance",
          filters: {
            range: this.time,
          },
        })
        .catch((err) => {
          if (isLogLevel(LOG_LVL_LIST.ERROR)) {
            console.log(`[ ${LOG_LVL_LIST.ERROR} ]	`, STORE, "fetch", err);
          }
        })
        .finally(() => {
          this.fetchDebonce = false;
          this.$store.commit("loaderOff");
        });
    },
    changeActiveClass(e) {
      if (e) {
        const active = this.$refs["sortDay"].querySelectorAll(".active");
        active.forEach((item) => item.classList.remove("active"));
        e.target.classList.add("active");
      }
    },
    timeClear(e) {
      this.changeActiveClass(e);
      const ourDate = new Date();
      const pastDate = ourDate.getDate() - 30;
      ourDate.setDate(pastDate);
      this.time = [
        ourDate.toISOString().slice(0, 10),
        new Date().toISOString().slice(0, 10),
      ];
    },
    yesteday(e) {
      this.changeActiveClass(e);
      const ourDate = new Date();
      const pastDate = ourDate.getDate() - 1;
      ourDate.setDate(pastDate);
      this.time = [
        ourDate.toISOString().slice(0, 10),
        ourDate.toISOString().slice(0, 10),
      ];
    },
    today(e) {
      this.changeActiveClass(e);
      const today = new Date().toISOString().slice(0, 10);
      this.time = [today, today];
    },
    onResize() {
      if (window.matchMedia("(max-width: 767px)").matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    dateFormat(date) {
      return (
        new Intl.DateTimeFormat("ru-RU", { dateStyle: "short" }).format(
          new Date(date)
        ) || date
      );
    },
  },
  created() {
    this.$store.commit("loaderOn");
    this.fetch();
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  mounted() {
    this.timeClear();
  },
  beforeUnmount() {
    this.$store.commit("loaderOff");
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
