<template lang="pug">
  
  form.login-form.form(@submit.prevent)

    //- login field
    .form-field(:class="{validated: isLoginFieldValidated, focused: (logFieldFocused || loginField), invalid: invalidLogin}")
      label.form-label() E-mail или телефон
      //- input.form-input(
      //-     v-model = "loginField"
      //-     type="text",
      //- )
      imask-input.form-input(
        v-model:typed="loginField"
        type="text"
        :unmask="true"
        :mask="mask"
        :prepare="prepareMask"
        @focus="logFieldFocused = true"
        @blur="onBlurInputHandler(true)"
        @input="validateLoginField"
      )
          //- :disabled="isLoginFieldDisabled"

      //- login error message
      <input-error :showVar="invalidLogin">
        <p>Введен неверный E-mail или телефон</p>
      </input-error>
    
</template>

<script>
import validateEmailBool from "@/mixins/validateEmailBool";
import { IMaskComponent } from "vue-imask";

export default {
  components: {
    "imask-input": IMaskComponent,
  },
  mixins: [validateEmailBool],
  data() {
    return {
      mask: [
        {
          mask: "+{7}(000)000-00-00",
        },
        {
          mask: /^\S*@?\S*$/,
        },
      ],
      logFieldFocused: false,
      emailLoginProceed: false,
      // phoneLoginProceed: false,
      isLoginFieldValidated: false,
      loginField: "",
      loginValue: "",
      pasted: false,
      // isLoginFieldDisabled: false,
      invalidLogin: false,
      timeout: null,
    };
  },
  methods: {
    prepareMask(appended, masked) {
      if (appended === "8" && masked.value === "") {
        return "";
      }
      return appended;
    },
    onBlurInputHandler(forced = false) {
      if (this.loginField === "") {
        this.logFieldFocused = false;
      }
      if (this.emailLoginProceed) {
        if (this.validateEmailBool(this.loginField)) {
          // console.log('the login field is email and it is valid');
          this.invalidLogin = false;
          this.isLoginFieldValidated = true;
          // this.isLoginFieldDisabled = true;
          this.$store.commit("changeEnteredLogin", this.loginField);
          this.$router.push("/login-email1");
        } else if (forced) {
          this.invalidLogin = true;
        }
      }
    },
    validateLoginField() {
      this.invalidLogin = false;
      this.emailLoginProceed = false;
      let regCheckForPhone = /^\+?7?\s?\(?\d{3}.*/;
      this.loginValue = this.loginField.replace(/\D/g, "").substr(0, 11);
      // let regCheckForPhone = /^\d{3}/;
      // this.loginValue = this.loginField.replace(/\D/g, '');
      if (
        regCheckForPhone.test(this.loginField.slice(0, 7)) &&
        this.loginValue.length >= 3
      ) {
        // console.log('the login field must be the phone number');
        // this.loginField = this.replaceNumberForInput(this.loginField);
        if (this.loginValue.length === 11) {
          // this.phoneLoginProceed = true;
          this.isLoginFieldValidated = true;
          // this.isLoginFieldDisabled = true;
          this.$store.commit("changeEnteredLogin", this.loginField);
          this.$router.push("/login-email1");
        } else {
          this.isLoginFieldValidated = false;
          // this.isLoginFieldDisabled = false;
          // this.phoneLoginProceed = false;
        }
      } else if (this.loginField.length >= 5) {
        // console.log('the login field must be the email');
        this.emailLoginProceed = true;
      }

      this.onBlurInputHandler();
    },
    // replaceNumberForInput(value) {
    //   let val = "";
    //   const x = value
    //     .replace(/\D/g, "")
    //     .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

    //   if (!x[3]) {
    //     val = x[1] === "7" ? "+" + x[1] + " " + x[2] : "+7 " + x[1] + x[2];
    //   } else {
    //     val = !x[3]
    //       ? "+" + x[1] + " " + x[2]
    //       : "+" +
    //         x[1] +
    //         " " +
    //         x[2] +
    //         "-" +
    //         x[3] +
    //         (x[4] ? "-" + x[4] : "") +
    //         (x[5] ? "-" + x[5] : "");
    //   }
    //   return val;
    // },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.form {
  .form-field {
    margin-bottom: 0;
  }
}
</style>
