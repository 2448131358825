<template lang="pug">

  .loading(v-if="localLoading")
    <inline-svg class="loader" :src="require('@/assets/images/page-loader.svg')"></inline-svg>

  div(v-else)
    .statistic-source(v-if="array.length == 0")
      .statistic-empty.statistic-block
        p Данных за этот сезон нет
    .rating-list-table(v-else)
      RatingListHead(v-if="!isMobile" :typeSeason="typeSeason")
      //- table body
      .rating-list-table-tbody
        template(v-for="(row, index) in array")
          RatingListHead(v-if="isMobile" :typeSeason="typeSeason")
          //- table rows
          .rating-list-table-tr
            .rating-list-table-td
              .rating-list-table-td-wrap {{ row.place }}
            .rating-list-table-td
              .rating-list-table-td-wrap
                .rating-list-table-pic(:style="{background: row.iconColor}") {{ row.avatar ? '' : getFirstUserLetter(row.name) }}
                  img(v-if="row.avatar" :src="row.avatar" :alt="getFirstUserLetter(row.name)")
                span {{ row.name }}
            .rating-list-table-td(:class="row.rank")
              .rating-list-table-td-wrap
                svg(:class="['icon', `icon-` + rank(row.rank).icon]")
                  use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + rank(row.rank).icon")
                span {{ rank(row.rank).title }}
            .rating-list-table-td
              .rating-list-table-td-wrap
                span {{ formatValue(row.value) }}

</template>

<script>
import onResize from "@/mixins/onResize";
import RatingListHead from "/src/components/rating/RatinListHead";
const STORE = "rating";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";
import { mapGetters } from "vuex";
import Utils from "/src/helper.js";

export default {
  name: "RatingList",
  mixins: [onResize],
  components: {
    RatingListHead,
  },
  props: {
    typeSeason: {
      type: String,
      required: true,
      validator: function (value) {
        // Значение должно соответствовать одной из этих строк
        return ["hunter", "earner"].indexOf(value) !== -1;
      },
    },
    season: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      localLoading: true,
      isMobile: false,
    };
  },
  computed: {
    ...mapGetters(STORE, ["array"]),
    ...mapGetters("rank", ["rank"]),
  },
  methods: {
    formatValue(v) {
      const output = Utils.balanceFormat(v);
      return this.typeSeason === "hunter" ? output : output + " ₽";
    },
    getFirstUserLetter(str) {
      return str ? str.slice(0, 1).toUpperCase() : "";
    },
    fetch() {
      this.localLoading = true;
      const { season, typeSeason } = this;
      this.$store
        .dispatch(`${STORE}/fetch`, { season, typeSeason })
        .catch((err) => {
          if (isLogLevel(LOG_LVL_LIST.ERROR)) {
            console.log(`[ ${LOG_LVL_LIST.ERROR} ]	`, STORE, "fetch", err);
          }
        })
        .finally(() => {
          this.localLoading = false;
          this.$store.commit("loaderOff");
        });
    },
  },
  watch: {
    season() {
      this.fetch();
    },
    typeSeason() {
      this.fetch();
    },
  },
  created() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
    this.fetch();
  },
  mounted() {},
  // beforeUnmount() {
  //   this.$store.commit("loaderOff");
  // },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="scss"></style>
