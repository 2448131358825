import Vue from "vue";
// import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export function FETCH_ALL(state, data) {
  if (data?.title) {
    state = Object.assign(state, data);
  } else if (data.html) {
    Vue.set(state, "html", data.html);
  }
}
