import axios from "axios";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export function fetch({ commit }) {
  const url = "/api/social/";
  const method = "GET";
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, method, url);
  }
  return new Promise((resolve, reject) => {
    axios({ method, url })
      .then((resp) => {
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(
            `[ ${LOG_LVL_LIST.DEBUG} ]	`,
            url,
            method,
            "SUCCESS: ",
            resp
          );
        }
        const data = resp.data;
        commit("FETCH_ALL", data);
        resolve();
      })
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(
            `[ ${LOG_LVL_LIST.ERROR} ]	`,
            url,
            err,
            method,
            "RESPONSE",
            err.response
          );
        }
      });
  });
}
