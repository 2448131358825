import Vue from "vue";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export function FETCH_ALL(state, data) {
  console.log("FETCH_ALL", data);
  Vue.set(state, "data", data);
}

export function ADD(state, data) {
  if (!Array.isArray(state.data?.deals)) {
    state.data.deals = [];
  }
  const output = { ...state.data };
  if (Array.isArray(data?.deals)) {
    output.deals = state.data.deals.concat(data.deals);
  }
  FETCH_ALL(state, output);
}
