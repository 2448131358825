<template lang="pug">
    .profile-edit
        .profile-edit-wrapper 
          .profile-edit-head
            .profile-edit-title
                .profile-edit-title-icon
                    svg.icon.icon-profile
                      use(xlink:href="@/assets/images/sprite/sprite-inline.svg#profile")
                span Личность
            <profile-back-btn/>
          .profile-progressbar-wrapper
            .profile-progressbar
                .progressbar-current._progress-full
                .progressbar-body
                    .progressbar-step-item._finished
                        .progressbar-circle 1
                        span Личная информация
                    .progressbar-step-item._finished
                        .progressbar-circle 2
                        span Детали документа
                    .progressbar-step-item._finished
                        .progressbar-circle 3
                        span Загрузка документа

          .profile-edit-succes
            img(src="@/assets/images/sprite/appsuccess.svg")
            p Успешная отправка заявки на верификацию
                    

        //- .profile-edit-btn-wrapper
        //-     button.button.profile-edit-save-btn
        //-       svg.icon.icon-check
        //-         use(xlink:href="@/assets/images/sprite/sprite-inline.svg#check")
        //-       | Сохранить
            


</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  computed: {},
  created() {
    this.$store.commit("loaderOff");
  },
};
</script>

<style lang="scss"></style>
