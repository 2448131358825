<template lang="pug">
  .wallet
    <router-view/>
</template>

<script>
import changeTitle from "@/mixins/changeTitle";
import emitCloseMenu from "@/mixins/emitCloseMenu";
const STORE = "wallet";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export default {
  components: {},
  mixins: [changeTitle, emitCloseMenu],
  data() {
    return {
      pageName: "Кошелек",
    };
  },
  methods: {},
  created() {
    this.emitCloseMenu();
  },
  mounted() {
    this.$store
      .dispatch(`${STORE}/fetch`)
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(`[ ${LOG_LVL_LIST.ERROR} ]	`, STORE, "fetch", err);
        }
      })
      .finally(() => {
        this.$store.commit("loaderOff");
      });
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.changeTitle(vm.pageName);
    });
  },
};
</script>

<style></style>
