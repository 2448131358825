import axios from "axios";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";
const APIURL = "/api/agreement/";

export function fetch({ commit }, id) {
  const method = "GET";
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, method, APIURL);
  }
  return axios({ method, url: APIURL })
    .then((resp) => {
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(
          `[ ${LOG_LVL_LIST.DEBUG} ]	`,
          APIURL,
          method,
          "SUCCESS",
          resp
        );
      }
      commit("FETCH_ALL", resp.data);
    })
    .catch((err) => {
      if (isLogLevel(LOG_LVL_LIST.ERROR)) {
        console.log(
          `[ ${LOG_LVL_LIST.ERROR} ]	`,
          APIURL,
          err,
          method,
          "RESPONSE",
          err.response
        );
      }
    });
}
