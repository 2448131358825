<template lang="pug">
  .notification-main
    .notification-main-head
      .notification-main-title
        p {{ notification.title }}
        span(v-if="notification.time")
          timeago(:datetime="new Date(notification.time)")
      .notification-main-back.button(@click="goParent")
        svg(class="icon icon-arrow-left")
          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#arrow-left")
        | Все уведомления
    .notification-main-body.text(v-if="notification.msg")
      p {{ notification.msg }}
    button.notification-main-delete(@click="remove")
      svg.icon.icon-trash
        use(xlink:href="@/assets/images/sprite/sprite-inline.svg#trash")
      | Удалить


</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NotificationOne",
  data() {
    return {
      index: -1,
      notification: {},
    };
  },
  computed: {
    ...mapGetters("notifications", ["notifyArray"]),
  },
  watch: {
    notifyArray() {
      this.getNotification();
    },
    "$route.params.id": {
      handler: function () {
        this.getNotification();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    goParent() {
      this.$router.push("/notifications");
    },
    getNotification() {
      this.index = this.$route?.params?.id;
      if (
        this.notifyArray.hasOwnProperty(this.index) &&
        this.notifyArray[this.index].hasOwnProperty("title")
      ) {
        this.notification = Object.assign(this.notifyArray[this.index]);
      }
    },
    remove() {
      this.$store
        .dispatch("notifications/remove", this.index)
        .then(() => {
          this.goParent();
        })
        .catch(() => {});
    },
  },
  mounted() {
    const index = this.$route?.params?.id;
    this.$store.dispatch("notifications/read", index).catch(() => {});
    this.getNotification();
    this.$emit("closeMenu");
    this.$emit("changeTitle", "Уведомления");
    this.$store.commit("loaderOff");
  },
};
</script>
