<template lang="pug">
    .application-new
        .application-new-wrapper 
            .application-new-head
                .application-new-title
                    .application-new-title-icon
                        svg.icon.icon-investment
                            use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + prodIcon  ")
                    span {{ prodTitle }}
                <back-btn :path="'/programs'">Продукты</back-btn>
            .application-new-form-block
                form.application-new-form
                    .form-fullname
                        .form-field(:class="{focused: nameFieldFocused, filled: nameFieldFilled}")
                            label.form-label() Имя
                            input.form-input(
                                type="text"
                                maxlength='16'
                                name="name"
                                v-model="nameField"
                                @focus="nameFieldFocused = true"
                                @blur="onBlurInputHandler"
                              )
                        .form-field(:class="{focused: surnameFieldFocused, filled: surnameFieldFilled}")
                            label.form-label() Фамилия
                            input.form-input(
                                type="text"
                                maxlength='16'
                                name="surname"
                                v-model="surnameField"
                                @focus="surnameFieldFocused = true"
                                @blur="onBlurInputHandler"
                              )
                        .form-field(:class="{focused: middlenameFieldFocused, filled: middlenameFieldFilled}")
                            label.form-label() Отчество
                            input.form-input(
                                type="text"
                                maxlength='16'
                                v-model="middlenameField"
                                name="middle_name"
                                @focus="middlenameFieldFocused = true"
                                @blur="onBlurInputHandler"
                              )
                    .form-row
                      .form-field(:class="{focused: phoneFieldFocused, filled: phoneFieldFilled}")
                        label.form-label Мобильный номер
                        input.form-input(
                          type="text"
                          name="phone"
                          v-model="phoneField"
                          @focus="phoneFieldFocused = true"
                          @blur="onBlurInputHandler"
                          @input="phoneInputHandler"
                          @paste="onPhoneFieldPaste"
                        )

                      .form-field(:class="{focused: emailFieldFocused, filled: emailFieldFilled}")
                        label.form-label Электронный адрес
                        input.form-input(
                          type="text"
                          name="email"
                          v-model="emailField"
                          @focus="emailFieldFocused = true"
                          @blur="onBlurInputHandler"
                          @input="emailInputHandler"
                        )

        .application-new-btn-wrapper
          button.button.button-grey(@click="$router.go(-1)") Отменить
          button.button(
            :class="{'button-loading': loadingResend}"
            @click="submit"
          )
            svg.icon.icon-check
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#check")
            | Оформить заявку
</template>

<script>
import validateEmailBool from "@/mixins/validateEmailBool";
import { mapGetters } from "vuex";
const STORE = "programs";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export default {
  data() {
    return {
      loadingResend: false,
      prodId: "",
      prodIcon: "list1",
      prodTitle: "",
      nameField: "Ростислав",
      surnameField: "Артемьевских",
      middlenameField: "Николаевич",
      phoneField: "+7 922-037-03-77",
      emailField: "artrostislav@yandex.ru",

      nameFieldFocused: false,
      surnameFieldFocused: false,
      middlenameFieldFocused: false,
      phoneFieldFocused: false,
      emailFieldFocused: false,

      nameFieldFilled: true,
      surnameFieldFilled: true,
      middlenameFieldFilled: true,
      phoneFieldFilled: true,
      emailFieldFilled: true,

      isNameFieldValidated: false,
      isSurnameFieldValidated: false,
      isMiddlenameFieldValidated: false,
      isPhoneFieldValidated: false,
      isEmailFieldValidated: false,
    };
  },
  methods: {
    submit() {
      this.loadingResend = true;
      this.$store
        .dispatch(`${STORE}/send`, {
          productId: this.prodId,
          name: this.nameField,
          fathername: this.middlenameField,
          surname: this.surnameField,
          email: this.emailField,
          phone: this.phoneField,
        })
        .then(() => {
          this.$router.push("/programs/application-completed");
        })
        .catch(() => {})
        .finally(() => {
          this.loadingResend = false;
        });
    },
    userSync() {
      this.nameField = this.user.name;
      this.surnameField = this.user.surname;
      this.middlenameField = this.user.fathername;
      this.phoneField = this.user.phone;
      this.emailField = this.user.email;
    },
    onBlurInputHandler() {
      this.nameFieldFocused = false;
      this.surnameFieldFocused = false;
      this.middlenameFieldFocused = false;
      this.phoneFieldFocused = false;
      this.emailFieldFocused = false;

      // name field
      if (this.nameField === "") {
        this.nameFieldFilled = false;
      } else {
        this.nameFieldFilled = true;
      }

      // surname field
      if (this.surnameField === "") {
        this.surnameFieldFilled = false;
      } else {
        this.surnameFieldFilled = true;
      }

      // email field
      if (this.middlenameField === "") {
        this.middlenameFieldFilled = false;
      } else {
        this.middlenameFieldFilled = true;
      }

      // phone field
      if (this.phoneField === "") {
        this.phoneFieldFilled = false;
      } else {
        this.phoneFieldFilled = true;
      }

      // email field
      if (this.emailField === "") {
        this.emailFieldFilled = false;
      } else {
        this.emailFieldFilled = true;
      }
    },
    phoneInputHandler() {
      this.phoneField = this.replaceNumberForInput(this.phoneField);
    },
    onPhoneFieldPaste(event) {
      let pastedValue = event.clipboardData.getData("text");
      setTimeout(() => {
        this.phoneField = this.replaceNumberForPaste(pastedValue);
      });
    },
    replaceNumberForPaste(value) {
      const r = value.replace(/\D/g, "");
      let val = r;
      if (val.charAt(0) !== "7") {
        if (val.length >= 11) {
          val = "7" + val.slice(1, 11);
        } else if (val.length < 11) {
          val = "7" + val;
        }
      }
      return this.replaceNumberForInput(val);
    },
    replaceNumberForInput(value) {
      let val = "";
      const x = value
        .replace(/\D/g, "")
        .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

      if (!x[1]) {
        x[1] = 7;
        val = "+" + x[1] + " ";
      } else if (!x[2] && x[1] !== "") {
        val = x[1] === "7" ? "+" + x[1] + " " : "+7 " + x[1];
      } else {
        val = !x[3]
          ? "+" + x[1] + " " + x[2]
          : "+" +
            x[1] +
            " " +
            x[2] +
            "-" +
            x[3] +
            (x[4] ? "-" + x[4] : "") +
            (x[5] ? "-" + x[5] : "");
      }
      return val;
    },
    emailInputHandler() {
      // this.invalidEmail = false;
      this.isEmailFieldValidated = false;
    },
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(STORE, ["productItem"]),
    phoneValue() {
      return parseInt(this.phoneField.replace(/\D/g, ""));
    },
  },
  watch: {
    user() {
      this.userSync();
    },
  },
  mounted() {
    this.userSync();
    this.$store
      .dispatch(`${STORE}/fetch`, "products")
      .then(() => {
        const index = this.$route?.params?.id;
        const prodItem = this.productItem(index);
        if (prodItem?.icon) {
          this.prodIcon = prodItem?.icon;
        }
        if (prodItem?.title) {
          this.prodTitle = prodItem?.title;
        }
        if (prodItem?.id) {
          this.prodId = prodItem?.id;
        }
      })
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(`[ ${LOG_LVL_LIST.ERROR} ]	`, STORE, "fetch", err);
        }
      })
      .finally(() => {
        this.$store.commit("loaderOff");
      });
  },
};
</script>

<style lang="scss"></style>
