<template lang="pug">

  .news
    .news-one(v-for="(item, index) in newsArray" :key="index")
      a(:href="item.link || '#'"  v-if="item.img" :target='item.link ? "_blank" : "_self"').news-one-pic
        img(:src="item.img")
      .news-one-info
        a(:href="item.link || '#'" :target='item.link ? "_blank" : "_self"').news-one-title {{ item.title }}
        .news-one-desc(v-html="item.desc")
        .news-one-actions
          span
            svg.icon.icon-eye
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#eye")
            | {{ item.show }}
          span(v-if="item.time")
            svg.icon.icon-clock
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#clock")
            timeago(:datetime="new Date(item.time)")
      a(:href="item.file" target='_blank').news-one-btn(v-if="item.file")
        svg.icon.icon-presentation
          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#presentation")
        | Посмотреть презентацию
    button.news-more(@click="getMore" v-if="showMore")
      svg.icon.icon-plus
        use(xlink:href="@/assets/images/sprite/sprite-inline.svg#plus")
      | Показать ещё

</template>

<script>
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";
import { mapGetters } from "vuex";
const STORE = "news";

export default {
  name: "News",
  data() {
    return {
      page: 1,
      news: [],
      pageName: "Лента",
    };
  },
  computed: {
    ...mapGetters(STORE, ["newsArray", "showMore"]),
  },
  methods: {
    getMore() {
      this.page += 1;
      this.$store.dispatch(`${STORE}/getMore`, this.page);
    },
    changeTitle(pagename) {
      this.$emit("changeTitle", pagename);
    },
    emitCloseMenu() {
      this.$emit("closeMenu");
    },
  },
  created() {
    this.emitCloseMenu();
    this.$store
      .dispatch(`${STORE}/getMore`, this.page)
      .then(() => this.$nextTick(() => this.$store.dispatch(`${STORE}/read`)))
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(`[ ${LOG_LVL_LIST.ERROR} ]	`, STORE, "fetch", err);
        }
      })
      .finally(() => {
        this.$store.commit("loaderOff");
      });
  },
  mounted() {},
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.changeTitle(vm.pageName);
    });
  },
};
</script>
