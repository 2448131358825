<template>
  <transition name="input-error">
    <div class="error-message" v-if="showVar">
      <svg class="icon icon-warning">
        <use
          xlink:href="@/assets/images/sprite/sprite-inline.svg#warning"
        ></use>
      </svg>
      <div class="error-message-text">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    showVar: {
      type: Boolean,
      required: true,
    },
  },
  name: 'input-error',
};
</script>

<style></style>
