<template lang="pug">
  .programs-tabs
      .programs-tabs-menu.tabs-menu
          router-link.programs-tabs-menu-item.tabs-menu-item(
            v-for='(tab, index) in tabs'
            :key="index"
            :to="`/programs/` + tab.path"
          )
            svg(class="icon" :class="`icon-` + tab.icon")
              use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + tab.icon")
            span {{ tab.title }}
      .programs-tabs-content.tabs-content
        <router-view/>

</template>

<script>
export default {
  data() {
    return {
      tabs: [
        {
          icon: "cube",
          title: "Продукты",
          path: "",
        },
        {
          icon: "list1",
          title: "Заявки",
          path: "applications",
        },
      ],
    };
  },
  methods: {},
};
</script>
