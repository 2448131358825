<template lang="pug">
  .achievements
    .achievements-head
      .achievements-head-icon.achievements-head-icon--newbie
        svg.icon.icon-chevron
          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#chevron")
      .achievements-head-title {{ user.rank }}
      .achievements-head-score
        svg.icon.icon-achievement
          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#achievement")
        span {{ status }}
    .achievements-wrap
      .achievements-block(
        v-for="(item, index) in array"
        :key="index"
        :class="[{done: item.isdone}]"
      )
        .achievements-block-pic
          img(:src="item.img || defaultImg")
        .achievements-block-title {{ item.title }}
        .achievements-block-desc {{ item.desc }}
        .achievements-block-action(v-if="item.isdone")
          svg.icon.icon-check
            use(xlink:href="@/assets/images/sprite/sprite-inline.svg#check")
        .achievements-block-action(v-else-if="!item.isstart")
          button(@click="start(index)").button Приступить к выполнению


</template>

<script>
import { mapGetters } from "vuex";
const STORE = "achievements";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export default {
  name: "Achievements",
  data() {
    return {
      pageName: "Достижения",
      defaultImg: require("@/assets/images/achievements-empty.svg"),
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(STORE, ["array", "doneCount"]),
    status() {
      return `${this.doneCount}/${this.array.length}`;
    },
  },
  methods: {
    start(index) {
      this.$store.dispatch(`${STORE}/start`, index).catch(() => {});
    },
    changeTitle(pagename) {
      this.$emit("changeTitle", pagename);
    },
    emitCloseMenu() {
      this.$emit("closeMenu");
    },
  },
  created() {
    this.emitCloseMenu();
    this.$store
      .dispatch(`${STORE}/fetch`)
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(`[ ${LOG_LVL_LIST.ERROR} ]	`, STORE, "fetch", err);
        }
      })
      .finally(() => {
        this.$store.commit("loaderOff");
      });
  },
  mounted() {},
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.changeTitle(vm.pageName);
    });
  },
};
</script>
