// import state from "./state";
import * as getters from "./getters";
import * as mutations from "./mutations";
import * as actions from "./actions";

const state = function () {
  return {
    map: {
      // name: "Ростислав Вa.",
      // rank: "expert",
      // avatar: require("@/assets/images/profile-photo.png"),
      // children: [
      //   {
      //     name: "Анатолий Н.",
      //     rank: "beginner",
      //     iconColor: "#eaa745",
      //     avatar: "",
      //     children: [
      //       {
      //         name: "Диана Ц.",
      //         rank: "expert",
      //         iconColor: "#ff8845",
      //         avatar: require("@/assets/images/user3.png"),
      //         children: [
      //           {
      //             name: "Кристина Е.",
      //             rank: "specialist",
      //             iconColor: "#69bb55",
      //             avatar: "",
      //           },
      //           {
      //             name: "Ирина Л.",
      //             rank: "beginner",
      //             iconColor: "#eaa745",
      //             avatar: require("@/assets/images/user5.png"),
      //           },
      //         ],
      //       },
      //       {
      //         name: "Дмитрий Щ.",
      //         rank: "newcomer",
      //         iconColor: "#a6c242",
      //         avatar: "",
      //       },
      //       {
      //         name: "Ольга Д.",
      //         rank: "beginner",
      //         avatar: require("@/assets/images/user4.png"),
      //       },
      //     ],
      //   },
      //   {
      //     name: "Алексей В.",
      //     rank: "newcomer",
      //     iconColor: "#f66746",
      //     avatar: "",
      //     children: [
      //       {
      //         name: "Виктор Щ.",
      //         rank: "beginner",
      //         iconColor: "#ff8845",
      //         avatar: "",
      //       },
      //       {
      //         name: "Леонид К.",
      //         rank: "newcomer",
      //         avatar: require("@/assets/images/user1.png"),
      //         children: [
      //           {
      //             name: "Анна У.",
      //             rank: "beginner",
      //             avatar: require("@/assets/images/user2.png"),
      //           },
      //           {
      //             name: "Владимир С.",
      //             rank: "newcomer",
      //             iconColor: "#eaa745",
      //             avatar: "",
      //           },
      //           {
      //             name: "Константин Ц.",
      //             rank: "beginner",
      //             iconColor: "#ed4646",
      //             avatar: "",
      //           },
      //         ],
      //       },
      //     ],
      //   },
      // ],
    },
  };
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
