<template lang="pug">
  .settings-block-inner
    .settings-block
      .settings-block-header
        .settings-block-head
          .settings-block-head-icon
            svg(class="icon icon-lock")
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#lock")
          p Пароль
        <settings-back-btn/>
      form.form.settings-form(@submit.prevent)
        .settings-form-inner
          .form-fields-wrapper
            .form-field.form-field-col-2.focused(
              :class="{invalid: !valid}"
            )
              label.form-label Пароль
              input.form-input(
              v-model="value"
                type="password"
                name="password"
                @focus="onFocusInputHandler"
                @blur="onBlurInputHandler"
                @input="validation"
              )
              .form-btn-eye(
                @click='changeInputType'
              )
                svg.icon.icon-eye-close(v-if="isPwdHide")
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#eye-close")
                svg.icon.icon-eye-open(v-else)
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#eye-open")
              .pwd-level(
                v-if="showlevel"
                :class="{'pwd-level--weak': lowPwd, 'pwd-level--middle': middlePwd, 'pwd-level--strong': highPwd}"
                )
                .pwd-level-line
                .pwd-level-text(
                  v-if="lowLength"
                  ) Длина пароля должна быть от 8 до 36 символов
                .pwd-level-text(
                  v-else-if="lowPwd"
                  ) Слабый пароль
                .pwd-level-text(
                  v-else-if="middlePwd"
                  ) Средний пароль
                .pwd-level-text(
                  v-else-if="highPwd"
                  ) Сильный пароль
            .form-field.form-field-col-2
              label.form-label Повторите пароль
              input.form-input(
              v-model="valueRepeat"
                type="password"
                name="password_confirmation"
                @focus="onFocusInputHandler"
                @blur="onBlurInputHandler"
                @input="validationRepeat"
              )
        button.settings-form-btn(
          :class="{'button-disabled': !validRepeat || !valid}"
          @click="save"
        )
          svg.icon.icon-check
            use(xlink:href="@/assets/images/sprite/sprite-inline.svg#check")
          | Сохранить



</template>

<script>
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

import changeInputType from "@/mixins/changeInputType";
import onFocusInputHandler from "@/mixins/onFocusInputHandler";
import onBlurInputHandler from "@/mixins/onBlurInputHandler";

export default {
  mixins: [changeInputType, onFocusInputHandler, onBlurInputHandler],
  data() {
    return {
      value: "",
      valueRepeat: "",
      valid: false,
      lowLength: true,
      showlevel: true,
      validRepeat: false,
      lowPwdRegExp: /(?=.*[a-z])(?=.*[A-Z])[a-zA-Z]{8,36}/g,
      middlePwdRegExp: /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,36}/g,
      highPwdRegExp:
        /(?=.*[0-9])(?=.*[\W])(?=.*[a-z])(?=.*[A-Z])[0-9\Wa-zA-Z]{8,36}/g,
      lowPwd: false,
      middlePwd: false,
      highPwd: false,
    };
  },
  methods: {
    save() {
      this.$store
        .dispatch("newPassword", this.value)
        .then(() => this.$router.push(`/profile`))
        .catch((resp) => {
          this.value = "";
          this.valueRepeat = "";
          this.valid = false;
        });
    },
    validation() {
      const value = this.value;
      if (value.length >= 8 && value.length <= 36) {
        if (value.match(this.highPwdRegExp)) {
          this.highPwd = true;
        } else if (
          value.match(this.middlePwdRegExp) ||
          value.match(/(?=.*[\W])(?=.*[a-z])(?=.*[A-Z])[\Wa-zA-Z]{8,36}/g)
        ) {
          this.middlePwd = true;
        } else if (
          value.match(this.lowPwdRegExp) ||
          value.match(/(?=.*[a-z])[a-z]{8,36}/g) ||
          value.match(/(?=.*[A-Z])[A-Z]{8,36}/g) ||
          value.match(/(?=.*[0-9])[0-9]{8,36}/g)
        ) {
          this.lowPwd = true;
        } else {
          this.middlePwd = true;
        }

        this.lowLength = false;
      } else {
        this.lowLength = true;
        this.lowPwd = false;
        this.middlePwd = false;
        this.highPwd = false;
      }
      if (!this.lowLength && !this.lowPwd) {
        this.valid = true;
      } else {
        this.valid = false;
      }

      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(
          `[ ${LOG_LVL_LIST.DEBUG} ]	`,
          "validate",
          this.value,
          this.valueRepeat,
          this.lowPwd ? "Слабый пароль" : "",
          this.valid
        );
      }
    },
    validationRepeat() {
      if (this.value === this.valueRepeat) {
        this.validRepeat = true;
      } else {
        this.validRepeat = false;
      }
    },
  },
  created() {
    this.$store.commit("loaderOff");
  },
};
</script>
