<template lang="pug">
  .login
    <vue-scroll>
      .login-inner
        .login-block
          router-link.logo(to="/")
              img(src="@/assets/images/logo.svg" alt="logo")
          .login-main
              h1.title-h1 Вход 

              <router-view/>

              //- registration question and link
              p.login-register-question Вы ещё не зарегистрированы? 
              router-link.login-register-link.link(to="/registration") Зарегистрироваться
                svg.icon.icon-arrow-right
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#arrow-right")

        .login-poster
            img(src="@/assets/images/sign-in.jpg")
            .login-poster-inner
                p.title-h1 Наколдуй свою структуру
                router-link(to="/registration").button Присоединиться
      
    </vue-scroll>
    
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.login {
  height: 100vh;
  // &-inner {
  //   display: flex;
  // }
}
</style>
