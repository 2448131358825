<template lang="pug">
  form.login-form.form(@submit.prevent)
    //- login field
    .form-field(:class="{validated: isLoginFieldValidated, focused: ( logFieldFocused || loginField), invalid: invalidLogin}")
      label.form-label() E-mail или телефон
      imask-input.form-input(
        v-model:typed = "loginField"
        type="text"
        :unmask="true"
        :mask="mask"
        :prepare="prepareMask"
        @focus="logFieldFocused = true"
        @blur="onBlurInputHandler"
        @input="validateLoginField"
      )
        
      //- login error message
      <input-error :showVar="invalidLogin">
        <p>Введен неверный E-mail или телефон</p>
      </input-error>


    //- get code button
    button.button(
      :class="{'button-disabled': !isLoginFieldValidated}"
      :disabled="!isLoginFieldValidated"
      @click="getCode"
    )
      svg.icon.icon-cellphone
        use(xlink:href="@/assets/images/sprite/sprite-inline.svg#cellphone")
      | Получить код

    
</template>

<script>
import validateEmailBool from "@/mixins/validateEmailBool";
import replaceNumberForInput from "@/mixins/replaceNumberForInput";
import { IMaskComponent } from "vue-imask";

export default {
  components: {
    "imask-input": IMaskComponent,
  },
  mixins: [validateEmailBool, replaceNumberForInput],
  data() {
    return {
      mask: [
        {
          mask: "+{7}(000)000-00-00",
        },
        {
          mask: /^\S*@?\S*$/,
        },
      ],
      logFieldFocused: false,
      isLoginFieldValidated: false,
      loginField: "",
      // loginValue: '',
      invalidLogin: false,
    };
  },
  methods: {
    prepareMask(appended, masked) {
      if (appended === "8" && masked.value === "") {
        return "";
      }
      return appended;
    },
    onBlurInputHandler() {
      if (this.loginField === "") {
        this.logFieldFocused = false;
      }
    },
    validateLoginField() {
      this.isLoginFieldValidated = false;
      let regCheckForPhone = /^\+?7?\s?\(?\d{3}.*/;
      // let regCheckForPhone = /^\d{3}/;
      const loginValue = this.loginField.replace(/\D/g, "").substr(0, 11);
      if (
        regCheckForPhone.test(this.loginField.slice(0, 7)) &&
        loginValue.length >= 3
      ) {
        // console.log('the login field must be the phone number');
        this.loginField = this.replaceNumberForInput(this.loginField);
        if (loginValue.length === 11) {
          this.isLoginFieldValidated = true;
          console.log("changeEnteredLogin", loginValue);
          this.$store.commit("changeEnteredLogin", loginValue);
        } else {
          this.isLoginFieldValidated = false;
        }
      } else if (this.loginField.length >= 5) {
        // console.log('the login field must be the email');
        if (this.validateEmailBool(this.loginField)) {
          this.isLoginFieldValidated = true;
        } else {
          this.isLoginFieldValidated = false;
        }
      }
    },
    getCode() {
      if (this.isLoginFieldValidated) {
        this.$store.commit("changeEnteredLogin", this.loginField);
        this.$router.push("/login-phone2");
      }
    },
  },
  computed: {},
  mounted() {
    if (this.$store.state.enteredLogin) {
      this.logFieldFocused = true;
      this.loginField = this.$store.state.enteredLogin;
      this.validateLoginField();
    }
  },
};
</script>

<style></style>
