export function structure(state) {
  return state?.map?.name ? state.map : null;
}

export function structureList(state) {
  return state?.list?.name ? state.list : null;
}

export function levelArray(state) {
  const levelArray = [];
  if (state?.map?.hasOwnProperty("name")) {
    const map = state.map;
    levelArray.push([]);
    levelArray[0].push({
      ...map,
      parent: {},
    });
    treeParse(levelArray, map, 1);
    return levelArray;
  } else {
    return [];
  }
}

function treeParse(levelArray, parent, level = 0) {
  if (levelArray.length < level || !Array.isArray(levelArray[level])) {
    levelArray[level] = [];
  }
  if (Array.isArray(parent.children) && parent.children.length > 0) {
    parent.children.forEach((item) => {
      const child = { ...item };
      child.parent = {
        name: parent.name,
        iconColor: parent?.iconColor || "",
        avatar: parent?.avatar || "",
      };
      levelArray[level].push(child);
      if (Array.isArray(child.children) && child.children.length > 0) {
        treeParse(levelArray, child, level + 1);
      }
    });
  } else {
    levelArray[level].push(parent);
  }
}
