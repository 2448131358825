import axios from "axios";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";
const APIURL = "/api/achievements/";

export function fetch({ commit }) {
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "GET", APIURL);
  }
  return new Promise((resolve, reject) => {
    axios({ method: "GET", url: APIURL })
      .then((resp) => {
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, APIURL, "GET SUCCESS", resp);
        }
        const data = resp.data;
        commit("FETCH_ALL", data);
        resolve();
      })
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(
            `[ ${LOG_LVL_LIST.ERROR} ]	`,
            APIURL,
            err,
            "GET RESPONSE",
            err.response
          );
        }
        reject();
      });
  });
}

export function start({ commit, getters }, index = -1) {
  return new Promise((resolve, reject) => {
    if (index == -1 || !getters.array.hasOwnProperty(index)) {
      reject();
    }
    const data = { ...getters.array[index] };
    const method = "PUT";
    if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
      console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, method, APIURL, data);
    }
    axios({ method, url: APIURL, data })
      .then((resp) => {
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(
            `[ ${LOG_LVL_LIST.DEBUG} ]	`,
            APIURL,
            method,
            "SUCCESS",
            resp
          );
        }
        commit("start", index);
        resolve();
      })
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(
            `[ ${LOG_LVL_LIST.ERROR} ]	`,
            APIURL,
            method,
            err,
            "RESPONSE",
            err.response
          );
        }
        reject();
      });
  });
}
