export default {
  data() {
    return {
      validRegex:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    };
  },
  methods: {
    validateEmail(event) {
      let input = event.target;
      if (input.value.match(this.validRegex)) {
        input.parentElement.classList.add('validated');
      } else {
        input.parentElement.classList.remove('validated');
      }
    },
  },
};
