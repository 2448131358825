// export function video(state) {
//   return state?.video || null;
// }

export function levelList(state) {
  return state.data?.levelList || [];
}

export function statusList(state) {
  return state.data?.statusList || [];
}

export function productList(state) {
  return state.data?.productList || [];
}
export function dealsByLevels(state) {
  return [
    {
      level: "",
      deals: Array.isArray(state?.data?.deals) ? state?.data?.deals : [],
    },
  ];
}

export function dealsCount(state) {
  return state?.data?.deals?.length || 0;
}

export function showMore(state) {
  const dc = dealsCount(state);
  return dc > 0 && state.data.stuffAmount > dc;
}
