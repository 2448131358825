// import state from "./state";
import * as getters from "./getters";
import * as mutations from "./mutations";
import * as actions from "./actions";

const state = function () {
  return {
    address: "",
    personaldata: {
      name: "",
      surname: "",
      fathername: "",
      birthday: "",
      country: "",
    },
    passport: {
      serial: "",
      issued: "",
      date: "",
      department_code: "",
      files: {
        main: "",
        reg: "",
        resident: "",
      },
    },
  };
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
