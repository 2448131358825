<template lang="pug">
  .login
    <vue-scroll>
      .login-inner
        .login-block
          a.logo(href="/")
              img(src="@/assets/images/logo.svg" alt="logo")
          .login-main
              h1.title-h1 Регистрация 

              <router-view/>

              //- registration question and link
              p.login-register-question Вы уже зарегистрированы?
              router-link.login-register-link.link(to="/login") Войти
                svg.icon.icon-arrow-right
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#arrow-right")

        .login-poster
          img(src="@/assets/images/sign-in.jpg")
          .login-poster-inner
            p.title-h1 Наколдуй свою структуру
            .register-notice
              p В случае возникновения проблем с регистрацией — обращайтесь в службу поддержки по электронному адресу
              a(href="mailto:support@opnpartner.ru") support@opnpartner.ru
      
    </vue-scroll>
    
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.login {
  height: 100vh;
  // &-inner {
  //   display: flex;
  // }
}
</style>
