import axios from "axios";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";
const API_URL = "/api/wallet/";
let fetch_debonce = false;

const COMMIT = true;

export function fetch({ commit }) {
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "GET", API_URL);
  }
  return new Promise((resolve, reject) => {
    if (fetch_debonce) {
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(
          `[ ${LOG_LVL_LIST.DEBUG} ]	`,
          "GET",
          API_URL,
          "DEBOUNCE REJECT"
        );
      }
      const debonceInterval = setInterval(() => {
        if (!fetch_debonce) {
          if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
            console.log(
              `[ ${LOG_LVL_LIST.DEBUG} ]	`,
              "GET",
              API_URL,
              "DEBOUNCE UNBLOCK"
            );
          }
          clearInterval(debonceInterval);
          resolve();
        }
      }, 1000);
      return;
    }
    fetch_debonce = true;
    axios({ method: "GET", url: API_URL })
      .then((resp) => {
        const data = resp.data;
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(
            `[ ${LOG_LVL_LIST.DEBUG} ]	`,
            API_URL,
            "GET SUCCESS: ",
            data
          );
        }
        if (process.env.NODE_ENV === "production" || COMMIT) {
          commit("FETCH_ALL", data);
        }
        if (Array.isArray(data?.transactions) && data.transactions.length > 0) {
          commit("empty", false);
        }
        fetch_debonce = false;
        resolve();
      })
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(
            `[ ${LOG_LVL_LIST.ERROR} ]	`,
            API_URL,
            err,
            "GET RESPONSE: ",
            err.response
          );
        }
        fetch_debonce = false;
        reject();
      });
  });
}

export function filter({ commit }, filters) {
  const data = { action: "filter", data: filters };
  const method = "POST";
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, method, API_URL, data);
  }
  return new Promise((resolve, reject) => {
    axios({ method, url: API_URL, data })
      .then((resp) => {
        const output = resp.data;
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(
            `[ ${LOG_LVL_LIST.DEBUG} ]	`,
            API_URL,
            method,
            "SUCCESS",
            output
          );
        }
        if (process.env.NODE_ENV === "production" || COMMIT)
          commit("SET_ARRAY_BY_KEY", {
            add: filters?.more || false,
            key: filters?.key || "transactions",
            data: output,
          });
        resolve();
      })
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(
            `[ ${LOG_LVL_LIST.ERROR} ]	`,
            API_URL,
            err,
            method,
            "RESPONSE",
            err.response
          );
        }
        reject();
      });
  });
}

export function getByKey({ commit, state }, key) {
  if (!state.keys.includes(key)) {
    return;
  }
  const data = { action: "get", key };
  const method = "POST";
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, method, API_URL, data);
  }
  return new Promise((resolve, reject) => {
    axios({ method, url: API_URL, data })
      .then((resp) => {
        const output = resp.data;
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(
            `[ ${LOG_LVL_LIST.DEBUG} ]	`,
            API_URL,
            method,
            "SUCCESS",
            output
          );
        }
        if (process.env.NODE_ENV === "production" || COMMIT)
          commit("SET_ARRAY_BY_KEY", { key, data: output });
        resolve();
      })
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(
            `[ ${LOG_LVL_LIST.ERROR} ]	`,
            API_URL,
            err,
            method,
            "RESPONSE",
            err.response
          );
        }
        reject();
      });
  });
}

export function payout({ commit }, sum) {
  const method = "PUT";
  const data = { action: "payout", data: sum };
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, method, API_URL, data);
  }
  return axios({ method, url: API_URL, data })
    .then((resp) => {
      const output = resp.data;
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(
          `[ ${LOG_LVL_LIST.DEBUG} ]	`,
          API_URL,
          method,
          "SUCCESS",
          output
        );
      }
      if (process.env.NODE_ENV === "production" || COMMIT)
        commit("FETCH_ALL", output);
      return Promise.resolve();
    })
    .catch((err) => {
      if (isLogLevel(LOG_LVL_LIST.ERROR)) {
        console.log(
          `[ ${LOG_LVL_LIST.ERROR} ]	`,
          API_URL,
          err,
          "GET RESPONSE: ",
          err.response
        );
      }
      return Promise.reject(err?.response?.data?.statusText);
    });
}

export function editAccount({ commit }, form) {
  const method = "PUT";
  let action = "create";
  if (Number.isInteger(form?.id)) {
    action = "edit";
  }
  const data = { action, data: form };
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, method, API_URL, data);
  }
  return new Promise((resolve, reject) => {
    axios({ method, url: API_URL, data })
      .then((resp) => {
        const output = resp.data;
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(
            `[ ${LOG_LVL_LIST.DEBUG} ]	`,
            API_URL,
            method,
            "SUCCESS",
            output
          );
        }
        if (process.env.NODE_ENV === "production" || COMMIT)
          commit("SET_ARRAY_BY_KEY", { key: "accountList", data: output });
        resolve();
      })
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(
            `[ ${LOG_LVL_LIST.ERROR} ]	`,
            API_URL,
            err,
            "GET RESPONSE: ",
            err.response
          );
        }
        reject();
      });
  });
}
