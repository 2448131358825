<template lang="pug">
  .statistic
    .statistic-tabs
      .statistic-tabs-menu
        .statistic-tabs-menu-item( v-for='(tab, index) in tabs'
          :key="index"
          @click="sidebarButtonHandler(tab.component)"
          :class="[{selected: currentComponent===tab.component }]")
          svg(class="icon" :class="`icon-` + tab.icon")
            use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + tab.icon")
          span {{ tab.title }}

      .statistic-tabs-content
        component(
          :is="currentComponent"
        )

</template>

<script>
import Revenue from "../components/statistic/Revenue";
import Source from "../components/statistic/Source";
import Attendance from "../components/statistic/Attendance";
import changeTitle from "@/mixins/changeTitle";
import emitCloseMenu from "@/mixins/emitCloseMenu";

export default {
  name: "Statistics",
  components: {
    Revenue,
    Source,
    Attendance,
  },
  mixins: [changeTitle, emitCloseMenu],
  data() {
    return {
      tabs: [
        {
          icon: "revenue",
          title: "Доход",
          component: "Revenue",
        },
        {
          icon: "attendance",
          title: "Посещаемость",
          component: "Attendance",
        },
        {
          icon: "source",
          title: "Источники",
          component: "Source",
        },
      ],
      currentComponent: "Revenue",
      pageName: "Статистика",
    };
  },
  methods: {
    sidebarButtonHandler(sidebarComponentName) {
      this.currentComponent = sidebarComponentName;
    },
  },
  created() {
    this.emitCloseMenu();
  },
  mounted() {},
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.changeTitle(vm.pageName);
    });
  },
};
</script>
