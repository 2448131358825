<template lang="pug">
  .profile-account

    //- nickname with photo
    .profile-block.profile-block--nickname
      .profile-icon
        .profile-user-letter(v-if="!user.avatar") {{firstUserLetter}}
        .profile-photo-btn
          svg.icon.icon-camera
            use(xlink:href="@/assets/images/sprite/sprite-inline.svg#camera")
        img(:src='user.avatar' v-if="user.avatar")
      .profile-nickname-block
        span Никнейм
        .profile-nickname
          p.title-h2 {{ nickName }}
          svg(:class="['icon-' + rankIcon, 'icon' ]")
            use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + rankIcon")
      //- editing block
      .profile-block-edit
        .profile-block-edit-btn(@click="$router.push(`/person-editing`)")
          svg(class="icon icon-pen")
            use(xlink:href="@/assets/images/sprite/sprite-inline.svg#pen")
          | Редактировать

    //- personality
    .profile-block
      .profile-block-head(@click="openBlock")
        .profile-block-head-desc
          .profile-block-head-icon
            svg.icon.icon-profile
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#profile")
          span Личность
          .profile-warning(v-if="!verify")
            .profile-warning-text Необходимо верифицировать
          .profile-success(v-else)
            .profile-success-text Верифицировано
        svg(class="icon icon-arrow-down")
          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#arrow-down")

      .profile-block-body
        .profile-personality-data
          .data-field
            p.data-caption Имя
            p.data-content {{ user.name || nodata }}
          .data-field
            p.data-caption Фамилия 
            p.data-content {{ user.surname || nodata  }}
          .data-field
            p.data-caption Отчество
            p.data-content {{ user.fathername || nodata  }}
          .data-field
            p.data-caption Дата рождения
            p.data-content {{ localBirthday || nodata  }}
          .data-field
            p.data-caption Страна
            p.data-content {{ user.country  || nodata }}
          
        .profile-block-edit
          .profile-block-edit-btn(
            @click="$router.push(`/person-edit-step-1`)"
            )
            svg(class="icon icon-pen")
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#pen")
            | Редактировать
    
    //- address
    .profile-block
      .profile-block-head(@click="openBlock")
        .profile-block-head-desc
          .profile-block-head-icon
            svg.icon.icon-house
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#house")
          span Адрес
          .profile-warning(v-if="!verify")
            .profile-warning-text Необходимо верифицировать
        svg(class="icon icon-arrow-down")
          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#arrow-down")

      .profile-block-body
        .data-field
          p.data-caption Адрес проживания
          p.data-content {{ user.address || nodata }}
        .profile-block-edit
          .profile-block-edit-btn(
            @click="$router.push('/address-edit-step-1')"
            )
              svg(class="icon icon-pen")
                use(xlink:href="@/assets/images/sprite/sprite-inline.svg#pen")
              | Редактировать


</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  name: "Account",
  data() {
    return {
      nodata: "не заполнено",
    };
  },
  computed: {
    localBirthday() {
      return this.user.birthday
        ? new Date(this.user.birthday).toLocaleDateString()
        : "";
    },
    nickName() {
      return this.title;
    },
    firstUserLetter() {
      return this.nickName ? this.nickName.slice(0, 1).toUpperCase() : "";
    },
    ...mapGetters(["user", "verify", "title"]),
    ...mapGetters("rank", ["rank"]),
    rankIcon() {
      const rankItem = this.rank(this.user.rank);
      return rankItem ? rankItem.icon : this.rank("beginner").icon;
    },
  },
  methods: {
    openBlock(e) {
      let parent = e.currentTarget.parentNode;
      parent.classList.toggle("active");
    },
  },
  created() {
    this.$store.commit("loaderOff");
  },
  mounted() {
    this.$emit("closeMenu");
    // console.log('Account is mounted');
  },
};
</script>
