<template lang="pug">
  .support-chat
    .support-chat-header
      .support-chat-header-title
        p {{title}}
        span {{id}}
      .support-back.button(
        @click="$router.push('/support/questions')"
      )
        svg(class="icon icon-arrow-left")
          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#arrow-left")
        | Мои вопросы
    form.support-chat-body(@submit.prevent)

      .support-chat-dialog(v-for="(chatItem, index) of chat" :key="index" :class="chatItem.me ? 'support-chat-dialog--me' : 'support-chat-dialog--user'")
        .support-chat-dialog-head
          .support-chat-dialog-pic
            img(v-if="getAvatar(chatItem)" :src="getAvatar(chatItem)")
            .profile-icon.kw-fix(v-else)
              .profile-user-letter.kw-fix {{ firstUserLetter(getUsername(chatItem)) }}
          .support-chat-dialog-name {{ getUsername(chatItem) }}
          .support-chat-dialog-date {{ msgDate(chatItem.date) }}
        .support-chat-dialog-text
          p {{ chatItem.msg }}

      .form-field(
        :class="{focused: ( msgFocus || msg)}"
      )
        label.form-label Ответить
        textarea.form-input(
          v-model="msg"
          rows="5"
          name="message"
          @focus="msgFocus = true"
          @blur="onBlurInputHandler"
          @input="checkForm"
        )
      button.support-form-btn(
        :class="{'button-disabled': (loadingResend || !inputValid), 'button-loading': loadingResend}"
        :disabled="loadingResend || !inputValid"
        @click="submit"
      )
        inline-svg(
          v-if="loadingResend"
          class="loader"
          :src="require('@/assets/images/sprite/loader.svg')"
        )
        span.kw-btn(v-else)
          svg.icon.icon-send
            use(xlink:href="@/assets/images/sprite/sprite-inline.svg#send")
          | Отправить


</template>

<script>
import Utils from "/src/helper.js";
import { mapGetters } from "vuex";
const STORE = "chatlist";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export default {
  name: "SupportChat",
  data() {
    return {
      loadingResend: false,
      fetchDebonce: false,
      fetchDone: false,
      index: -1,
      inputValid: false,
      msg: "",
      msgFocus: false,
    };
  },
  computed: {
    ...mapGetters(["me", "wsIsConnected", "token"]),
    ...mapGetters("chat", ["chat"]),
    ...mapGetters(STORE, ["chatlist"]),
    ...mapGetters(STORE, { getChatlistItem: "chatlistItem" }),
    id() {
      return this.chatlistItem?.id || null;
    },
    title() {
      return this.chatlistItem?.title || null;
    },
    chatlistItem() {
      if (this.index > -1) {
        return this.getChatlistItem(this.index);
      }
      return null;
    },
  },
  watch: {
    "$route.params.id": {
      handler: function (v) {
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(
            `[ ${LOG_LVL_LIST.DEBUG} ]	`,
            "watch",
            "route.params.id",
            v,
            "dependencies",
            this.fetchDebonce
          );
        }
        this.index = this.$route?.params?.id;
        this.fetch();
      },
      deep: true,
      immediate: true,
    },
    wsIsConnected(v) {
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(
          `[ ${LOG_LVL_LIST.DEBUG} ]	`,
          "watch",
          "wsIsConnected",
          v,
          "dependencies",
          this.fetchDone
        );
      }
      if (this.fetchDone) this.subscribe();
    },
    msgFocus(v) {
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(
          `[ ${LOG_LVL_LIST.DEBUG} ]	`,
          "watch",
          "msgFocus",
          v,
          "isread",
          this.id,
          this.chatlistItem?.isread
        );
      }
      if (!this.chatlistItem?.isread) {
        this.read();
      }
    },
  },
  methods: {
    firstUserLetter: Utils.firstUserLetter,
    getAvatar(item) {
      return item.me ? this.me.avatar : item.avatar;
    },
    getUsername(item) {
      return item.me ? this.me.username : item.username;
    },
    msgDate(date) {
      const dateOptions = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      return new Date(date)?.toLocaleDateString("ru-RU", dateOptions) || "";
    },
    onBlurInputHandler(event) {
      this.msgFocus = false;
      let inputValue = event.target.value;
      if (inputValue === "") {
        event.target.parentElement.classList.remove("focused");
      }
    },
    checkForm() {
      this.inputValid =
        String(this.msg).replace(/\s|[^A-Za-zА-Яа-я0-9_]/gi, "").length > 1;
      console.log(this.inputValid);
    },
    clearForm() {
      this.inputValid = false;
      this.msg = "";
    },
    submit() {
      const { msg, id } = this;
      this.checkForm();
      if (this.inputValid) {
        this.loadingResend = true;
        this.$store
          .dispatch(`chat/send`, { msg, id })
          .then(this.clearForm)
          .catch((err) => {
            if (isLogLevel(LOG_LVL_LIST.ERROR)) {
              console.log(`[ ${LOG_LVL_LIST.ERROR} ]	`, STORE, "fetch", err);
            }
          })
          .finally(() => {
            this.loadingResend = !true;
          });
      }
    },
    fetch() {
      if (this.fetchDebonce) {
        return;
      }
      this.fetchDebonce = true;
      this.fetchDone = false;
      this.loadingResend = true;
      this.$store
        .dispatch(`${STORE}/fetch`)
        .then(() => {
          return this.$store.dispatch(`chat/fetch`, this.id);
        })
        .catch((err) => {
          if (isLogLevel(LOG_LVL_LIST.ERROR)) {
            console.log(`[ ${LOG_LVL_LIST.ERROR} ]	`, STORE, "fetch", err);
          }
        })
        .finally(() => {
          this.loadingResend = !true;
          this.fetchDebonce = false;
          this.fetchDone = true;
          this.$store.commit("loaderOff");
          this.subscribe();
        });
    },
    subscribe(subscribe = true) {
      if (!this.wsIsConnected) {
        return;
      }
      this.$socket.sendObj({
        type: subscribe ? "subscribe_events" : "unsubscribe_events",
        data_type: "chat-item",
        namespace: "chat",
        mutation: "SOCKET_ADD",
        token: this.token,
        payload: {
          id: this.id,
        },
      });
    },
    read() {
      this.$store.dispatch(`${STORE}/open`, this.index).catch(() => {});
    },
  },
  mounted() {
    this.index = this.$route?.params?.id;
    this.read();
    this.fetch();
  },
  beforeUnmount() {
    this.subscribe(false);
  },
};
</script>
<style lang="scss">
.profile-icon {
  &.kw-fix {
    max-height: 100%;
    max-width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.profile-user-letter {
  &.kw-fix {
    margin: 0;
    font-size: 1rem;
    line-height: 1;
    display: flex;
    justify-content: center;
  }
}
.kw-btn {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
</style>
