// import state from "./state";
import * as getters from "./getters";
import * as mutations from "./mutations";
import * as actions from "./actions";

const state = function () {
  return {
    keys: ["levelList", "income", "attendance", "sources"],
    levelList: [
      // {
      //   id: 1,
      //   level: 1,
      // },
      // {
      //   id: 2,
      //   level: 2,
      // },
      // {
      //   id: 3,
      //   level: 3,
      // },
      // {
      //   id: 4,
      //   level: 4,
      // },
      // {
      //   id: 5,
      //   level: 5,
      // },
    ],
    income: [
      // {
      //   date: "2021-09-07",
      //   deals: 18,
      //   sum: 10000,
      //   percent: 1,
      // },
      // {
      //   date: "2021-09-06",
      //   deals: 18,
      //   sum: 20000,
      //   percent: 2,
      // },
      // {
      //   date: "2021-09-05",
      //   deals: 16,
      //   sum: 30000,
      //   percent: 0.1,
      // },
      // {
      //   date: "2021-09-04",
      //   deals: 15,
      //   sum: 50000,
      //   percent: 0.8,
      // },
      // {
      //   date: "2021-09-03",
      //   deals: 12,
      //   sum: 30000,
      //   percent: 1.2,
      // },
      // {
      //   date: "2021-09-02",
      //   deals: 11,
      //   sum: 70000,
      //   percent: 1,
      // },
      // {
      //   date: "2021-09-01",
      //   deals: 10,
      //   sum: 10000,
      //   percent: 1,
      // },
    ],
    attendance: [
      // {
      //   date: "2021-09-07",
      //   switch: 1093,
      //   reg: 58,
      //   deals: 18,
      //   sum: 10000,
      //   percent: 1,
      // },
      // {
      //   date: "2021-09-06",
      //   switch: 1093,
      //   reg: 58,
      //   deals: 18,
      //   sum: 20000,
      //   percent: 2,
      // },
      // {
      //   date: "2021-09-05",
      //   switch: 1093,
      //   reg: 58,
      //   deals: 16,
      //   sum: 30000,
      //   percent: 0.1,
      // },
      // {
      //   date: "2021-09-04",
      //   switch: 1093,
      //   reg: 58,
      //   deals: 15,
      //   sum: 50000,
      //   percent: 0.8,
      // },
      // {
      //   date: "2021-09-03",
      //   switch: 1093,
      //   reg: 58,
      //   deals: 12,
      //   sum: 30000,
      //   percent: 1.2,
      // },
      // {
      //   date: "2021-09-02",
      //   switch: 1093,
      //   reg: 58,
      //   deals: 11,
      //   sum: 70000,
      //   percent: 1,
      // },
      // {
      //   date: "2021-09-01",
      //   switch: 1093,
      //   reg: 58,
      //   deals: 10,
      //   sum: 10000,
      //   percent: 1,
      // },
    ],
    sources: [
      // {
      //   source: "Яндекс",
      //   color: "#D24681",
      //   switch: 1093,
      //   reg: 58,
      //   deals: 91,
      //   sum: 10001,
      //   percent: 56,
      // },
      // {
      //   source: "Google",
      //   color: "#ED4646",
      //   switch: 908,
      //   reg: 39,
      //   deals: 18,
      //   sum: 20000,
      //   percent: 2,
      // },
      // {
      //   source: "Instagram ",
      //   color: "#B644B1",
      //   switch: 1201,
      //   reg: 20,
      //   deals: 16,
      //   sum: 30000,
      //   percent: 0.1,
      // },
      // {
      //   source: "VK",
      //   color: "#FF8845",
      //   switch: 872,
      //   reg: 52,
      //   deals: 15,
      //   sum: 50000,
      //   percent: 0.8,
      // },
      // {
      //   source: "UTM",
      //   color: "#F66746",
      //   switch: 982,
      //   reg: 38,
      //   deals: 12,
      //   sum: 30000,
      //   percent: 1.2,
      // },
      // {
      //   source: "Не определён",
      //   color: "#ED4646",
      //   switch: 992,
      //   reg: 31,
      //   deals: 11,
      //   sum: 70000,
      //   percent: 1,
      // },
    ],
  };
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
