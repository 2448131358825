<template lang="pug">
  router-link(
    :to="'/notification/' + index"
  ).notification-one(:class="{'notification-one--unread': !isread}")
    .notification-one--inner
      .notification-icon
        svg.icon(:class="'icon-' + icon || 'bell'")
          use(:xlink:href="iconSprite + '#' + icon || 'bell'")
      .notification-content
        p.notification-text {{ title }}
        span.notification-time(v-if="time")
          timeago(:datetime="new Date(time)")
      button.notification-remove-btn(@click="remove")
        svg.icon.icon-trash
          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#trash")
</template>
<script>
const STORE = "notifications";
export default {
  name: "Notification",
  props: {
    index: {
      type: Number,
      required: true,
    },
    id: String,
    title: {
      type: String,
      required: true,
    },
    time: String,
    icon: {
      type: String,
      default: "bell",
    },
    isread: {
      type: Boolean,
      required: true,
    },
    msg: String,
  },
  data() {
    return {
      iconSprite: require("@/assets/images/sprite/sprite-inline.svg"),
    };
  },
  computed: {
    // ...mapGetters(["user"]),
  },
  created() {},
  methods: {
    remove() {
      this.$store
        .dispatch(`${STORE}/remove`, this.index)
        .then(() => {})
        .catch(() => {});
    },
    changeTitle(pagename) {
      this.$emit("changeTitle", pagename);
    },
    emitCloseMenu() {
      this.$emit("closeMenu");
    },
  },
  mounted() {},
};
</script>
