import Vue from "vue";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export function FETCH_ALL(state, obj) {
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "FETCH_ALL", obj);
  }
  if (!state.keys.includes(obj?.key)) {
    return;
  }
  const data = Array.isArray(obj?.data) ? obj.data : [];
  Vue.set(state, obj.key, data);
}

export function add(state, obj) {
  if (!state.keys.includes(obj?.key)) {
    return;
  }
  if (!Array.isArray(state[obj.key])) {
    state[obj.key] = [];
  }
  if (!obj?.data) {
    return;
  }
  if (!state.status.includes(obj.data?.status)) {
    obj.data.status = "inprogres";
  }
  state[obj.key].push(obj.data);
}
