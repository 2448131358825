<template lang="pug">
  
  form.registration-form.form(@submit.prevent)

    //- code field
    .form-code-field(
      :class="{invalid: invalidCode}"
      )
      span.form-code-message Код приглашения
      .form-code-inputs(
        :class="{invalid: invalidCode}"
      )
        input.form-code-input(
          v-for="n in 5"
          :key="n"
          :ref="'input_' + n"
          :name="'digit' + n"
          :maxlength="maxlength"
          type="text"
          placeholder=" "
          autocomplete="off"
          @input="codeInputHandler($event, n, 5)"
          @keydown="codeInputNext($event, n, 5)"
        )
        //- code error message
        <input-error :showVar="invalidCode">
          <p>Неверный код приглашения</p>
        </input-error>
        
    //- register button
    button.button.button-register(
      :class="{'button-disabled': !isCodeValidated, 'button-loading': loading}"
      :disabled="!isCodeValidated || loading"
      @click="checkServerData"
      )
      <inline-svg v-if="loading" class="loader" :src="require('@/assets/images/sprite/loader.svg')"></inline-svg>
      span.button-content(v-else)
        svg.icon.icon-add
          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#add")
        | Начать регистрацию
    
</template>

<script>
import codeInputHandler from "@/mixins/iviteCode";
import codeInputNext from "@/mixins/iviteCode";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export default {
  mixins: [codeInputHandler, codeInputNext],
  data() {
    return {
      invalidCode: false,
      loading: false,
    };
  },
  methods: {
    checkServerData() {
      if (this.isCodeValidated && !this.loading) {
        this.loading = true;
        this.$store
          .dispatch("invite", this.enteredCode)
          .then(() => {
            this.loading = false;
            this.$router.push("/registration-form");
          })
          .catch((err) => {
            if (isLogLevel(LOG_LVL_LIST.ERROR)) {
              console.log(`[ ${LOG_LVL_LIST.ERROR} ]	`, err);
            }
            this.loading = false;
            this.invalidCode = true;
          });
      }
    },
  },
  computed: {
    enteredCode() {
      return this.codeInputs.join("");
    },
    isCodeValidated() {
      return this.codeInputs.join("").length === 5 ? true : false;
    },
  },
  mounted() {
    const code = this.$route?.params?.id;
    if (code) {
      code.split("").forEach((item, index) => {
        this.$refs[`input_${index + 1}`][0].value = item;
        this.$set(this.codeInputs, index, item);
      });
    }
  },
};
</script>

<style lang="scss"></style>
