// import state from "./state";
import * as getters from "./getters";
import * as mutations from "./mutations";
import * as actions from "./actions";

const state = function () {
  return {
    html: "",
    title: "",
    subtitle: "",
    list: [],
  };
};
// return {
//   title: "Пользовательское соглашение",
//   subtitle: "Редакция №1 от 5 сентября 2021",
//   list: [
//     {
//       title: "Общие положения.",
//       htmlText:
//         "<p>Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» (далее - Закон о персональных данных) и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые ООО «ОПН» (далее – Оператор).</p>",
//       htmlList: [
//         "Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.",
//         "Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта <a href='https://referral.opnpartner.ru'>https://referral.opnpartner.ru</a>",
//       ],
//     },
//     {
//       title: "Основные понятия, используемые в Политике",
//       htmlText: "",
//       htmlList: [
//         "Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной техники.",
//         "Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).",
//         "Веб-сайт – совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу https://referral.opnpartner.ru",
//         "Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств.",
//       ],
//     },
//     {
//       title: "Основные понятия, используемые в Политике",
//       htmlText: "",
//     },
//     {
//       title: "Основные понятия, используемые в Политике",
//       htmlText: "",
//       htmlList: [
//         "Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.",
//         "Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта <a href='https://referral.opnpartner.ru'>https://referral.opnpartner.ru</a>",
//         "Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной техники.",
//         "Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).",
//         "Веб-сайт – совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу https://referral.opnpartner.ru",
//         "Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств.",
//       ],
//     },
//   ],
// };

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
