<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import axios from "axios";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export default {
  data() {
    return {};
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "kwtest",
          content: "test",
        },
      ],
    };
  },
  methods: {},
  computed: {},
  mounted() {
    const referrer = document.referrer || "";
    localStorage.setItem("referrer", referrer);
    if (!localStorage.getItem("referrerFirst")) {
      localStorage.setItem("referrerFirst", referrer);
    }
    const invite = this.$route?.params?.id;
    if (invite) {
      const url = "/api/referrer/";
      const method = "POST";
      const data = {
        invite,
        referrer,
      };
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, method, url, data);
      }
      axios({ method, url, data })
        .then((resp) => {
          if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
            console.log(
              `[ ${LOG_LVL_LIST.DEBUG} ]	`,
              url,
              method,
              "SUCCESS",
              resp
            );
          }
        })
        .catch((err) => {
          if (isLogLevel(LOG_LVL_LIST.ERROR)) {
            console.log(
              `[ ${LOG_LVL_LIST.ERROR} ]	`,
              url,
              err,
              method,
              "RESPONSE",
              err?.response
            );
          }
        });
    }
  },
};
</script>

<style lang="scss"></style>
