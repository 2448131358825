import Vue from "vue";

export function savePersonalData(state, data) {
  state.personaldata = Object.assign(state.personaldata, data);
}

export function savePasport(state, data) {
  state.passport = Object.assign(state.passport, data);
}

export function saveAddress(state, data) {
  state.address = data;
}

export function setFiels(state, data) {
  Vue.set(state.passport, "files", data);
}
