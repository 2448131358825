import axios from "axios";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";
const APIURL = "/api/structure/";

export function fetch({ commit }) {
  const method = "GET";
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, method, APIURL);
  }
  return new Promise((resolve, reject) => {
    axios({ method, url: APIURL })
      .then((resp) => {
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(
            `[ ${LOG_LVL_LIST.DEBUG} ]	`,
            APIURL,
            method,
            "SUCCESS",
            resp
          );
        }
        commit("FETCH_ALL", resp.data);
        resolve();
      })
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(
            `[ ${LOG_LVL_LIST.ERROR} ]	`,
            APIURL,
            err,
            method,
            "RESPONSE",
            err.response
          );
        }
        reject();
      });
  });
}
