export function products(state) {
  return state?.products?.length > 0 ? state.products : [];
}

export function productItem(state, index) {
  return (index) => {
    return state?.products.hasOwnProperty(index) ? state.products[index] : null;
  };
}

export function applications(state) {
  return state?.applications?.length > 0 ? state.applications : [];
}
