import axios from "axios";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";
const APIURL = "/api/chatlist/";

export function fetch({ commit }) {
  const method = "GET";
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, method, APIURL);
  }
  return new Promise((resolve, reject) => {
    axios({ method, url: APIURL })
      .then((resp) => {
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(
            `[ ${LOG_LVL_LIST.DEBUG} ]	`,
            APIURL,
            method,
            "SUCCESS",
            resp
          );
        }
        const data = resp.data;
        commit("FETCH_ALL", data);
        resolve();
      })
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(
            `[ ${LOG_LVL_LIST.ERROR} ]	`,
            APIURL,
            err,
            method,
            "RESPONSE",
            err.response
          );
        }
        reject();
      });
  });
}

export function open({ commit, getters }, index = -1) {
  const method = "PUT";
  return new Promise((resolve, reject) => {
    const item = getters.chatlistItem(index);
    if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
      console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "open", index, item);
    }
    if (!item) {
      reject();
    }
    axios({ method, url: APIURL, data: item })
      .then((resp) => {
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(
            `[ ${LOG_LVL_LIST.DEBUG} ]	`,
            APIURL,
            method,
            "SUCCESS",
            resp
          );
        }
        commit("read", { index, item });
        resolve();
      })
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(
            `[ ${LOG_LVL_LIST.ERROR} ]	`,
            APIURL,
            err,
            method,
            "RESPONSE",
            err.response
          );
        }
        reject();
      });
  });
}

export function create({ commit }, data) {
  return new Promise((resolve, reject) => {
    const method = "POST";
    if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
      console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, method, APIURL, data);
    }
    axios({ method, url: APIURL, data })
      .then((resp) => {
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(
            `[ ${LOG_LVL_LIST.DEBUG} ]	`,
            APIURL,
            method,
            "SUCCESS",
            resp
          );
        }
        const data = resp.data;
        commit("create", data);
        resolve();
      })
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(
            `[ ${LOG_LVL_LIST.ERROR} ]	`,
            APIURL,
            err,
            method,
            "RESPONSE",
            err.response
          );
        }
        reject();
      });
  });
}
