<template lang="pug">
    .rating-list-table-thead
      .rating-list-table-th(v-for="th in heads[typeSeason]")
        .rating-list-table-th-wrap
          svg.icon(v-if="th.icon" :class="'icon-' + th.icon")
            use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + th.icon")
          span {{ th.title }}

</template>
<script>
const fields = [
  {
    icon: "",
    title: "Место",
  },
  {
    icon: "person",
    title: "Имя",
  },
  {
    icon: "star",
    title: "Ранг",
  },
];
export default {
  name: "RatingListHead",
  props: {
    typeSeason: {
      type: String,
      required: true,
      validator: function (value) {
        // Значение должно соответствовать одной из этих строк
        return ["hunter", "earner"].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      heads: {
        hunter: [
          ...fields,
          {
            icon: "group",
            title: "Привлечённых",
          },
        ],
        earner: [
          ...fields,
          {
            icon: "revenue",
            title: "Заработано",
          },
        ],
      },
    };
  },
};
</script>
