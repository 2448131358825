<template lang="pug">
  TwofaForm(inputType="number")
</template>

<script>
import TwofaForm from "/src/components/kwLogin/twofa";

export default {
  components: {
    TwofaForm,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  watch: {},
  mounted() {},
};
</script>

<style></style>
