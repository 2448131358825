import Utils from "/src/helper.js";
export function balance(state) {
  return state.balance;
}

export function forecast(state) {
  return state.forecast;
}

export function frozen(state) {
  return state.frozen || 0;
}

export function empty(state) {
  return state?.empty || false;
}

export function transactionsArray(state) {
  return Array.isArray(state?.transactions) ? state.transactions : [];
}

export function preview(state) {
  return {
    balance: Number.isInteger(state.balance)
      ? Utils.balanceFormat(state.balance) + " ₽"
      : "0 ₽",
    frozen: Number.isInteger(state.frozen)
      ? Utils.balanceFormat(state.frozen) + " ₽"
      : "0 ₽",
    forecast: Number.isInteger(state.forecast.value)
      ? Utils.balanceFormat(state.forecast.value) + " ₽"
      : "0 ₽",
  };
}

export function levelList(state) {
  return state?.levelList || [];
}
export function userList(state) {
  return state?.userList || [];
}
export function productList(state) {
  return state?.productList || [];
}

export function accountList(state) {
  return state?.accountList || [];
}
export function accountListItem(state) {
  return (id) => {
    const output = state?.accountList.find((item) => item.id == id);
    return output ? output : null;
  };
}

export function transactions(state) {
  return state?.transactions || [];
}

export function transactionsShort(state) {
  const all = transactions(state);
  if (all.length == 0) {
    return all;
  }
  return all.slice(0, 3);
}

export function payments(state) {
  if (Array.isArray(state?.payments) && state.payments.length > 0) {
    const output = [];
    const dateMap = new Map();
    state.payments.forEach((item) => {
      const date = new Date(item.date);
      date.setHours(0, 0, 0, 0);
      const key = date.toString();
      if (dateMap.has(key)) {
        const mapItem = dateMap.get(key);
        mapItem.push(item);
      } else {
        dateMap.set(key, [item]);
      }
    });
    for (const element of dateMap.entries()) {
      const obj = { day: element[0], paymentData: element[1] };
      output.push(obj);
    }
    return output;
  } else {
    return [];
  }
}
export function showMoreTransactions(state) {
  return (
    state.transactions.length > 0 &&
    state.transactionsAmount > state.transactions.length
  );
}

export function showMorePayments(state) {
  return (
    state.payments.length > 0 && state.paymentsAmount > state.payments.length
  );
}
export function types(state) {
  return Array.isArray(state?.types) ? state.types : [];
}

export function typeBytype(state) {
  return (type) => {
    const typesArray = types(state);
    const output = typesArray.find((item) => item.type == type);
    return output ? output : {};
  };
}

export function accountBytype(state) {
  return (type) => {
    const typesArray = accountList(state);
    const output = typesArray.find((item) => item.type == type);
    return output ? output : null;
  };
}
