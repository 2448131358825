// import state from "./state";
import * as getters from "./getters";
import * as mutations from "./mutations";
import * as actions from "./actions";

const state = function () {
  return {
    // banners: {
    //   archive: "/allbanners.zip",
    //   manual: "/Manual_RU_STM.pdf",
    //   rules: "/AlanWake_PC_Manual_EN.pdf",
    //   items: [
    //     {
    //       name: "Привлечение",
    //       sizes: [
    //         {
    //           w: 400,
    //           h: 240,
    //           file: "/images/logo.svg",
    //           code: `<a href=""><img src="https://opn.group/referral_240x400.png"/></a>`,
    //         },
    //         {
    //           w: 500,
    //           h: 300,
    //           file: "/images/achievements-1.png",
    //           code: `<a href=""><img src="https://opn.group/referral_240x400.png"/></a>`,
    //         },
    //         {
    //           w: 400,
    //           h: 400,
    //           file: "/images/news-5.jpg",
    //           code: `<a href=""><img src="https://opn.group/referral_240x400.png"/></a>`,
    //         },
    //         {
    //           w: 500,
    //           h: 500,
    //           file: "/images/attendance-empty.svg",
    //           code: `<a href=""><img src="https://opn.group/referral_240x400.png"/></a>`,
    //         },
    //       ],
    //     },
    //     {
    //       name: "Привлечение 2",
    //       sizes: [
    //         {
    //           w: 400,
    //           h: 240,
    //           file: "/images/achievements-1.png",
    //           code: "<a href=''><img src='https://opn.group/referral_240x402.png'/></a>",
    //         },
    //         {
    //           w: 500,
    //           h: 300,
    //           file: "/images/logo.svg",
    //           code: "<a href=''><img src='https://opn.group/referral_300x502.png'/></a>",
    //         },
    //         {
    //           w: 400,
    //           h: 400,
    //           file: "/images/attendance-empty.svg",
    //           code: "<a href=''><img src='https://opn.group/referral_400x402.png'/></a>",
    //         },
    //         {
    //           w: 500,
    //           h: 500,
    //           file: "/images/news-5.jpg",
    //           code: "<a href=''><img src='https://opn.group/referral_500x502.png'/></a>",
    //         },
    //       ],
    //     },
    //   ],
    // },
    // video: {
    //   manual: "/Manual_RU_STM.pdf",
    //   rules: "/AlanWake_PC_Manual_EN.pdf",
    //   items: [
    //     {
    //       name: "Привлечение",
    //       sizes: [
    //         {
    //           w: 400,
    //           h: 240,
    //           iframe: `<iframe src="https://www.youtube.com/embed/FTFaQWZBqQ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
    //           code: `<iframe src="https://www.youtube.com/embed/FTFaQWZBqQ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
    //         },
    //         {
    //           w: 500,
    //           h: 300,
    //           iframe: `<iframe src="https://www.youtube.com/embed/FTFaQWZBqQ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
    //           code: `<iframe src="https://www.youtube.com/embed/FTFaQWZBqQ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
    //         },
    //         {
    //           w: 400,
    //           h: 400,
    //           iframe: `<iframe src="https://www.youtube.com/embed/FTFaQWZBqQ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
    //           code: `<iframe src="https://www.youtube.com/embed/FTFaQWZBqQ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
    //         },
    //         {
    //           w: 500,
    //           h: 500,
    //           iframe: `<iframe src="https://www.youtube.com/embed/FTFaQWZBqQ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
    //           code: `<iframe src="https://www.youtube.com/embed/FTFaQWZBqQ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
    //         },
    //       ],
    //     },
    //     {
    //       name: "Привлечение 2",
    //       sizes: [
    //         {
    //           w: 402,
    //           h: 242,
    //           iframe: `<iframe src="https://www.youtube.com/embed/FTFaQWZBqQ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
    //           code: `<iframe src="https://www.youtube.com/embed/FTFaQWZBqQ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
    //         },
    //         {
    //           w: 502,
    //           h: 302,
    //           iframe: `<iframe src="https://www.youtube.com/embed/FTFaQWZBqQ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
    //           code: `<iframe src="https://www.youtube.com/embed/FTFaQWZBqQ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
    //         },
    //         {
    //           w: 402,
    //           h: 402,
    //           iframe: `<iframe src="https://www.youtube.com/embed/FTFaQWZBqQ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
    //           code: `<iframe src="https://www.youtube.com/embed/FTFaQWZBqQ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
    //         },
    //         {
    //           w: 502,
    //           h: 502,
    //           iframe: `<iframe src="https://www.youtube.com/embed/FTFaQWZBqQ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
    //           code: `<iframe src="https://www.youtube.com/embed/FTFaQWZBqQ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
    //         },
    //       ],
    //     },
    //   ],
    // },
  };
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
