import axios from "axios";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";
const APIURL = "/api/programs/";

export function fetch({ state, commit }, key) {
  if (!state.keys.includes(key)) {
    return;
  }
  const method = "POST";
  const data = { action: "get", key };
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, method, APIURL, data);
  }
  return axios({ method, url: APIURL, data })
    .then((resp) => {
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(
          `[ ${LOG_LVL_LIST.DEBUG} ]	`,
          APIURL,
          method,
          "SUCCESS",
          resp
        );
      }
      commit("FETCH_ALL", { key, data: resp.data });
    })
    .catch((err) => {
      if (isLogLevel(LOG_LVL_LIST.ERROR)) {
        console.log(
          `[ ${LOG_LVL_LIST.ERROR} ]	`,
          APIURL,
          err,
          method,
          "RESPONSE",
          err.response
        );
      }
    });
}

export function send({ state, commit }, form) {
  const method = "POST";
  const data = { action: "create", form };
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, method, APIURL, data);
  }
  return new Promise((resolve, reject) => {
    axios({ method, url: APIURL, data })
      .then((resp) => {
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(
            `[ ${LOG_LVL_LIST.DEBUG} ]	`,
            APIURL,
            method,
            "SUCCESS",
            resp
          );
        }
        commit("add", { key: "applications", data: resp.data });
        resolve();
      })
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(
            `[ ${LOG_LVL_LIST.ERROR} ]	`,
            APIURL,
            err,
            method,
            "RESPONSE",
            err.response
          );
        }
        reject();
      });
  });
}
