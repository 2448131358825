<template lang="pug">
  .deals
    .deals-filters
      select.deals-select.select-item(v-model="levelListSelected" @change="fetch(0)")
        option(key="all" value="all") Все уровни
        option(
          v-for="level in levelList"
          :key="level.id"
          :value="level.id"
        ) {{ level.text }}
      select.deals-select.select-item(v-model="productListSelected" @change="fetch(0)")
        option(key="all" value="all") Все продукты
        option(
          v-for="product in productList"
          :key="product.id"
          :value="product.id"
        ) {{ product.text }}
      select.deals-select.select-item(v-model="statusListSelected" @change="fetch(0)")
        option(key="all" value="all") Все статусы
        option(
          v-for="status in statusList"
          :key="status.id"
          :value="status.id"
        ) {{ status.text }}

    .deals-content
      .deals-body
        //- deals level
        .deals-level(v-for="level in dealsByLevels" :key="level.level")
          h2.deals-level-title {{ level.level }}
          .deals-table
            //- deals table head {{{
            .deals-table-head(v-if="!isMobile")
              .deals-table-title.deals-table-title--product
                svg.icon.icon-cube
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#cube")
                span.deals-table-title-caption Продукт
              .deals-table-title.deals-table-title--user
                svg.icon.icon-person
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#person")
                span.deals-table-title-caption От кого
              .deals-table-title.deals-table-title--revenue
                svg.icon.icon-revenue
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#revenue")
                span.deals-table-title-caption Доход
              .deals-table-title.deals-table-title--status
            // }}}

            //- deals table body {{{
            .deals-table-body
              .deals-table-row(v-for="deal in level.deals")
                .deals-table-row-progress(:style="{width: deal.progress + '%'}")
                .deals-table-row-content
                  //- deals product info {{{
                  .deals-product
                    .deals-product-icon
                      svg(:class="['icon-' + deal.productIcon, 'icon' ]")
                        use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + deal.productIcon")
                    .deals-product-content
                      p.deals-product-title {{ deal.title }}
                      .deals-product-data
                        span.deals-product-number № {{ deal.number }}
                        span.deals-product-created Создана: {{ dateFormat(deal.created) }}
                        span.deals-product-completion Прогноз завершения: {{ dateFormat(deal.completion) }}
                  // }}}
                  //- deals user info
                  .deals-table-title.deals-table-title--user(v-if="isMobile")
                    span.deals-table-title-caption От кого

                  .deals-user
                    .deals-user-icon
                      img.deals-user-icon-photo(
                        v-if="deal.user.avatar"
                        :src="deal.user.avatar"
                        :alt="'фото пользователя ' + deal.user.name"
                      )
                      span.deals-user-icon-letter(v-else :style="{backgroundColor: deal.user.iconColor}") {{ getFirstUserLetter(deal.user.name) }}
                    p.deals-user-name {{ deal.user.name }}
                    .deals-user-rank(:class="deal.user.rank")
                      svg(:class="['icon-' + rank(deal.user.rank).icon, 'icon' ]")
                        use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + rank(deal.user.rank).icon")

                  //- deals income info
                  .deals-table-title.deals-table-title--revenue(v-if="isMobile")
                    span.deals-table-title-caption Доход

                  p.deals-income {{ numberWithSpaces(deal.income) }} ₽

                  //- deals status info
                  .deals-table-title.deals-table-title--status(v-if="isMobile")
                    span.deals-table-title-caption Статус

                  .deals-status
                    .deals-status-icon(:data-title="getStatusData(deal.statusId).text")
                      svg(:class="['icon-' + getStatusData(deal.statusId).icon, 'icon' ]")
                        use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + getStatusData(deal.statusId).icon")
            // }}}

      button.button.show-more-btn(@click="more" v-if="showMore")
        svg.icon.icon-plus
          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#plus")
        | Показать ещё
</template>

<script>
import numberWithSpaces from "@/mixins/numberWithSpaces";
import onResize from "@/mixins/onResize";
import changeTitle from "@/mixins/changeTitle";
import emitCloseMenu from "@/mixins/emitCloseMenu";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

const STORE = "deals";
import { mapGetters } from "vuex";

export default {
  mixins: [numberWithSpaces, onResize, changeTitle, emitCloseMenu],
  data() {
    return {
      page: 1,
      isMobile: false,
      pageName: "Сделки",
      productListSelected: "all",
      levelListSelected: "all",
      statusListSelected: "all",
    };
  },
  computed: {
    ...mapGetters("rank", ["rank"]),
    ...mapGetters(STORE, [
      "productList",
      "statusList",
      "levelList",
      "dealsByLevels",
      "showMore",
    ]),
  },
  methods: {
    dateFormat(date) {
      try {
        return new Intl.DateTimeFormat("ru-RU", { dateStyle: "short" }).format(
          new Date(date)
        );
      } catch (err) {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(`[ ${LOG_LVL_LIST.ERROR} ]	`, "dateFormat", date, err);
        }
        return "";
      }
    },
    more() {
      this.page += 1;
      this.fetch(this.page, true);
    },
    fetch(page = 1, more = false) {
      this.page = page;
      this.$store
        .dispatch(`${STORE}/fetch`, {
          more,
          filters: {
            page,
            product: this.productListSelected,
            level: this.levelListSelected,
            status: this.statusListSelected,
          },
        })
        .catch((err) => {
          if (isLogLevel(LOG_LVL_LIST.ERROR)) {
            console.log(`[ ${LOG_LVL_LIST.ERROR} ]	`, STORE, "fetch", err);
          }
        })
        .finally(() => {
          console.log("fetch dealsByLevels:", this.dealsByLevels);
          this.$store.commit("loaderOff");
        });
    },
    getFirstUserLetter(str) {
      return str.slice(0, 1).toUpperCase();
    },
    getStatusData(id) {
      return this.statusList.find((item) => item.id === id);
    },
  },
  created() {
    this.emitCloseMenu();
    this.onResize();
    window.addEventListener("resize", this.onResize);
    this.fetch();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.changeTitle(vm.pageName);
    });
  },
};
</script>

<style></style>
