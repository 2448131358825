<template lang="pug">
  .agreement
    <vue-scroll @handle-scroll="scrollHandler">
      .loading(v-if="loader" style="height: 100vh; max-height: 100%;")
        <inline-svg class="loader" :src="require('@/assets/images/page-loader.svg')"></inline-svg>
      .agreement-logo(v-show="!loader")
        a.logo(href="/")
          img(src="@/assets/images/logo.svg" alt="logo")
      .agreement-wrap(v-show="!loader" v-if="title")
        .agreement-title
          h1.title-h2 {{ title }}
          span {{ subtitle }}

        .agreement-content(ref="agreement-content")
          .text
            ol(ref="list-container")
              li(v-for="(item, index) in list" :key="index" :ref="'menu-' + index" :id="'menu-' + index").agreement-list-item
                b {{ item.title }}
                div(v-html="item.htmlText")
                ol
                  li(v-for="(subitem, subindex) in item.htmlList" :key="subindex" v-html="subitem")
      .agreement-wrap(v-show="!loader" v-if="html" v-html="html")

      Social(v-show="!loader")
    </vue-scroll>
</template>
<script>
import Social from "../components/Social.vue";
import { mapGetters } from "vuex";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

const STORE = "agreement";

export default {
  components: {
    Social,
  },
  data() {
    return {
      activeItem: 0,
    };
  },
  computed: {
    ...mapGetters(["loader"]),
    ...mapGetters(STORE, ["html", "title", "subtitle", "list"]),
  },
  methods: {
    openMenu(index) {
      let listItems = this.$refs["list-container"].querySelectorAll(
        ".agreement-list-item"
      );
      let slide = listItems[index];
      slide.scrollIntoView({ block: "start", behavior: "smooth" });
    },
    scrollHandler() {
      // TODO
      return;
      let listItems = this.$refs["list-container"].querySelectorAll(
        ".agreement-list-item"
      );
      // let winScroll = window.scrollY;
      // console.log('winScroll = ', winScroll);
      listItems.forEach((item, index) => {
        // let itemHeight = item.scrollHeight;
        // console.log('itemHeight = ', itemHeight);
        let itemClientTop = item.getBoundingClientRect().top;
        let itemClientBottom = item.getBoundingClientRect().bottom;
        // console.log('itemClientTop = ', itemClientTop);
        // console.log('itemClientBottom = ', itemClientBottom);
        if (
          // itemClientTop + winScroll <= winScroll + 10 &&
          // itemClientTop + winScroll + itemHeight > winScroll
          itemClientTop <= 10 &&
          itemClientBottom > 0
        ) {
          this.activeItem = index;
        }
      });
    },
  },
  created() {
    this.$store.commit("loaderOn");
    this.$store
      .dispatch(`${STORE}/fetch`)
      .then(() => {})
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(`[ ${LOG_LVL_LIST.ERROR} ]	`, STORE, "fetch", err);
        }
      })
      .finally(() => {
        this.$store.commit("loaderOff");
      });
  },
  mounted() {
    // this.scrollHandler();
  },
  beforeDestroy() {
    this.$store.commit("loaderOff");
    // window.removeEventListener('scroll', this.scrollHandler);
  },
};
</script>

<style lang="scss"></style>
