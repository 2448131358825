<template lang="pug">

  .application
    .application-block(v-if="applications.length == 0")
      .structure-empty
        .application-block-content
          span Здесь появится ваши заявки
    .application-block(v-else v-for="app in applications" :key="app.id")
      .application-block-icon
        svg(:class="['icon', 'icon-' + app.icon]")
          use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + app.icon")
      .application-block-content
        p.application-block-number {{ app.id }}
        .application-block-desc
          span {{ app.title }}
          span {{ dateFormat(app.date) }}
      .application-block-status
        svg(:class="['icon', 'icon-' + statusIcons[app.status] ]")
          use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + statusIcons[app.status]")

</template>

<script>
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";
const STORE = "programs";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      statusIcons: {
        inprogres: "clock",
        done: "check",
        failure: "stop",
      },
    };
  },
  computed: {
    ...mapGetters(STORE, ["applications"]),
  },
  methods: {
    dateFormat(date) {
      try {
        return new Intl.DateTimeFormat("ru-RU", { dateStyle: "long" }).format(
          new Date(date)
        );
      } catch (err) {
        console.log(err);
        return "";
      }
    },
  },
  created() {
    this.$store
      .dispatch(`${STORE}/fetch`, "applications")
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(`[ ${LOG_LVL_LIST.ERROR} ]	`, STORE, "fetch", err);
        }
      })
      .finally(() => {
        this.$store.commit("loaderOff");
      });
  },
};
</script>

<style></style>
