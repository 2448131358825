<template lang="pug">
  .structure
    .structure-tabs
      .structure-tabs-menu
        .structure-tabs-menu-item( v-for='(tab, index) in tabs'
          :key="index"
          @click="sidebarButtonHandler(tab.component)"
          :class="[{selected: currentComponent===tab.component }]")
          svg(class="icon" :class="`icon-` + tab.icon")
            use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + tab.icon")
          span {{ tab.title }}
        .structure-tabs-counter
          svg(class="icon icon-group")
            use(xlink:href="@/assets/images/sprite/sprite-inline.svg#group")
          span {{ countAllUser }}

      .structure-tabs-content
        component(
          :is="currentComponent"
        )

</template>

<script>
import StructureList from "@/components/structure/StructureList";
import StructureMap from "@/components/structure/StructureMap";
import changeTitle from "@/mixins/changeTitle";
import emitCloseMenu from "@/mixins/emitCloseMenu";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

const STORE = "structure";
import { mapGetters } from "vuex";

export default {
  name: "Structure",
  components: {
    StructureList,
    StructureMap,
  },
  mixins: [changeTitle, emitCloseMenu],
  data() {
    return {
      tabs: [
        {
          icon: "map",
          title: "Карта",
          component: "StructureMap",
        },
        {
          icon: "list",
          title: "Список",
          component: "StructureList",
        },
      ],
      currentComponent: "StructureMap",
      pageName: "Моя структура",
    };
  },
  computed: {
    ...mapGetters(STORE, ["levelArray"]),
    countAllUser() {
      let count = 0;
      this.levelArray.forEach((item) => {
        count += item.length;
      });
      return count;
    },
  },
  methods: {
    sidebarButtonHandler(sidebarComponentName) {
      this.currentComponent = sidebarComponentName;
    },
  },
  created() {
    this.emitCloseMenu();
  },
  mounted() {
    this.$store
      .dispatch("structure/fetch")
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(`[ ${LOG_LVL_LIST.ERROR} ]	`, STORE, "fetch", err);
        }
      })
      .finally(() => {
        this.$store.commit("loaderOff");
      });
  },
  beforeRouteEnter(to, from, next) {
    document.title = to.meta.title;
    next((vm) => {
      vm.changeTitle(vm.pageName);
    });
  },
};
</script>
