// import state from "./state";
import * as getters from "./getters";
import * as mutations from "./mutations";
import * as actions from "./actions";

const state = function () {
  return {
    beginner: {
      title: "Новичок",
      icon: "chevron",
    },
    newcomer: {
      title: "Опытный",
      icon: "chevron",
    },
    specialist: {
      title: "Специалист",
      icon: "expert",
    },
    expert: {
      title: "Эксперт",
      icon: "expert",
    },
  };
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
