// import Vue from "vue";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export function FETCH_ALL(state, data) {
  if (Array.isArray(data)) {
    state.blocks = data;
  } else if (data) {
    state.blocks = [];
    state.blocks.push(data);
  } else {
    state.blocks = [];
  }
}
