<template lang="pug">
    .payments
      .payments-body
        form.payments-form(@submit.prevent)
          .payments-form-body
              //- wallet
              .payments-wallet
                  .payments-wallet-icon
                      svg(class="icon icon-wallet")
                          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#wallet")
                  .payments-wallet-desc
                      p.payments-wallet-caption OPN кошелёк
                      p.payments-wallet-sum {{ preview.balance }}
              //- sum input
              .form-field.kw-error-fix(:class="{focused: paymentFieldFocused, filled: paymentFieldFilled, invalid: paymentFieldInvalid}")
                input.form-input(
                  ref="paymentInput"
                  type="number"
                  placeholder="Введите сумму"
                  @input="paymentValidation"
                  @focus="paymentFieldFocused = true"
                  @blur="onBlurInputHandler"
                  v-model.number="paymentField"
                )
                input-error(:showVar="paymentFieldInvalid")
                  p {{ paymentPlaceholder || "Ошибка вывода средств, повторите запрос"}}
              //- account selection
              .payments-account.select(data-state="")
                  svg(class="icon icon-arrow-down")
                      use(xlink:href="@/assets/images/sprite/sprite-inline.svg#arrow-down")
                  .select-title(
                      @click="selectMenuHandler")
                      svg(:class="['icon-' + payAccount.icon, 'icon' ]")
                          use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + payAccount.icon")
                      span {{ payAccount.title }}
                  .select-menu
                    ul.select-content
                      li(v-for="(account, index) in accountList" :key="account.id")
                        input.select-input(
                          v-model="selectedAccount"
                          name="accountSelect"
                          type="radio"
                          :id="'account' + account.id",
                          :value="index"
                        )
                        label.select-label(:for="'account' + account.id" @click="closeSelectMenu")
                          svg(:class="['icon-' + typeAccount(account.type).icon, 'icon' ]")
                              use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + typeAccount(account.type).icon")
                          | {{ typeAccount(account.type).title }}
                    .add-btn.button.button-grey(@click="addAccount")
                        svg.icon.icon-plus
                            use(xlink:href="@/assets/images/sprite/sprite-inline.svg#plus")
                        | Добавить реквизиты

          button.payments-form-btn.button(
            @click="payout"
            :class="{'button-disabled': loadingResend, 'button-loading': loadingResend}"
            :disabled="loadingResend"
          )
            inline-svg(
              v-if="loadingResend"
              class="loader"
              :src="require('@/assets/images/sprite/loader.svg')"
            )
            span(v-else)
              svg(class="icon icon-payments")
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#payments")
              | Вывести средства

        //- payments list
        .payments-list(v-if="empty")
          .payments-empty
            img(src='@/assets/images/payment.png' alt='выплаты')
            p.payments-empty-text Здесь появится ваша история выплат
        .payments-list(v-else)
          .payments-day(v-for='day in payments' :key='day.day')
              .payments-day-caption {{ dateFormat(day.day) }}
              .payments-item(v-for='(payment, index) in day.paymentData' :key='index')
                  p.payments-item-icon
                      svg(:class="['icon-' + payment.icon, 'icon' ]")
                          use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + payment.icon")
                  .payments-item-desc
                      p.payments-item-title {{ payment.title }}
                      span.payments-item-app Заявка № {{ payment.id }}
                  .payments-item-info
                      span.payments-item-sum {{ balanceFormat(payment.sum) }} ₽
                      .payments-item-status
                          svg(:class="['icon-' + payment.status, 'icon' ]")
                              use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + payment.status")

      button.show-more-btn.button(
        v-if="showMorePayments"
        @click="more"
        :class="{'button-disabled': loadingResend, 'button-loading': loadingResend}"
        :disabled="loadingResend"
      )
        inline-svg(
          v-if="loadingResend"
          class="loader"
          :src="require('@/assets/images/sprite/loader.svg')"
        )
        span(v-else)
          svg.icon.icon-plus
            use(xlink:href="@/assets/images/sprite/sprite-inline.svg#plus")
          | Показать ещё

      //- menu mask
      .menu-mask(v-if="menuMaskShow" @click="closeSelectsMenu")
        //- :class="{show: menuMaskShow}"

</template>

<script>
import Utils from "/src/helper.js";
import { mapGetters } from "vuex";
const STORE = "wallet";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export default {
  data() {
    return {
      page: 1,
      loadingResend: false,
      paymentField: "",
      paymentPlaceholder: "",
      paymentFieldFocused: false,
      paymentFieldFilled: false,
      paymentFieldInvalid: false,
      selectedAccount: 0,
      menuMaskShow: false,
    };
  },
  computed: {
    ...mapGetters(STORE, [
      "preview",
      "balance",
      "accountList",
      "payments",
      "showMorePayments",
      "types",
      "typeBytype",
    ]),
    empty() {
      return !(this.payments.length > 0);
    },
    payAccount() {
      return this.typeAccount(
        this.accountList[this.selectedAccount]?.type || ""
      );
    },
  },
  methods: {
    paymentValidation(v) {
      this.paymentFieldInvalid = false;
      this.paymentPlaceholder = "";
    },
    more() {
      this.page += 1;
      this.fetch(this.page, true);
    },
    fetch(page = 1, more = false) {
      this.page = page;
      this.loadingResend = true;
      setTimeout(() => {
        this.$store
          .dispatch(`${STORE}/filter`, { key: "payments", page, more })
          .catch((err) => {
            if (isLogLevel(LOG_LVL_LIST.ERROR)) {
              console.log(`[ ${LOG_LVL_LIST.ERROR} ]	`, STORE, "fetch", err);
            }
          })
          .finally(() => {
            this.loadingResend = false;
            this.$store.commit("loaderOff");
          });
      }, 0);
    },
    typeAccount(type) {
      const item = this.typeBytype(type);
      return item ? item : {};
    },
    dateFormat(date) {
      try {
        return new Intl.DateTimeFormat("ru-RU", { dateStyle: "medium" }).format(
          new Date(date)
        );
      } catch (err) {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(`[ ${LOG_LVL_LIST.ERROR} ]	`, date, err);
        }
        return "";
      }
    },
    payout() {
      this.loadingResend = true;
      this.$store
        .dispatch(`${STORE}/payout`, {
          sum: this.paymentField,
          account: this.accountList[this.selectedAccount]?.id || "",
        })
        .catch((err) => {
          this.paymentFieldInvalid = true;
          this.paymentField = "";
          this.paymentPlaceholder = err;
        })
        .finally(() => {
          this.loadingResend = false;
        });
    },
    addAccount() {
      this.$router.push("/wallet/details/");
    },
    balanceFormat(n) {
      return Utils.balanceFormat(n);
    },
    onBlurInputHandler() {
      this.paymentFieldFocused = false;

      if (this.paymentField === "") {
        this.paymentFieldFilled = false;
      } else {
        this.paymentFieldFilled = true;
      }
    },
    selectMenuHandler(event) {
      let select = event.currentTarget.closest(".select");
      if (select.dataset.state === "active") {
        select.dataset.state = "";
        this.menuMaskShow = false;
      } else {
        select.dataset.state = "active";
        this.menuMaskShow = true;
      }
    },
    closeSelectMenu(event) {
      event.currentTarget.closest(".select").dataset.state = "";
      this.menuMaskShow = false;
    },
    closeSelectsMenu() {
      let selects = document.querySelectorAll(".select");
      selects.forEach((select) => {
        select.dataset.state = "";
      });
      this.menuMaskShow = false;
    },
  },
  created() {
    this.fetch();
  },
};
</script>

<style lang="scss">
.form-field.kw-error-fix {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > .error-message {
    position: relative;
    left: 0;
    width: 100%;
    margin-top: 1rem;
  }
}
</style>
