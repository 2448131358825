<template lang="pug">
  .not-found
    .not-found-logo
      //- a.logo(href="#")
      router-link.logo(
        to="/"
        )
        img(src="@/assets/images/logo.svg" alt="logo")
    .not-found-content
      img(src="@/assets/images/404.png" alt="404")
      .not-found-title 404
      .not-found-subtitle Вы заблудились!
      router-link(to="/").button Вернуться домой
    Social()




</template>

<script>
import Social from '../components/Social.vue';
export default {
  components: {
    Social,
  },
};
</script>
