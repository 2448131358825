<template lang="pug">
    .profile-edit
        .profile-edit-wrapper 
            .profile-edit-head
                .profile-edit-title
                    .profile-edit-title-icon
                        svg.icon.icon-profile
                            use(xlink:href="@/assets/images/sprite/sprite-inline.svg#profile")
                    span Личность
                <profile-back-btn/>
            .profile-progressbar-wrapper
                .profile-progressbar
                    .progressbar-current._progress-half
                    .progressbar-body
                        .progressbar-step-item._finished
                            .progressbar-circle 1
                            span Личная информация
                        .progressbar-step-item._current
                            .progressbar-circle 2
                            span Детали документа
                        .progressbar-step-item
                            .progressbar-circle 3
                            span Загрузка документа
            .profile-personality-form-block
                form.profile-personality-form
                    .form-row
                        .form-field(
                          :class="{filled: !!passportSerial, invalid: passportSerial.length < 3}"
                        )
                            label.form-label() Серийный номер
                            input.form-input(
                                type="text"
                                minlength="3"
                                maxlength="30"
                                name="passport_serial_number"
                                v-model="passportSerial"
                                @focus="onFocusInputHandler"
                                @blur="onBlurInputHandler"
                                )
                        .form-field(:class="{filled: !!passportIssued, invalid: passportIssued.length < 3}")
                            label.form-label() Кем выдан
                            input.form-input(
                                type="text"
                                minlength="3"
                                maxlength="200"
                                name="passport_issued_by"
                                v-model="passportIssued"
                                @focus="onFocusInputHandler"
                                @blur="onBlurInputHandler"
                                )
                    .form-row
                      .form-date-select.form-date-select--issue(
                        :class="{invalid: !(selectedDay && selectedMonth && selectedYear)}"
                      )
                        select(v-model="selectedDay")
                            option(
                                v-for="day in daysInMonth"
                                :key="day"
                                ) {{ day }}
                        select(v-model="selectedMonth")
                            option(
                                v-for="(month, index) in monthsList"
                                :key="month.monthName"
                                :value="index + 1"
                                ) {{ month.monthName }}
                        select(v-model="selectedYear")
                            option(
                                v-for="(item, year) in 60"
                                :key="year"
                                ) {{currentDate - year}}

                      .form-field(:class="{filled: !!passportDepartmentCode}")
                          label.form-label() Код подразделения
                          input.form-input(
                              type="text"
                              name="passport_department_code"
                              v-model="passportDepartmentCode"
                              @focus="onFocusInputHandler"
                              @blur="onBlurInputHandler"
                              )
        .profile-edit-btn-wrapper
            button.button.button-grey.profile-edit-back-btn(
              @click="$router.go(-1)"
              )
              svg.icon.icon-arrow-left
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#arrow-left")
              | Назад
            button.button.profile-edit-continue-btn(
              :class="{'button-disabled': errors}"
              @click="nextStep"
              )
              | Продолжить
              svg.icon.icon-arrow-right
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#arrow-right")
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";
const STORE = "personEdit";

export default {
  data() {
    return {
      passportSerial: "",
      passportIssued: "",
      passportDepartmentCode: "",
      selectedDay: null,
      selectedMonth: null,
      selectedYear: null,
      monthsList: [
        { monthName: "Январь", days: "31" },
        { monthName: "Февраль", days: "28" },
        { monthName: "Март", days: "31" },
        { monthName: "Апрель", days: "30" },
        { monthName: "Май", days: "31" },
        { monthName: "Июнь", days: "30" },
        { monthName: "Июль", days: "31" },
        { monthName: "Август", days: "31" },
        { monthName: "Сентябрь", days: "30" },
        { monthName: "Октябрь", days: "31" },
        { monthName: "Ноябрь", days: "30" },
        { monthName: "Декабрь", days: "31" },
      ],
    };
  },
  methods: {
    nextStep() {
      const data = {
        serial: this.passportSerial,
        issued: this.passportIssued,
        department_code: this.passportDepartmentCode,
        date: `${this.selectedYear}-${this.selectedMonth
          .toString()
          .padStart(2, "0")}-${this.selectedDay.toString().padStart(2, "0")}`,
      };
      this.$store.commit("personEdit/savePasport", data);
      this.$router.push(`/person-edit-step-3`);
    },
    onFocusInputHandler(event) {
      event.target.parentElement.classList.add("focused");
      event.target.focus();
    },
    onBlurInputHandler(event) {
      let inputValue = event.target.value;
      event.target.parentElement.classList.remove("focused");
      if (inputValue === "") {
        event.target.parentElement.classList.remove("filled");
      } else {
        event.target.parentElement.classList.add("filled");
      }
    },
  },
  computed: {
    ...mapGetters(STORE, ["passport"]),
    errors() {
      return !(
        this.selectedDay &&
        this.selectedMonth &&
        this.selectedYear &&
        this.passportSerial &&
        this.passportIssued
      );
    },
    currentDate() {
      return new Date().getFullYear();
    },
    daysInMonth() {
      return new Date(this.selectedYear, this.selectedMonth, 0).getDate();
    },
  },
  created() {
    this.$store
      .dispatch(`${STORE}/getPassport`)
      .then(() => {
        this.passportSerial = this.passport.serial || "";
        this.passportIssued = this.passport.issued || "";
        this.passportDepartmentCode = this.passport.department_code || "";

        const date = new Date(this.passport.date || "");
        // if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        //   console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "GET DATE: ", date);
        // }
        this.selectedDay = date?.getDate() || null;
        this.selectedMonth = date?.getMonth() + 1 || null;
        this.selectedYear = date?.getFullYear() || null;
      })
      .finally(() => {
        this.$store.commit("loaderOff");
      });
  },
};
</script>

<style lang="scss" scoped>
.form-date-select {
  &.invalid {
    box-shadow: 0px 0px 0px 4px #ffc8bc;
  }
}
.form-row {
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  @media (max-width: 1249.98px) {
    margin-bottom: 20px;
  }
  .form {
    &-field {
      width: calc(50% - 15px);
      margin-bottom: 0;
      @media (max-width: 1249.98px) {
        width: 100%;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.profile-edit {
  &-btn-wrapper {
    display: flex;
    .button {
      width: 50%;
      border-radius: 0;
    }
  }
}
</style>
