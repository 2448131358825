// export function levelList(state) {
//   return state.data?.levelList || [];
// }

export function statisticData(state) {
  return state?.income || [];
}

export function chartData(state) {
  const data = statisticData(state);
  if (data.length <= 0) {
    return {};
  }
  const labels = [];
  const datasetIncome = {
    label: "Доход",
    borderColor: "#69BB55",
    pointBackgroundColor: "#69BB55",
    borderWidth: 3,
    pointBorderColor: "#69BB55",
    backgroundColor: "rgba(105, 187, 85, 0.1)",
    data: [],
    yAxisID: "sum",
  };
  const datasetDeal = {
    label: "Сделок",
    borderColor: "#25A8F2",
    pointBackgroundColor: "#25A8F2",
    pointBorderColor: "#25A8F2",
    borderWidth: 3,
    backgroundColor: "rgba(37, 168, 242, 0.1)",
    data: [],
    yAxisID: "deals",
  };
  data.forEach((item) => {
    labels.push(item.date);
    datasetIncome.data.push(item.sum);
    datasetDeal.data.push(item.deals);
  });
  return { labels, datasets: [datasetIncome, datasetDeal] };
}

export function sourcesData(state) {
  return state?.sources || [];
}

export function sourcesChartData(state) {
  const data = sourcesData(state);
  if (data.length <= 0) {
    return {};
  }
  const labels = [];
  const dataset = {
    backgroundColor: [],
    data: [],
  };
  data.forEach((item) => {
    labels.push(item.source);
    dataset.data.push(item.switch);
    dataset.backgroundColor.push(item.color);
  });
  return { labels, datasets: [dataset] };
}

export function attendanceData(state) {
  return state?.attendance || [];
}

export function attendanceChartData(state) {
  const data = attendanceData(state);
  if (data.length <= 0) {
    return {};
  }
  const labels = [];
  const datasetIncome = {
    label: "Переходы",
    borderColor: "#69BB55",
    pointBackgroundColor: "#69BB55",
    borderWidth: 3,
    pointBorderColor: "#69BB55",
    backgroundColor: "rgba(105, 187, 85, 0.1)",
    data: [],
  };
  const datasetDeal = {
    label: "Регистрации",
    borderColor: "#25A8F2",
    pointBackgroundColor: "#25A8F2",
    pointBorderColor: "#25A8F2",
    borderWidth: 3,
    backgroundColor: "rgba(37, 168, 242, 0.1)",
    data: [],
  };
  data.forEach((item) => {
    labels.push(item.date);
    datasetIncome.data.push(item.switch);
    datasetDeal.data.push(item.reg);
  });
  return { labels, datasets: [datasetIncome, datasetDeal] };
}

export function levelList(state) {
  return state?.levelList || [];
}
