import Vue from "vue";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export function FETCH_ALL(state, data) {
  if (data && Array.isArray(data)) {
    state.array = data;
  } else if (data) {
    ADD(state, data);
  } else {
    state.array = [];
  }
}

export function read(state, { index, item }) {
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "read", index, item);
  }
  if (!item) {
    return;
  }
  item.isread = true;
  Vue.set(state.array, index, item);
}

export function create(state, data) {
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "create", data);
  }
  if (!data || !data.id) {
    return;
  }
  if (!Array.isArray(state?.array)) {
    state.array = [];
  }
  state.array.push(data);
}

export function ADD(state, data) {
  if (!Array.isArray(state.array)) {
    state.array = [];
  }
  state.array.push(data);
}

export function SOCKET_SET(state, data) {
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "SOCKET SET", data);
  }
  if (data?.value) {
    const target = state?.array.findIndex((item) => item.id == data.value.id);
    if (target != -1) {
      Vue.set(state.array, target, data.value);
    } else {
      ADD(state, data.value);
    }
  }
}
