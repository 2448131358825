<template lang="pug">
  .profile
    .profile-tabs
      .profile-tabs-menu
        
        router-link.profile-tabs-menu-item( v-for='(tab, index) in tabs'
          :key="index"
          :to="`/profile/` + tab.path"
          )
          svg(class="icon" :class="`icon-` + tab.icon")
            use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + tab.icon")
          span {{ tab.title }}
      .profile-tabs-content
        <router-view @closeMenu='emitCloseMenu' />


    .profile-logout
      .profile-logout-btn(@click="logout") Выйти из аккаунта
        svg(class="icon icon-enter")
          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#enter")


</template>

<script>
import { mapGetters } from "vuex";
import changeTitle from "@/mixins/changeTitle";
import emitCloseMenu from "@/mixins/emitCloseMenu";

export default {
  name: "Profile",
  components: {},
  mixins: [changeTitle, emitCloseMenu],
  data() {
    return {
      tabs: [
        {
          icon: "profile",
          title: "Профиль",
          component: "Account",
          path: "",
        },
        {
          icon: "settings",
          title: "Настройки",
          component: "Settings",
          path: "settings",
        },
      ],
      currentComponent: "Account",
      userFullName: "Ростислав Артемьевских",
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    emitCloseMenu() {
      this.$emit("closeMenu");
    },
    changeTitle(pagename) {
      this.$emit("changeTitle", pagename);
    },
  },
  created() {
    this.emitCloseMenu();
    this.$store.commit("loaderOff");
  },
  mounted() {
    this.$emit("changeTitle", `${this.user.name} ${this.user.surname}`);
  },
};
</script>
