<template lang="pug">
  .settings-block-inner
    .settings-block
      .settings-block-header
        .settings-block-head
          .settings-block-head-icon
            svg(class="icon icon-envelope")
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#envelope")
          p Электронный адрес
        <settings-back-btn/>
      form.form.settings-form(@submit.prevent)
        .settings-form-inner
          .form-field.focused(
            :class="{invalid: !valid}"
          )
            label.form-label email
            input.form-input(
              v-model="value"
              type="text"
              name="email"
              @focus="onFocusInputHandler"
              @blur="onBlurInputHandler"
              @input="validateEmail"
            )
        button.settings-form-btn(
          :class="{'button-disabled': !valid}"
          @click="save"
        )
          svg.icon.icon-check
            use(xlink:href="@/assets/images/sprite/sprite-inline.svg#check")
          | Сохранить



</template>

<script>
import { mapGetters } from "vuex";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

import validateEmail from "@/mixins/validateEmail";
import onFocusInputHandler from "@/mixins/onFocusInputHandler";
import onBlurInputHandler from "@/mixins/onBlurInputHandler";

export default {
  mixins: [validateEmail, onFocusInputHandler, onBlurInputHandler],
  data() {
    return {
      value: "",
      valid: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  watch: {
    user: function () {
      this.value = this.user.email;
      this.validateEmail();
    },
  },
  methods: {
    save() {
      if (this.value === this.user.email) {
        console.log(
          `[ ${LOG_LVL_LIST.DEBUG} ]	`,
          "email is not new, request is not send",
          this.value
        );
        this.$router.push(`/profile`);
        return;
      }
      const data = { email: this.value };
      this.$store
        .dispatch("setMetaItem", data)
        .then(() => this.$router.push(`/profile`))
        .catch((resp) => {
          this.valid = false;
        });
    },
    onFocusInputHandler(event) {
      event.target.parentElement.classList.add("focused");
      event.target.focus();
    },
    onBlurInputHandler(event) {
      let inputValue = event.target.value;
      if (inputValue === "") {
        event.target.parentElement.classList.remove("focused");
      }
    },
    validateEmail() {
      const validRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(
          `[ ${LOG_LVL_LIST.DEBUG} ]	`,
          "validateEmail",
          this.value,
          this.value.match(validRegex) ? true : false
        );
      }
      if (this.value.match(validRegex)) {
        this.valid = true;
      } else {
        this.valid = false;
      }
    },
  },
  created() {
    this.$store.commit("loaderOff");
  },
  mounted() {
    this.value = this.user.email;
    this.validateEmail();
  },
};
</script>
