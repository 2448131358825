import Vue from "vue";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export function FETCH_ALL(state, data) {
  state = Object.assign(state, data);
}
export function SOCKET_FETCH_ALL(state, data) {
  FETCH_ALL(state, data.value);
}

export function updateTransactions(state, data) {
  Vue.set(state, "transactions", data);
}

export function empty(state, data) {
  Vue.set(state, "empty", data);
}

export function SET_ARRAY_BY_KEY(state, obj) {
  if (!state.keys.includes(obj?.key)) {
    return;
  }
  const data = Array.isArray(obj?.data) ? obj.data : [];
  const current = state[obj.key];
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "SET_ARRAY_BY_KEY", current, data);
  }
  let output = null;
  if (obj.add === true && Array.isArray(current)) {
    output = current.concat(data);
  } else {
    output = data;
  }
  if (output) Vue.set(state, obj.key, output);
}
