<template lang="pug">
  .login
    <vue-scroll>
      .login-inner
        .login-block
          a.logo(href="#")
              img(src="@/assets/images/logo.svg" alt="logo")
          .login-main
              h1.title-h1 Восстановление пароля

              form.form(@submit.prevent)

                //- code field
                .form-code-field
                  span.form-code-message Введите код из {{ sourceText }}
                  .form-code-inputs(
                    :class="{invalid: invalidCode}"
                    )
                    input.form-code-input(type="text" placeholder=" " :maxlength="maxlength"
                      ref="input_1"
                      @input="codeInputHandler($event, 1)"
                      name="digit1"
                      )
                    input.form-code-input(type="text" placeholder=" " :maxlength="maxlength"
                      ref="input_2"
                      @input="codeInputHandler($event, 2)"
                      name="digit2"
                      )
                    input.form-code-input(type="text" placeholder=" " :maxlength="maxlength"
                      ref="input_3"
                      @input="codeInputHandler($event, 3)"
                      name="digit3"
                      )
                    input.form-code-input(type="text" placeholder=" " :maxlength="maxlength"
                      ref="input_4"
                      @input="codeInputHandler($event, 4)"
                      name="digit4"
                      )
                    input.form-code-input(type="text" placeholder=" " :maxlength="maxlength"
                      ref="input_5"
                      @input="codeInputHandler($event, 5)"
                      name="digit5"
                      )
                  
                    //- code error message
                    <input-error :showVar="invalidCode">
                      <p>Неверно указан код.</p>
                      <p>Пожалуйста, попробуйте еще раз</p>
                    </input-error>
                    

                //- resend code button
                button.button.button-resend-code(
                  :class="{'button-disabled': resendLock || loading}"
                  :disabled="resendLock || loading"
                  @click="resendCode"
                  )
                  svg.icon.icon-refresh
                    use(xlink:href="@/assets/images/sprite/sprite-inline.svg#refresh")
                  span
                    span.main-text Отправить код повторно
                    span.resend-time(v-if="resendLock") можно через 
                      span.resend-seconds {{ seconds }}
                      |  сек


                //- enter button
                button.button.button-enter(
                  :class="{'button-disabled': !isCodeValidated, 'button-loading': loading}"
                  :disabled="!isCodeValidated || loading"
                  @click="checkServerData"
                  )
                  <inline-svg v-if="loading" class="loader" :src="require('@/assets/images/sprite/loader.svg')"></inline-svg>
                  span.button-content(v-else)
                    //- svg.icon.icon-add
                    //-   use(xlink:href="@/assets/images/sprite/sprite-inline.svg#add")
                    | Восстановить

              //- registration question and link
              p.login-register-question Вы ещё не зарегистрированы? 
              router-link.login-register-link.link(to="/registration") Зарегистрироваться
                svg.icon.icon-arrow-right
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#arrow-right")

        .login-poster
          img(src="@/assets/images/sign-in.jpg")
          .login-poster-inner
            p.title-h1 Наколдуй свою структуру
            router-link(to="/registration").button Присоединиться
      
    </vue-scroll>
    
</template>

<script>
import axios from "axios";
import resendCode from "@/mixins/resendCode";
import codeInputHandler from "@/mixins/codeInputHandler";

export default {
  mixins: [resendCode, codeInputHandler],
  data() {
    return {
      invalidCode: false,
      loading: false,
      isSms: true,
      isEmail: false,
    };
  },
  methods: {
    checkServerData() {
      if (this.isCodeValidated && !this.loading) {
        this.loading = true;
        const code = this.enteredCode;
        const login = this.$store.state.enteredLogin;
        axios({
          url: "/api/twofa/",
          data: { login, code },
          method: "POST",
        })
          .then((resp) => {
            console.log("2FA ok: ", resp);
            this.loading = false;
            this.$router.push("/check-email");
          })
          .catch((err) => {
            console.log("2FA err: ", err);
            this.loading = false;
            this.invalidCode = true;
          });
      }
    },
  },
  computed: {
    enteredCode() {
      return parseInt(this.codeInputs.join(""));
    },
    isCodeValidated() {
      return this.codeInputs.join("").length === 5 ? true : false;
    },
    sourceText() {
      if (this.isSms) {
        return "смс";
      } else if (this.isEmail) {
        return "e-mail";
      }
      return "";
    },
  },
  mounted() {
    this.resendCode();
  },
};
</script>

<style lang="scss" scoped>
.login {
  height: 100vh;
  // &-inner {
  //   display: flex;
  // }
}
</style>
