<template lang="pug">
  .main-page
    // mobile menu {{{
    .mobile-menu(v-if="isMobile")
      ul
        li
          router-link(
            to="/"
            custom
            v-slot="{ href, navigate, isExactActive}"
          )
            a.mobile-menu-btn(
              :href="href"
              @click="kwNav($event, isExactActive, navigate)"
              :class="isExactActive && !anyMenuSwitcher ? 'router-link-exact-active' : ''"
            )
              .mobile-menu-btn-icon
                svg.icon.icon-view
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#view")
              span Обзор
        li
          .mobile-get-link
            button.mobile-menu-btn.mob-get-link-btn._mob-menu-switcher(
              data-mob-menu-switcher='mobMenuSwitch2'
              :class="{'_is-menu-opened': mobMenuSwitchers.mobMenuSwitch2}"
              @click="mobMenuHandler"
            )
              .mobile-menu-btn-icon
                svg.icon.icon-link
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#link")
              span Ссылка
            .mobile-get-link-menu(v-if="refMobMenu")
              .sidebar-title.mobile-title-block
                p.title-h2 Ваша реферальная ссылка

              //- mobile copy link field
              .mobile-get-link-field(:class="{'data-copied': dataCopied}")
                //- text to copy
                p.mobile-get-link-text(v-if="!dataCopied") {{ inviteLink }}
                //- link copied message
                .link-copied-message(v-else)
                  svg.icon.icon-check
                    use(xlink:href="@/assets/images/sprite/sprite-inline.svg#check")
                  span.link-copied-text Ссылка скопирована
                //- copy button
                button.button(
                  v-clipboard="inviteLink"
                  @success="handleClipboardSuccess"
                ) Скопировать ссылку
        li
          router-link(
            to="/wallet"
            custom
            v-slot="{ href, navigate, isActive, isExactActive }"
          )
            a.mobile-menu-btn(
              :href="href"
              @click="kwNav($event, isActive, navigate)"
              :class="{'router-link-exact-active': ((isActive && (href != '/')) || isExactActive) && !anyMenuSwitcher}"
            )
              .mobile-menu-btn-icon
                svg.icon.icon-wallet
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#wallet")
              span Кошелёк
        li
          router-link(
            to="/notifications"
            custom
            v-slot="{ href, navigate, isExactActive }"
          )
            a.mobile-menu-btn(
              :href="href"
              @click="kwNav($event, isExactActive, navigate)"
              :class="isExactActive && !anyMenuSwitcher ? 'router-link-exact-active' : ''"
            )
              .mobile-menu-btn-icon.notifications-icon
                svg.icon.icon-bell
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#bell")
              span Уведомления
        li
          button.mobile-menu-btn._mob-menu-switcher(
            data-mob-menu-switcher='mobMenuSwitch3'
            :class="{'_is-menu-opened': mobMenuSwitchers.mobMenuSwitch3}"
            @click="mobMenuHandler"
          )
            .mobile-menu-btn-icon
              svg.icon.icon-menu
                use(xlink:href="@/assets/images/sprite/sprite-inline.svg#menu")
            span Меню
    // }}}
    <vue-scroll>
      //- page header
      header.header(
          :class="{'_is-menu-toggled': isMenuToggled, '_is-menu-opened': isHeaderFixed}"
        )
        router-link.header-logo(to="/")
          img(src="@/assets/images/logo.svg" alt="logo")
        h1.header-title(
          v-if="!isMobile"
        ) {{currentTitle || userTitle}}

        // header nav {{{
        nav.header-nav(v-if="!isMobile")
          ul
            li          
              .header-get-link.header-nav-item(:class="{'_is-menu-opened': headerNavBtns.headerNavBtn1}")
                button.header-get-link-button.header-nav-button(
                  data-header-nav-btn='headerNavBtn1'
                  @click="userMenuHandler"
                )
                  svg.icon.icon-link
                    use(xlink:href="@/assets/images/sprite/sprite-inline.svg#link")
                  span Получить ссылку
                .header-get-link-menu.header-nav-item-menu
                  p.get-link-caption Ваша реферальная ссылка

                  //- copy link field
                  .get-link-field(:class="{'data-copied': dataCopied}")
                    //- text to copy
                    span.get-link-text-link(v-if="!dataCopied") {{ inviteLink }}
                    //- link copied message
                    .link-copied-message(v-else)
                      svg.icon.icon-check
                        use(xlink:href="@/assets/images/sprite/sprite-inline.svg#check")
                      span.link-copied-text Ссылка скопирована
                    //- copy button
                    button.get-link-btn(
                      v-clipboard="inviteLink"
                      @success="handleClipboardSuccess"
                      data-title="Скопировать ссылку"
                    )
                      svg.icon.icon-copy
                        use(xlink:href="@/assets/images/sprite/sprite-inline.svg#copy")
            li                
              router-link.header-wallet.header-nav-button(to='/wallet')
                svg.icon.icon-wallet
                  use(xlink:href="@/assets/images/sprite/sprite-inline.svg#wallet")
                span {{ preview.balance }}
            li              
              .header-notifications.header-nav-item(
                :class="{'_is-menu-opened': headerNavBtns.headerNavBtn2, 'header-notifications--unread': unread }"
              )
                button.header-notifications-button.header-nav-button(
                  data-header-nav-btn='headerNavBtn2'
                  @click="userMenuHandler"
                  :class="{'kw-disabled': (notifyArray.length <= 0)}"
                  :disabled="notifyArray.length <= 0"
                )
                  .header-notifications-button-icon
                    svg.icon.icon-bell
                      use(xlink:href="@/assets/images/sprite/sprite-inline.svg#bell")
                .header-notifications-menu.header-nav-item-menu
                  .notifications-wrapper
                    Notification(v-for="(item, index) in notifyArray" :key="index" v-bind="{index: Number(index), ...item}")
                  router-link(
                    to='/notifications'
                    class="notifications-all")
                    | Показать все
                    svg.icon.icon-arrow-right
                      use(xlink:href="@/assets/images/sprite/sprite-inline.svg#arrow-right")
                  //a.notifications-all(href="#")
                    | Показать все
                    svg.icon.icon-arrow-right
                          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#arrow-right")
            li
              .header-user.header-nav-item(:class="{'_is-menu-opened': headerNavBtns.headerNavBtn3}")
                button.header-user-profile.header-nav-button(
                  data-header-nav-btn='headerNavBtn3'
                  @click="userMenuHandler"
                )
                  .header-user-icon(v-if="user.avatar")
                    img(:src="user.avatar" :alt="user.name")
                  .header-user-icon(v-else)
                    .profile-icon
                      .profile-user-letter {{ firstUserLetter }}
                  span.header-user-name {{ user.name }}
                  svg.icon.icon-arrow-down
                    use(xlink:href="@/assets/images/sprite/sprite-inline.svg#arrow-down")
                .header-user-menu.header-nav-item-menu
                  ul
                    li
                      router-link(to='/profile')
                        span
                          svg.icon.icon-profile
                            use(xlink:href="@/assets/images/sprite/sprite-inline.svg#profile")
                        | Профиль
                      
                    
                    li
                      router-link(to='/profile/settings')
                        span
                          svg.icon.icon-settings
                            use(xlink:href="@/assets/images/sprite/sprite-inline.svg#settings")
                        | Настройки
                    
                    li
                      a(href="#" @click="logout")
                        span
                          svg.icon.icon-enter
                            use(xlink:href="@/assets/images/sprite/sprite-inline.svg#enter")
                        | Выход

        // }}}
        //- mobile user profile {{{
        .mobile-user(v-if="isMobile")
          button.mobile-user-profile._mob-menu-switcher(
            data-mob-menu-switcher='mobMenuSwitch1'
            :class="{'_is-menu-opened': userMobMenu}"
            @click="mobMenuHandler"
          )
            .mobile-user-icon(v-if="user.avatar")
                img(:src="user.avatar" :alt="user.name")
            .mobile-user-icon(v-else)
              .profile-icon
                .profile-user-letter {{ firstUserLetter }}
            span.mobile-user-name {{ user.name }}
          .mobile-user-menu(v-if="userMobMenu")
            .mobile-user-full-name.mobile-title-block
              p.title-h2 {{ user.name }}
            ul
              li
                router-link(to='/profile')
                  span
                    svg.icon.icon-profile
                      use(xlink:href="@/assets/images/sprite/sprite-inline.svg#profile")
                  | Профиль
              
              li
                router-link(to='/profile/settings')
                  span
                    svg.icon.icon-settings
                      use(xlink:href="@/assets/images/sprite/sprite-inline.svg#settings")
                  | Настройки
              
              li
                a(href="#" @click="logout")
                  span
                    svg.icon.icon-enter
                      use(xlink:href="@/assets/images/sprite/sprite-inline.svg#enter")
                  | Выход
        // }}}
      //- page content
      main.main
        //- sidebar menu
        aside.sidebar-menu(
          v-if="!isMobile || (isMobile && isSidebarOpened)"
          :class="{'_is-menu-toggled': isMenuToggled, '_is-menu-opened':isSidebarOpened}"
        )
          .sidebar-title.mobile-title-block(v-if='isMobile')
            p.title-h2 Меню
          nav.sidebar-nav
            ul
              li(
                v-for='(sidebartab, index) in sidebarTabs' 
                :key="index"
              )
                router-link.sidebar-button(
                  :to="'/' + sidebartab.path"
                  custom
                  v-slot="{ href, route, navigate, isActive, isExactActive }"
                )
                  a(
                    :class="{'router-link-exact-active': ((isActive && (href != '/')) || isExactActive)}"
                    :href="href"
                    @click="($event) => {closeMenu(); navigate($event)}"
                  )
                    span.sidebar-button-icon(:class="{ 'sidebar-button-icon-unread': sidebartab.unread}")
                      svg(:class="['icon-' + sidebartab.icon, 'icon' ]")
                        use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + sidebartab.icon")
                    span.sidebar-button-text {{sidebartab.text}}
          button.sidebar-toggle-menu-button(
            v-if='!isMobile'
            :class="{'_is-menu-toggled': isMenuToggled}"
            @click="isMenuToggled = !isMenuToggled"
          )
            svg.icon.icon-arrow-left
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#arrow-left")

        //- page component
        .main-page-inner
          //- mobile title
          .mobile-title-block(v-if='isMobile && !loader')
            h1.title-h2 {{currentTitle || userTitle}}
          .loading(v-if="loader")
            <inline-svg class="loader" :src="require('@/assets/images/page-loader.svg')"></inline-svg>
          //- displaying of the pages
          router-view(
            :style="loader ? 'visibility: hidden; position: absolute;' : ''"
            @changeTitle="changeTitle"
            @closeMenu='closeMenu'
          )
          //- social block
          Social
      //- menu mask
      .menu-mask(
        v-if="menuMaskShow"
        @click="closeMenu"
      )
      //- transition(name="popup-fade")
      //-   .popup.popup-cookies(v-if="cookiesMsg")
      //-     p.message.cookies-message Мы используем
      //-       a.link cookie
      //-       | на нашем сайте. Если вы продолжите навигацию по сайту, это значит, что вы с этим согласны.
      //-     .button.button-cookies(
      //-       @click="cookiesMsg = !cookiesMsg"
      //-     ) Принимаю
    </vue-scroll>
</template>

<script>
import { mapGetters } from "vuex";
import Notification from "/src/components/notifications/notification";
import Social from "@/components/Social.vue";

export default {
  components: {
    Social,
    Notification,
  },
  data() {
    return {
      //- publicPath: process.env.BASE_URL,
      currentTitle: null,
      dataCopied: false,
      currentComponent: "Profile",
      isMenuToggled: false,
      menuMaskShow: false,
      // copyData: "https://opn.group/erp/register/q2456ds",
      isMobile: false,
      name: "Ростислав",
      fullName: "Ростислав Артемьевских",
      bodyHidden: false,
      isHeaderFixed: false,
      isSidebarOpened: false,
      mobMenuSwitchers: {},
      headerNavBtns: {},
      cookiesMsg: true,
    };
  },
  computed: {
    ...mapGetters(["user", "loader"]),
    ...mapGetters({ userTitle: "title" }),
    ...mapGetters("notifications", ["notifyArray", "unread"]),
    ...mapGetters("wallet", ["preview"]),
    ...mapGetters("news", { newsUnread: "unread" }),
    ...mapGetters("chatlist", { supportUnread: "unread" }),
    refMobMenu() {
      return this.mobMenuSwitchers?.mobMenuSwitch2;
    },
    anyMenuSwitcher() {
      for (const [key, val] of Object.entries(this.mobMenuSwitchers)) {
        if (val) {
          return true;
        }
      }
      return false;
    },
    mobMenu() {
      return this.mobMenuSwitchers?.mobMenuSwitch3;
    },
    userMobMenu() {
      return this.mobMenuSwitchers?.mobMenuSwitch1;
    },
    inviteLink() {
      return this.user.invite?.link || "";
    },
    firstUserLetter() {
      const name = this.user.name;
      return name ? name.slice(0, 1).toUpperCase() : "";
    },
    sidebarTabs() {
      const tabs = [
        {
          text: "Обзор",
          icon: "view",
          component: "View",
          path: "",
        },
        {
          text: "Моя структура",
          icon: "structure",
          component: "Structure",
          path: "structure",
        },
        {
          text: "Программы",
          icon: "cube",
          component: "Programs",
          path: "programs",
          additClass: "programs-link",
        },
        {
          text: "Сделки",
          icon: "bag",
          component: "Deals",
          path: "deals",
        },
        {
          text: "Статистика",
          icon: "statistics",
          component: "Statistics",
          path: "statistics",
        },
        {
          text: "Кошелёк",
          icon: "wallet",
          component: "Wallet",
          path: "wallet",
          additClass: "wallet-link",
        },
        {
          text: "Промо",
          icon: "loudspeaker",
          component: "Promo",
          path: "promo",
        },
        {
          text: "Лента",
          icon: "feed",
          component: "News",
          unread: this.newsUnread,
          path: "news",
        },
        {
          text: "Рейтинги",
          icon: "rating",
          component: "Rating",
          path: "rating",
        },
        {
          text: "Достижения",
          icon: "achievement",
          component: "Achievements",
          path: "achievements",
        },
        {
          text: "Поддержка",
          icon: "support",
          component: "Support",
          unread: this.supportUnread,
          path: "support",
          additClass: "support-link",
        },
      ];
      return tabs;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    handleClipboardSuccess() {
      this.dataCopied = true;
      setTimeout(() => {
        this.dataCopied = false;
      }, 3000);
    },
    sidebarButtonHandler() {
      if (this.isMobile) {
        this.bodyHidden = false;
        this.isHeaderFixed = false;
        this.isSidebarOpened = false;
      }
    },
    kwNav(e, isExactActive, navigate) {
      this.closeMenu();
      this.mobMenuSwitchers = {};
      this.bodyHidden = false;
      this.isHeaderFixed = false;
      this.isSidebarOpened = false;
      navigate(e);
    },
    userMenuHandler(event) {
      this.$store.dispatch("notifications/read");

      let id = event.currentTarget.dataset.headerNavBtn;
      this.menuMaskShow = !this.menuMaskShow;
      if (this.headerNavBtns[id]) {
        this.headerNavBtns = {};
        this.bodyHidden = false;
        this.isHeaderFixed = false;
        this.isSidebarOpened = false;
      } else {
        this.headerNavBtns = {};
        this.bodyHidden = true;
        // this.isHeaderFixed = true;
        this.isSidebarOpened = false;
        this.headerNavBtns[id] = true;
      }
    },
    mobMenuHandler(event) {
      let id = event.currentTarget.dataset.mobMenuSwitcher;
      if (this.mobMenuSwitchers[id]) {
        this.mobMenuSwitchers = {};
        this.bodyHidden = false;
        this.isHeaderFixed = false;
        this.isSidebarOpened = false;
      } else {
        this.mobMenuSwitchers = {};
        this.bodyHidden = true;
        this.isHeaderFixed = true;
        this.mobMenuSwitchers[id] = true;
        this.isSidebarOpened = false;
        if (id === "mobMenuSwitch3") {
          this.isSidebarOpened = true;
        }
      }
    },
    closeMenu() {
      this.bodyHidden = false;
      this.headerNavBtns = {};
      this.menuMaskShow = false;
      if (this.isMobile) {
        this.isHeaderFixed = false;
        this.isSidebarOpened = false;
        this.mobMenuSwitchers = {};
      }
    },
    onResize() {
      if (window.matchMedia("(max-width: 991px)").matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    mobMenuComponentBtnHandler() {
      if (this.bodyHidden) {
        this.bodyHidden = false;
        this.isHeaderFixed = false;
        this.isSidebarOpened = false;
        this.mobMenuSwitchers = {};
      }
    },
    changeTitle(title) {
      this.currentTitle = title;
    },
  },
  created() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  mounted() {
    // this.$store.commit("loaderOn");
  },
  watch: {
    bodyHidden(newVal) {
      let className = "hidden";
      if (newVal) {
        document.body.classList.add(className);
      } else {
        document.body.classList.remove(className);
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="scss" scoped>
.main-page {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.main {
  flex-grow: 1;
}

.cookies-fade-enter-active,
.cookies-fade-leave-active {
  transition: all 0.4s ease;
}

.cookies-fade-enter,
.cookies-fade-leave-to {
  opacity: 0;
}
.profile-icon {
  width: 100%;
  height: 100%;
}
.profile-user-letter {
  font-size: inherit;
  margin-top: 0;
}
.kw-disabled {
  cursor: not-allowed;
}
</style>
