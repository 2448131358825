<template lang="pug">
    .profile-edit
        .profile-edit-wrapper 
            .profile-edit-head
                .profile-edit-title
                    .profile-edit-title-icon
                        svg.icon.icon-profile
                            use(xlink:href="@/assets/images/sprite/sprite-inline.svg#profile")
                    span Адрес
                <profile-back-btn/>
            .profile-progressbar-wrapper.profile-progressbar-wrapper--address
              .profile-progressbar
                .progressbar-current._progress-full
                .progressbar-body
                    .progressbar-step-item._finished
                        .progressbar-circle 1
                        span Адрес проживания
                    .progressbar-step-item._current
                        .progressbar-circle 2
                        span Загрузка документа
            .profile-personality-form-block
              form.profile-personality-form
                .form-documents
                  kw-upload(
                    v-model="file",
                    icon="residence",
                    title="Документ подтверждающий проживание",
                    :min="Number(100 * 1000)"
                  )

            .profile-document-requirements
              ul
                li
                  .profile-document-icon
                    svg.icon.icon-user
                      use(xlink:href="@/assets/images/sprite/sprite-inline.svg#user")
                  span Сканируйте либо фотографируйте только оригиналы документов
                li
                  .profile-document-icon
                    svg.icon.icon-capture
                      use(xlink:href="@/assets/images/sprite/sprite-inline.svg#capture")
                  span Все 4 угла документа должны быть видны, края не должны быть обрезаны
                li
                  .profile-document-icon
                    svg.icon.icon-picture
                      use(xlink:href="@/assets/images/sprite/sprite-inline.svg#picture")
                  span Допустимые форматы: JPG, PDF, PNG
                li
                  .profile-document-icon
                    svg.icon.icon-picture
                      use(xlink:href="@/assets/images/sprite/sprite-inline.svg#picture")
                  span Допустимый размер: 100 KB - 8 MB
                      
        .profile-edit-btn-wrapper
          button.button.profile-edit-save-btn(
              @click="save"
              )
            svg.icon.icon-check
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#check")
            | Сохранить
            


</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";
import KwUpload from "/src/components/profile/KwUpload";
const STORE = "personEdit";

export default {
  components: {
    KwUpload,
  },
  data() {
    return {
      file: "",
    };
  },
  methods: {
    validModelData(v) {
      const valid = v instanceof File;
      return valid;
    },
    sendPassport() {
      const formData = new FormData();
      if (!this.validModelData(this.file)) {
        if (isLogLevel(LOG_LVL_LIST.INFO)) {
          console.log(
            `[ ${LOG_LVL_LIST.INFO} ]	`,
            "UPLOAD PASSPORT: ",
            "Нет валидных файлов для отправки"
          );
        }
        this.$router.push(`/address-edit-success`);
      }

      formData.set("resident", this.file);
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(
          `[ ${LOG_LVL_LIST.DEBUG} ]	`,
          formData.entries().next().value
        );
      }
      this.$store
        .dispatch(`${STORE}/upload`, formData)
        .then(() => {
          this.$router.push(`/address-edit-success`);
        })
        .catch(() => {});
      //Adding files to the formdata
    },
    save() {
      const data = { address: this.tmpAddress };
      this.$store.dispatch("setMetaItem", data).finally(this.sendPassport);
    },
  },
  computed: {
    ...mapGetters(STORE, { tmpAddress: "address" }),
    ...mapGetters(STORE, ["passport"]),
  },
  created() {
    this.$store
      .dispatch(`${STORE}/getPassport`)
      .then(() => {
        this.file = this.passport?.files?.resident || "";
      })
      .finally(() => {
        this.$store.commit("loaderOff");
      });
  },
};
</script>

<style lang="scss" scoped>
.form-document-item {
  width: 100%;
}
</style>
