<template lang="pug">
  
  form.registration-form.form(@submit.prevent)

    .form-fields-wrapper
      //- name field
      .form-field.form-field-col-2(
        :class="{validated: isNameFieldValidated, focused: ( nameFieldFocused || nameField)}"
        )
        label.form-label() Имя
        input.form-input(
          v-model="nameField"
          type="text"
          name="name"
          @focus="nameFieldFocused = true"
          @blur="onBlurInputHandler"
        )

      //- surname field
      .form-field.form-field-col-2(
        :class="{validated: isSurnameFieldValidated, focused: ( surnameFieldFocused || surnameField)}"
        )
        label.form-label() Фамилия
        input.form-input(
          v-model="surnameField"
          type="text"
          name="surname"
          @focus="surnameFieldFocused = true"
          @blur="onBlurInputHandler"
          )


    //- email field
    .form-field(:class="{validated: isEmailFieldValidated, focused: ( emailFieldFocused || emailField), invalid: invalidEmail}")
      label.form-label() E-mail
      input.form-input(
        v-model="emailField"
        type="email"
        name="email"
        @focus="emailFieldFocused = true"
        @blur="onBlurInputHandler"
        @input="emailInputHandler"
      )
      
      //- email error message
      <input-error :showVar="invalidEmail">
        <p>Не корректный e-mail</p>
      </input-error>
      

    //- phone field
    .form-field(:class="{validated: isPhoneFieldValidated, focused: ( phoneFieldFocused || phoneField ), invalid: invalidPhone}")
      label.form-label() Телефон
      imask-input.form-input(
        name="phone"
        type="tel"
        :unmask="true"
        v-model:typed="phoneField"
        mask="+{7}(000)000-00-00"
        :prepare="prepareMask"
        @focus="phoneFieldFocused = true"
        @input="phoneInputHandler"
      )

      
      //- email error message
      <input-error :showVar="invalidPhone">
        <p>Не корректный телефон</p>
      </input-error>


    //- password field
    .form-field(
      :class="{validated: isPwdFieldValidated, focused: ( pwdFieldFocused || pwdField), 'form-field-password': isPwdFieldValidated}"
      )
      label.form-label() Введите пароль
      input.form-input.form-input-pwd(
        v-model="pwdField"
        type="password"
        name="password"
        @focus="pwdFieldFocused = true"
        @blur="onBlurInputHandler"
        @input="passwordInputHandler"
      )
      .form-btn-eye(
        @click='changeInputType'
        )
        svg.icon.icon-eye-close(v-if="isPwdHide")
          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#eye-close")
        svg.icon.icon-eye-open(v-else)
          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#eye-open")
    //- password level
    .pwd-level(
      v-if="isPwdFieldValidated"
      :class="{'pwd-level--weak': pwdTypes.lowPwd, 'pwd-level--middle': pwdTypes.middlePwd, 'pwd-level--strong': pwdTypes.highPwd}"
      )
      .pwd-level-line
      .pwd-level-text(
        v-if="pwdTypes.lowPwd"
        ) Слабый пароль
      .pwd-level-text(
        v-else-if="pwdTypes.middlePwd"
        ) Средний пароль
      .pwd-level-text(
        v-else-if="pwdTypes.highPwd"
        ) Сильный пароль


    //- policy
    .form-actions
      .form-checkbox-field.form-policy-agree
        input(
          type="checkbox" 
          id="policy-check"
          name="privacy"
          v-model="isPolicyChecked"
          )
        label(for="policy-check").registration-label
          span Я принимаю условия   
            router-link.link(to="/agreement" target="_blank") политики конфиденциальности
        
    //- register button
    button.button.button-register(
      :class="{'button-disabled': errors, 'button-loading': loading}"
      :disabled="errors || loading"
      @click="regHandler"
      )
      <inline-svg v-if="loading" class="loader" :src="require('@/assets/images/sprite/loader.svg')"></inline-svg>
      span.button-content(v-else)
        svg.icon.icon-add
          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#add")
        | Зарегистрироваться
    
</template>

<script>
import validateEmailBool from "@/mixins/validateEmailBool";
import changeInputType from "@/mixins/changeInputType";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";
import { IMaskComponent } from "vue-imask";

export default {
  mixins: [validateEmailBool, changeInputType],
  components: {
    "imask-input": IMaskComponent,
  },
  data() {
    return {
      nameField: "",
      surnameField: "",
      emailField: "",
      phoneField: "",
      pwdField: "",

      nameFieldFocused: false,
      surnameFieldFocused: false,
      emailFieldFocused: false,
      phoneFieldFocused: false,
      pwdFieldFocused: false,

      isNameFieldValidated: false,
      isSurnameFieldValidated: false,
      isEmailFieldValidated: false,
      isPhoneFieldValidated: false,
      isPwdFieldValidated: false,
      isPolicyChecked: false,

      invalidEmail: false,
      invalidPhone: false,

      loading: false,

      // password regular expressions
      lowPwdRegExp: /(?=.*[a-z])(?=.*[A-Z])[a-zA-Z]{8,36}/g,
      middlePwdRegExp: /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,36}/g,
      highPwdRegExp:
        /(?=.*[0-9])(?=.*[\W])(?=.*[a-z])(?=.*[A-Z])[0-9\Wa-zA-Z]{8,36}/g,
      pwdTypes: {
        lowPwd: false,
        middlePwd: false,
        highPwd: false,
      },
    };
  },
  methods: {
    prepareMask(appended, masked) {
      if (appended === "8" && masked.value === "") {
        return "";
      }
      return appended;
    },
    onBlurInputHandler() {
      // name field
      if (this.nameField === "") {
        this.nameFieldFocused = false;
      }

      this.isNameFieldValidated = this.simpleInputValidation(this.nameField);

      // surname field
      if (this.surnameField === "") {
        this.surnameFieldFocused = false;
      }
      this.isSurnameFieldValidated = this.simpleInputValidation(
        this.surnameField
      );

      // email field
      if (this.emailField === "") {
        this.emailFieldFocused = false;
      }
      if (this.validateEmailBool(this.emailField)) {
        this.isEmailFieldValidated = true;
        this.invalidEmail = false;
      } else if (
        !this.validateEmailBool(this.emailField) &&
        this.emailField === ""
      ) {
        this.isEmailFieldValidated = false;
        this.invalidEmail = false;
      } else {
        this.isEmailFieldValidated = false;
        this.invalidEmail = true;
      }

      // phone field
      if (this.phoneField === "") {
        this.phoneFieldFocused = false;
      }
      if (this.phoneField.replace(/\D/g, "").length === 11) {
        this.isPhoneFieldValidated = true;
        this.invalidPhone = false;
      } else if (this.phoneField === "") {
        this.isPhoneFieldValidated = false;
        this.invalidPhone = false;
      } else {
        this.isPhoneFieldValidated = false;
        this.invalidPhone = true;
      }

      // password field
      if (this.pwdField === "") {
        this.pwdFieldFocused = false;
      }
      // this.passwordInputHandler();
    },
    simpleInputValidation(value) {
      if (
        value.length >= 2 &&
        value.length <= 20 &&
        !value.match(/[^a-zа-яё]/iu)
      ) {
        return true;
      }
      return false;
    },
    emailInputHandler() {
      this.invalidEmail = false;
      this.isEmailFieldValidated = false;
    },
    phoneInputHandler() {
      this.invalidPhone = false;
      // this.phoneField = this.replaceNumberForInput(this.phoneField);
    },
    passwordInputHandler() {
      if (
        this.pwdField.length === 0 ||
        this.pwdField.length < 8 ||
        this.pwdField.length > 36
      ) {
        this.isPwdFieldValidated = false;
        for (let pwdType in this.pwdTypes) {
          this.pwdTypes[pwdType] = false;
        }
      } else {
        this.isPwdFieldValidated = true;
        for (let pwdType in this.pwdTypes) {
          this.pwdTypes[pwdType] = false;
        }
        if (this.pwdField.match(this.highPwdRegExp)) {
          this.pwdTypes.highPwd = true;
        } else if (
          this.pwdField.match(this.middlePwdRegExp) ||
          this.pwdField.match(
            /(?=.*[\W])(?=.*[a-z])(?=.*[A-Z])[\Wa-zA-Z]{8,36}/g
          )
        ) {
          this.pwdTypes.middlePwd = true;
        } else if (
          this.pwdField.match(this.lowPwdRegExp) ||
          this.pwdField.match(/(?=.*[a-z])[a-z]{8,36}/g) ||
          this.pwdField.match(/(?=.*[A-Z])[A-Z]{8,36}/g) ||
          this.pwdField.match(/(?=.*[0-9])[0-9]{8,36}/g)
        ) {
          this.pwdTypes.lowPwd = true;
        } else {
          this.pwdTypes.middlePwd = true;
        }
      }
    },
    regHandler() {
      if (!this.errors) {
        this.loading = true;
        this.$store
          .dispatch("register", {
            name: this.nameField,
            surname: this.surnameField,
            email: this.emailField,
            phone: this.phoneField,
            password: this.pwdField,
            policy: this.isPolicyChecked,
            referrer: localStorage.getItem("referrerFirst"),
          })
          .then(() => {
            this.loading = false;
            this.$router.push("/registration-form-code");
          })
          .catch((err) => {
            this.loading = false;
            if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
              console.log(
                `[ ${LOG_LVL_LIST.DEBUG} ]	`,
                "submit error",
                err?.response?.data
              );
            }
            const data = err?.response?.data;
            if (data) {
              switch (data.status) {
                case 404:
                  break;
                case 403:
                  if (data.statusText.includes("Пользователь с таким email")) {
                    this.invalidEmail = true;
                  }
                  if (
                    data.statusText.includes("Пользователь с номером телефона")
                  ) {
                    this.invalidPhone = true;
                  }
                  break;
              }
            } else {
            }
          });
      }
    },
  },
  computed: {
    errors() {
      return !this.isNameFieldValidated ||
        !this.isSurnameFieldValidated ||
        !this.isPhoneFieldValidated ||
        !this.isPwdFieldValidated ||
        !this.isPolicyChecked
        ? true
        : false;
    },
  },
};
</script>

<style lang="scss"></style>
