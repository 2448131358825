// export function chatlistItem(state) {
//   return (index) => {
//     if (
//       index != -1 &&
//       state.array.hasOwnProperty(index) &&
//       state.array[index].hasOwnProperty("id")
//     ) {
//       return state.array[index];
//     } else {
//       return null;
//     }
//   };
// }

// export function unread(state) {
//   for (const item of state.array) {
//     if (item.isread == false) {
//       return true;
//     }
//   }
// }

export function chat(state) {
  return Array.isArray(state?.array) ? state.array : [];
}
