// import Vue from "vue";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export function FETCH_ALL(state, data) {
  if (Array.isArray(data)) {
    state.array = data;
  } else if (data) {
    state.array = [];
    state.array.push(data);
  } else {
    state.array = [];
  }
}

export function start(state, index) {
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(
      `[ ${LOG_LVL_LIST.DEBUG} ]	`,
      "start",
      index,
      state.array[index]
    );
  }
  if (state.array.hasOwnProperty(index)) {
    state.array[index].isstart = true;
  }
}
