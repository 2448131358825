export function title(state) {
  return state.title;
}
export function subtitle(state) {
  return state.subtitle;
}
// export function htmlText(state) {
//   return state.htmlText;
// }

export function list(state) {
  return Array.isArray(state?.list) ? state.list : [];
}

export function html(state) {
  return state.html;
}
