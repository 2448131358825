<template lang="pug">
    .balance
      //- balance cards  {
      .balance-cards
        .balance-card-item(
          v-for='(card, index) in balanceCards'
          :key='index'
          )
          span.balance-card-title {{ card.title }}
          span.balance-card-sum {{ card.sum }}
          svg(
            :class="['icon-' + card.icon, 'icon' ]"
            )
            use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + card.icon")
        // }
      //{{{
      //- balance content
      .balance-content
        .balance-body(v-if="empty")
          .balance-empty
            img(src='@/assets/images/balance.png' alt='баланс')
            p Здесь появится ваша история баланса
        .balance-body(v-else)
          //- filters {
          .balance-filters
            form.balance-form
              //- users select
              select.balance-form-select.form-select.filter-item(v-model="selectedUser" @change="fetch(0)")
                option(value="all") Все пользователи
                option(v-for="user in userList" :key="user.id" :value="user.id") {{ user.title }}

              select.balance-form-select.form-select.filter-item(v-model="selectedProductType" @change="fetch(0)")
                option(value="all") Все продукты
                option(v-for="product in productList" :key="product.id" :value="product.id") {{ product.title }}

              .balance-form-calendar.form-calendar.filter-item
                date-picker(
                  @change="fetch(0)"
                  v-model="time"
                  @clear="timeClear"
                  value-type="YYYY-MM-DD"
                  format="D MMM YYYY"
                  type="date"
                  :lang="lang"
                  :editable="false"
                  title-format="DD-MM-YYYY"
                  range
                  range-separator=" - "
                  :clearable="true"
                  :class="{'no-date': !time}"
                  placeholder="Выберите период"
                )
                  svg.icon.icon-calendar(slot="icon-calendar")
                    use(xlink:href="@/assets/images/sprite/sprite-inline.svg#calendar")

              .balance-form-levels.filter-item.select-levels(data-state="")
                .select-level.select-level--all(@click="selectMenuHandler")
                  input(
                    type='checkbox'
                    id='levelAll'
                    v-model="allLevels"
                    disabled
                  )
                  label(for='levelAll') Все уровни
                  svg.icon.icon-arrow-down
                    use(xlink:href="@/assets/images/sprite/sprite-inline.svg#arrow-down")
                ul.select-input-list
                  li.select-level(v-for="(level, i) in levels" :key="level.id")
                    input(
                      @change="fetch(0)"
                      type='checkbox'
                      :id="'level' + level.level"
                      v-model="level.checked"
                    )
                    label(:for="'level' + level.level") Уровень {{ level.level }}
          // }
          //- .balance-list {
          transition-group(name="balance-list" tag="div" class="balance-list")
            .balance-item(v-for='(item, index) in transactions' :key='item.id')
              .balance-item-icon
                svg(:class="['icon-' + item.icon, 'icon' ]")
                  use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + item.icon")
              .balance-item-content
                p.balance-item-title {{ item.title }}
                .balance-item-desc
                  span Уровень {{ item.level }}
                  span {{ item.product }}
                  span {{ item.user }}
              span.balance-item-sum(:class="{debet: item.isDebet}") {{ item.isDebet ? '+' : '-' }} {{ balanceFormat(item.sum) }} ₽
          //}
        button.show-more-btn.button(
          v-if="showMoreTransactions"
          :class="{'button-disabled': loadingResend, 'button-loading': loadingResend}"
          :disabled="loadingResend"
          @click="more"
        )
          inline-svg(
            v-if="loadingResend"
            class="loader"
            :src="require('@/assets/images/sprite/loader.svg')"
          )
          span(v-else)
            svg.icon.icon-plus
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#plus")
            | Показать ещё
      
        //}}}
      //- menu mask
      .menu-mask(v-if="menuMaskShow" @click="closeSelectsMenu")

</template>

<script>
import Utils from "/src/helper.js";
import { mapGetters } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ru";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

const STORE = "wallet";

export default {
  components: { DatePicker },
  data() {
    return {
      page: 1,
      loadingResend: false,
      balanceItemsLimit: 5,
      selectedProductType: "all",
      selectedUser: "all",
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
      time: null,
      menuMaskShow: false,
      allLevels: true,
      levels: [],
    };
  },
  computed: {
    ...mapGetters(STORE, [
      "empty",
      "preview",
      "levelList",
      "userList",
      "productList",
      "transactions",
      "showMoreTransactions",
    ]),
    levelListSelected() {
      const levelArray = [];
      this.levels.forEach((level) => {
        if (level.checked) {
          levelArray.push(level.id);
        }
      });
      if (levelArray.length == 0 || levelArray.length == this.levels.length) {
        this.allLevels = true;
        return "all";
      } else {
        this.allLevels = false;
        return levelArray;
      }
    },
    balanceCards() {
      return [
        {
          title: "Баланс",
          sum: this.preview.balance,
          icon: "wallet",
        },
        {
          title: "Заморожено",
          sum: this.preview.frozen,
          icon: "lock",
        },
        {
          title: "Прогноз",
          sum: this.preview.forecast,
          icon: "graph",
        },
      ];
    },
  },
  methods: {
    timeClear() {
      this.time = null;
    },
    more() {
      this.page += 1;
      this.fetch(this.page, true);
    },
    fetch(page = 1, more = false) {
      this.page = page;
      this.loadingResend = true;
      setTimeout(() => {
        this.$store
          .dispatch(`${STORE}/filter`, {
            key: "transactions",
            more,
            page,
            product: this.selectedProductType,
            level: this.levelListSelected,
            user: this.selectedUser,
            range: this.time || "all",
          })
          .then(() => {})
          .catch(() => {})
          .finally(() => {
            this.loadingResend = false;
          });
      }, 0);
    },
    balanceFormat(n) {
      return Utils.balanceFormat(n);
    },
    selectMenuHandler(event) {
      let select = event.currentTarget.closest(".select-levels");
      if (select.dataset.state === "active") {
        select.dataset.state = "";
        this.menuMaskShow = false;
      } else {
        select.dataset.state = "active";
        this.menuMaskShow = true;
      }
    },
    closeSelectMenu(event) {
      event.currentTarget.closest(".select-levels").dataset.state = "";
      this.menuMaskShow = false;
    },
    closeSelectsMenu() {
      let selects = document.querySelectorAll(".select-levels");
      selects.forEach((select) => {
        select.dataset.state = "";
      });
      this.menuMaskShow = false;
    },
    updateLevelList() {
      this.levelList.forEach((item, index) => {
        if (!this.levels.hasOwnProperty(index)) {
          this.levels.push({ id: item.id, level: item.level, checked: true });
        }
      });
    },
  },
  watch: {
    levelList() {
      this.updateLevelList();
    },
  },
  created() {
    this.$store
      .dispatch("wallet/fetch")
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(`[ ${LOG_LVL_LIST.ERROR} ]	`, STORE, "fetch", err);
        }
      })
      .finally(() => {
        this.$store.commit("loaderOff");
      });
  },
  mounted() {
    this.updateLevelList();
  },
};
</script>

<style lang="scss">
.balance-list-item {
  display: inline-block;
  margin-right: 10px;
}
.balance-list-enter-active,
.balance-list-leave-active {
  transition: all 0.4s ease;
}
.balance-list-enter-from,
.balance-list-leave-to {
  opacity: 0;
  transform: translateX(130px);
}
.balance-list-move {
  transition: transform 0.4s ease;
}
</style>
