<template lang="pug">
  .settings-block-inner
    .settings-block
      .settings-block-header
        .settings-block-head
          .settings-block-head-icon
            svg(class="icon icon-mobile")
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#mobile")
          p Мобильный номер
        <settings-back-btn/>
      form.form.settings-form(@submit.prevent)
        .settings-form-inner
          .form-field.kw-error-fix(
            :class="{focused: ( phoneFieldFocused || value), invalid: !valid}"
          )
            label.form-label Мобильный номер
            input.form-input(
              v-model="value"
              type="text"
              name="phone"
              @focus="phoneFieldFocused = true"
              @blur="onBlurInputHandler"
              @input="phoneInputHandler"
              @paste="onPhoneFieldPaste"
            )
            input-error(:showVar="!valid")
              p {{ errmsg || "Ошибка, номер не изменен"}}
        button.settings-form-btn(
          :class="{'button-disabled': !valid}"
          @click="save"
        )
          svg.icon.icon-check
            use(xlink:href="@/assets/images/sprite/sprite-inline.svg#check")
          | Сохранить



</template>

<script>
import { mapGetters } from "vuex";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export default {
  data() {
    return {
      value: "",
      errmsg: "",
      valid: false,
      phoneFieldFocused: true,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  watch: {
    user: function () {
      this.syncUserData();
    },
  },
  methods: {
    syncUserData() {
      this.value = this.user.phone;
      this.phoneInputHandler();
    },
    save() {
      if (this.value === this.user.phone) {
        console.log(
          `[ ${LOG_LVL_LIST.DEBUG} ]	`,
          "phone is not new, request is not send",
          this.value
        );
        this.$router.push(`/profile`);
        return;
      }
      const data = { phone: this.value };
      this.$store
        .dispatch("setMetaItem", data)
        .then(() => this.$router.push(`/profile`))
        .catch((err) => {
          this.errmsg = err;
          this.valid = false;
        });
    },
    onBlurInputHandler() {
      if (this.value === "") {
        this.phoneFieldFocused = false;
      }
    },
    phoneInputHandler() {
      this.value = this.replaceNumberForInput(this.value);
      const phoneValue = this.value.replace(/\D/g, "").substr(0, 11);
      if (phoneValue.length === 11) {
        this.valid = true;
      }
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(
          `[ ${LOG_LVL_LIST.DEBUG} ]	`,
          "validate",
          this.value,
          phoneValue.length
        );
      }
    },
    onPhoneFieldPaste(event) {
      let pastedValue = event.clipboardData.getData("text");
      setTimeout(() => {
        this.value = this.replaceNumberForPaste(pastedValue);
      });
    },
    replaceNumberForPaste(value) {
      const r = value.replace(/\D/g, "");
      let val = r;
      if (val.charAt(0) !== "7") {
        if (val.length >= 11) {
          val = "7" + val.slice(1, 11);
        } else if (val.length < 11) {
          val = "7" + val;
        }
      }
      return this.replaceNumberForInput(val);
    },
    replaceNumberForInput(value) {
      let val = "";
      const x = value
        .replace(/\D/g, "")
        .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

      if (!x[1]) {
        x[1] = 7;
        val = "+" + x[1] + " ";
      } else if (!x[2] && x[1] !== "") {
        val = x[1] === "7" ? "+" + x[1] + " " : "+7 " + x[1];
      } else {
        val = !x[3]
          ? "+" + x[1] + " " + x[2]
          : "+" +
            x[1] +
            " " +
            x[2] +
            "-" +
            x[3] +
            (x[4] ? "-" + x[4] : "") +
            (x[5] ? "-" + x[5] : "");
      }
      return val;
    },
  },
  created() {
    this.$store.commit("loaderOff");
  },
  mounted() {
    this.syncUserData();
  },
};
</script>

<style lang="scss">
.form-field.kw-error-fix {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > .error-message {
    position: relative;
    left: 0;
    width: 100%;
    margin-top: 1rem;
  }
}
</style>
