export default {
  data() {
    return {};
  },
  methods: {
    validateEmailBool(value) {
      let validRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (value.trim().match(validRegex)) {
        return true;
      }
      return false;
    },
  },
};
