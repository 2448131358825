import axios from "axios";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";
const API_URL = "/api/stat/";

export function getByKey({ commit, state }, { key, filters = {} }) {
  if (!state.keys.includes(key)) {
    return;
  }
  const data = { action: "get", key, filters };
  const method = "POST";
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, method, API_URL, data);
  }
  return axios({ method, url: API_URL, data })
    .then((resp) => {
      const output = resp.data;
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(
          `[ ${LOG_LVL_LIST.DEBUG} ]	`,
          API_URL,
          method,
          "SUCCESS",
          output
        );
      }
      commit("SET_ARRAY_BY_KEY", { key, data: output });
    })
    .catch((err) => {
      if (isLogLevel(LOG_LVL_LIST.ERROR)) {
        console.log(
          `[ ${LOG_LVL_LIST.ERROR} ]	`,
          API_URL,
          err,
          method,
          "RESPONSE",
          err.response
        );
      }
      return Promise.reject();
    });
}
