import Vue from "vue";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export function FETCH_ALL(state, data) {
  if (data && Array.isArray(data)) {
    state.array = data;
  } else if (data) {
    state.array = [];
    state.array.push(data);
  } else {
    state.array = [];
  }
}

export function addMe(state, msg) {
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "addMe", msg);
  }
  if (msg.hasOwnProperty("msg")) {
    state.array.push(msg);
  } else if (typeof msg === "string") {
    if (msg.length == 0) {
      return;
    }
    const data = { me: true, date: new Date().toISOString(), msg };
    state.array.push(data);
  }
}

export function SOCKET_ADD(state, data) {
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "SOCKET ADD", data);
  }

  if (!Array.isArray(state.array)) {
    state.array = [];
  }
  if (data?.value) {
    state.array.push(data.value);
  }
}
