import Utils from "/src/helper.js";

export function newsArray(state) {
  return Array.isArray(state?.news) ? state.news : [];
}

export function last(state) {
  return state.news[state.news.length - 1];
}

export function getItem(state) {
  return (index) => {
    if (
      state.news.hasOwnProperty(index) &&
      state.news[index].hasOwnProperty("id")
    ) {
      return state.news[index];
    } else {
      return null;
    }
  };
}

export function allIdArray(state) {
  return state.allIdArray;
}

export function unread(state) {
  for (const item of state.news) {
    if (item.isread == false) {
      return true;
    }
  }
}

export function showMore(state) {
  return state.news.length > 0 && state.stuffAmount > state.news.length;
}
