<template lang="pug">
  .profile-settings
    .profile-block(
      v-for='(item, index) in blocks'
      :key="index"
    )
      .profile-block-head(@click="openBlock")
        .profile-block-head-desc
          .profile-block-head-icon
            svg(class="icon" :class="`icon-` + item.icon")
              use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + item.icon")
          span {{ item.title }}
        svg(class="icon icon-arrow-down")
          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#arrow-down")
      .profile-block-body
        .profile-settings-text {{ item.text || "—"}}
        .profile-block-edit
          .profile-block-edit-btn(
            @click="$router.push(`/${item.path}`)"
          )
            svg(class="icon icon-pen")
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#pen")
            | Редактировать

    .profile-block
      .profile-block-head(@click="openBlock")
        .profile-block-head-desc
          .profile-block-head-icon
            svg(class="icon icon-bell")
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#bell")
          span Уведомления
        svg(class="icon icon-arrow-down")
          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#arrow-down")
      .profile-block-body
        .profile-settings-switch
          .profile-settings-text Получать уведомления на электронный адрес
          .profile-settings-switch-btn
            input(
              type="checkbox" 
              id="switch"
              name="send"
              v-model="notification"
              @change="notificationToggle"
            )
            label(for="switch") Toggle

</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";
export default {
  name: "Settings",
  components: {},
  data() {
    return {
      notification: false,
    };
  },
  methods: {
    openBlock(e) {
      let parent = e.currentTarget.parentNode;
      parent.classList.toggle("active");
    },
    notificationToggle() {
      const newValue = !this.notificationEnabled;
      const data = { notification: newValue };
      const url = "/api/personalmeta/";
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "POST: ", url, data);
      }
      axios({ method: "POST", url, data })
        .then((resp) => {
          if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
            console.log(
              `[ ${LOG_LVL_LIST.DEBUG} ]	`,
              url,
              "POST SUCCESS: ",
              resp
            );
          }
          this.$store.commit("notifications/enable", newValue);
        })
        .catch((err) => {
          if (isLogLevel(LOG_LVL_LIST.ERROR)) {
            console.log(
              `[ ${LOG_LVL_LIST.ERROR} ]	`,
              url,
              err,
              "POST RESPONSE: ",
              err.response
            );
          }
          setTimeout(() => (this.notification = this.notificationEnabled), 500);
        });
    },
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("notifications", { notificationEnabled: "enabled" }),
    blocks() {
      return [
        {
          icon: "envelope",
          title: "Электронный адрес",
          text: this.user.email,
          path: "settings-email",
        },
        {
          icon: "mobile",
          title: "Мобильный номер",
          text: this.user.phone,
          path: "settings-phone",
        },
        {
          icon: "lock",
          title: "Пароль",
          text: "••••••••••",
          path: "settings-password",
        },
      ];
    },
  },
  watch: {
    notificationEnabled() {
      this.notification = this.notificationEnabled;
    },
  },
  mounted() {
    this.$emit("closeMenu");
    this.notification = this.notificationEnabled;
  },
  created() {
    this.$store
      .dispatch("notifications/fetch")
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(`[ ${LOG_LVL_LIST.ERROR} ]	`, STORE, "fetch", err);
        }
      })
      .finally(() => {
        this.$store.commit("loaderOff");
      });
  },
};
</script>
