export default {
  data() {
    return {
      isPwdHide: true,
    };
  },
  methods: {
    changeInputType(event) {
      let input = event.currentTarget.previousElementSibling;
      if (input.getAttribute('type') === 'password') {
        input.setAttribute('type', 'text');
        input.focus();
      } else {
        input.setAttribute('type', 'password');
        input.focus();
      }
      this.isPwdHide = !this.isPwdHide;
    },
  },
};
