<template lang="pug">
    .profile-edit
        .profile-edit-wrapper 
            .profile-edit-head
                .profile-edit-title
                    .profile-edit-title-icon
                        svg.icon.icon-profile
                          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#profile")
                    span Личность
                <profile-back-btn/>
            .profile-progressbar-wrapper
                .profile-progressbar
                    .progressbar-current._progress-full
                    .progressbar-body
                        .progressbar-step-item._finished
                            .progressbar-circle 1
                            span Личная информация
                        .progressbar-step-item._finished
                            .progressbar-circle 2
                            span Детали документа
                        .progressbar-step-item._current
                            .progressbar-circle 3
                            span Загрузка документа
            .profile-personality-form-block
                form.profile-personality-form
                  .form-documents
                    kw-upload(
                      v-model="filePassportMain",
                      icon="user",
                      title="Загрузить основную страницу",
                      :min="Number(100 * 1000)"
                    )
                    kw-upload(
                      v-model="filePassportRegistration",
                      icon="location",
                      title="Загрузить страницу прописки",
                      :min="Number(100 * 1000)"
                    )

                .profile-document-requirements
                  ul
                    li
                      .profile-document-icon
                        svg.icon.icon-user
                          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#user")
                      span Сканируйте либо фотографируйте только оригиналы документов
                    li
                      .profile-document-icon
                        svg.icon.icon-capture
                          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#capture")
                      span Все 4 угла документа должны быть видны, края не должны быть обрезаны
                    li
                      .profile-document-icon
                        svg.icon.icon-picture
                          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#picture")
                      span Допустимые форматы: JPG, PDF, PNG
                    li
                      .profile-document-icon
                        svg.icon.icon-picture
                          use(xlink:href="@/assets/images/sprite/sprite-inline.svg#picture")
                      span Допустимый размер: 100 KB - 8 MB
                    

        .profile-edit-btn-wrapper
          button.button.profile-edit-save-btn(
              @click="save"
              )
            svg.icon.icon-check
              use(xlink:href="@/assets/images/sprite/sprite-inline.svg#check")
            | Сохранить
            


</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";
import KwUpload from "/src/components/profile/KwUpload";

const STORE = "personEdit";

export default {
  components: {
    KwUpload,
  },
  data() {
    return {
      filePassportMain: "",
      filePassportRegistration: "",
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(STORE, ["allTmpData", "personaldata", "address", "passport"]),
  },
  methods: {
    validModelData(v) {
      const valid = v instanceof File;
      return valid;
    },
    sendPassport() {
      const formData = new FormData();

      if (this.validModelData(this.filePassportMain)) {
        formData.set("main", this.filePassportMain);
      }
      if (this.validModelData(this.filePassportRegistration)) {
        formData.set("registration", this.filePassportRegistration);
      }

      if (
        !this.validModelData(this.filePassportRegistration) &&
        !this.validModelData(this.filePassportMain)
      ) {
        console.log(
          `[ ${LOG_LVL_LIST.INFO} ]	`,
          "UPLOAD PASSPORT: ",
          "Нет валидных файлов для отправки"
        );
        this.$router.push(`/person-edit-success`);
        return;
      }
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`);
        for (let v of formData.entries()) {
          console.log(v);
        }
      }

      this.$store
        .dispatch(`${STORE}/upload`, formData)
        .then((resp) => {
          this.$router.push(`/person-edit-success`);
        })
        .catch((err) => {});
    },
    save() {
      const data = this.allTmpData;
      const url = "/api/personaldata/";
      const method = "POST";
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, method, url, data);
      }
      axios({ method, url, data })
        .then((resp) => {
          if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
            console.log(
              `[ ${LOG_LVL_LIST.DEBUG} ]	`,
              url,
              "POST SUCCESS: ",
              resp
            );
          }
          this.$store.commit("setUser", {
            ...this.user,
            ...this.personaldata,
            address: this.address,
          });
        })
        .catch((err) => {
          if (isLogLevel(LOG_LVL_LIST.ERROR)) {
            console.log(
              `[ ${LOG_LVL_LIST.ERROR} ]	`,
              url,
              err,
              "POST RESPONSE: ",
              err.response
            );
          }
        })
        .finally(() => {
          this.sendPassport();
        });
    },
  },
  created() {
    this.$store
      .dispatch(`${STORE}/getPassport`)
      .then(() => {
        this.filePassportMain = this.passport?.files?.main || "";
        this.filePassportRegistration = this.passport?.files?.reg || "";
      })
      .finally(() => {
        this.$store.commit("loaderOff");
      });
  },
};
</script>

<style lang="scss"></style>
