// import state from "./state";
import * as getters from "./getters";
import * as mutations from "./mutations";
import * as actions from "./actions";
const CONST_PREFETCH = false;
const state = function () {
  let types = [],
    levelList = [],
    userList = [],
    productList = [],
    accountList = [],
    transactions = [],
    payments = [];
  if (process.env.NODE_ENV != "production" && CONST_PREFETCH) {
    types = [
      {
        type: "bank",
        title: "Банковский счёт",
        icon: "ancient-building",
        min: 1000,
        tax: "5 %",
        lag: "1 сутки",
        fields: [
          {
            id: "inn",
            title: "ИНН",
            type: "text",
          },
          {
            id: "account",
            title: "Номер счёта",
            type: "text",
          },
          {
            id: "bic",
            title: "Бик",
            type: "text",
          },
          {
            id: "cor-account",
            title: "Кор. счёта",
            type: "text",
          },
        ],
      },
      {
        type: "umoney",
        title: "ЮMoney",
        icon: "umoney1",
        min: 1000,
        tax: "5 %",
        lag: "1 сутки",
        fields: [
          {
            id: "account",
            title: "Номер счёта",
            type: "text",
          },
        ],
      },
      {
        type: "webmoney",
        title: "Webmoney",
        icon: "webmoney1",
        min: 1000,
        tax: "5 %",
        lag: "1 сутки",
        fields: [
          {
            id: "account",
            title: "Номер счёта",
            type: "text",
          },
        ],
      },
    ];
    levelList = [
      {
        id: 1,
        level: 1,
      },
      {
        id: 2,
        level: 2,
      },
      {
        id: 3,
        level: 3,
      },
      {
        id: 4,
        level: 4,
      },
      {
        id: 5,
        level: 5,
      },
    ];
    userList = [
      {
        id: 2,
        text: "Владимир С.",
      },
      {
        id: 3,
        text: "Анна У.",
      },
      {
        id: 4,
        text: "Константин Ц.",
      },
      {
        id: 5,
        text: "Кирилл Н.",
      },
      {
        id: 6,
        text: "Наталья К.",
      },
    ];
    productList = [
      {
        id: 1,
        text: "Продажа новостроек",
      },
      {
        id: 2,
        text: "Покупка под ремонт",
      },
      {
        id: 3,
        text: "Инвестирование",
      },
      {
        id: 4,
        text: "Продажа под ремонт",
      },
      {
        id: 5,
        text: "Услуга ремонта",
      },
    ];
    accountList = [
      {
        id: 1,
        type: "bank",
        fields: [
          {
            id: "inn",
            value: "444",
          },
          {
            id: "account",
            value: "333",
          },
          {
            id: "bic",
            value: "222",
          },
          {
            id: "cor-account",
            value: "1111",
          },
        ],
      },
      {
        id: 3,
        type: "webmoney",
        fields: [
          {
            id: "account",
            value: "asdf",
          },
        ],
      },
    ];
    transactions = [
      {
        id: "1",
        userId: "user1",
        productId: "1",
        user: "Владимир С.",
        title: "Зачисление средств по сделке № 27618",
        sum: 3100,
        isDebet: true,
        level: 1,
        product: "Продажа новостроек",
        icon: "building",
        date: "2021-12-27",
      },
      {
        userId: "user1",
        productId: "1",
        user: "Владимир С.",
        title: "Зачисление средств по сделке № 27618",
        sum: 1000,
        isDebet: false,
        level: 1,
        product: "Продажа новостроек",
        date: "2021-12-27",
      },
    ];
    payments = [
      {
        day: "2021-09-02",
        paymentData: [
          {
            id: 43872,
            title: "Вывод средств на расчётный счёт",
            sum: 30000,
            icon: "ancient-building",
            status: "check",
          },
        ],
      },
      {
        day: "2021-10-14",
        paymentData: [
          {
            id: 43873,
            title: "Вывод средств на расчётный счёт",
            sum: 30000,
            icon: "ancient-building",
            status: "check",
          },
          {
            id: 43874,
            title: "Вывод средств на Webmoney",
            sum: 340000,
            icon: "webmoney",
            status: "stop",
          },
        ],
      },
    ];
  }
  return {
    keys: ["payments", "transactions", "accountList", "types"],
    types,
    empty: true,
    balance: 0,
    frozen: 0,
    forecast: {
      month: null,
      value: 0,
    },
    levelList,
    userList,
    productList,
    accountList,
    transactionsAmount: 1,
    paymentsAmount: 0,
    transactions,
    payments,
  };
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
