<template lang="pug">
  .support
    .support-tabs(v-if="isHidden")
      .support-tabs-menu
          //- @click="sidebarButtonHandler(tab.component)"
          //  :class="[{selected: currentComponent===tab.component }]"
          router-link.support-tabs-menu-item( v-for='(tab, index) in tabs'
            :key="index"
            :to="`/support/` + tab.path"
            )
            svg(class="icon" :class="`icon-` + tab.icon")
              use(:xlink:href="require('@/assets/images/sprite/sprite-inline.svg') + '#' + tab.icon")
            span {{ tab.title }}
      .support-tabs-content
        <router-view/>
    //- .support-comp(v-else="!isHidden")
    //-   SupportForm
    //-   SupportChat


</template>

<script>
const STORE = "faq";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";
export default {
  name: "Support",
  data() {
    return {
      isHidden: true,
      tabs: [
        {
          icon: "question",
          title: "Часто задаваемые вопросы",
          component: "FAQ",
          path: "",
        },
        {
          icon: "comment",
          title: "Мои вопросы",
          component: "MyFAQ",
          path: "questions",
        },
      ],
      pageName: "Поддержка",
    };
  },
  methods: {
    changeTitle(pagename) {
      this.$emit("changeTitle", pagename);
    },
    emitCloseMenu() {
      this.$emit("closeMenu");
    },
  },
  created() {
    this.emitCloseMenu();
    this.$store.commit("loaderOn");
    this.$store
      .dispatch(`${STORE}/fetch`)
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(`[ ${LOG_LVL_LIST.ERROR} ]	`, STORE, "fetch", err);
        }
      })
      .finally(() => {
        this.$store.commit("loaderOff");
      });
  },
  mounted() {
    // this.$router.push(`/main/support/faq`);
    // this.$emit('changeTitle', 'Поддержка');
  },
  beforeUnmount() {
    this.$store.commit("loaderOff");
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.changeTitle(vm.pageName);
    });
  },
};
</script>
