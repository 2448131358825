<template lang="pug">

  TwofaForm(
    classForm = "login-form"
    buttonText = "Войти по смс"
    :isSms="isSms"
    @error="twofaError"
    inputType="number"
  )
    //- login field
    .form-field(:class="{validated: isLoginFieldValidated, focused: ( logFieldFocused || loginField), invalid: invalidLogin}")
      label.form-label() E-mail или телефон
      input.form-input(
          v-model = "loginField"
          type="text"
          @focus="logFieldFocused = true"
          @blur="onBlurInputHandler"
          @input="validateLoginField"
      )

      //- login error message
      <input-error :showVar="invalidLogin">
        <p>Введен неверный E-mail или телефон</p>
      </input-error>
</template>

<script>
import TwofaForm from "/src/components/kwLogin/twofa";

import validateEmailBool from "@/mixins/validateEmailBool";
import replaceNumberForInput from "@/mixins/replaceNumberForInput";

import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export default {
  components: {
    TwofaForm,
  },
  mixins: [validateEmailBool, replaceNumberForInput],
  data() {
    return {
      logFieldFocused: false,
      isLoginFieldValidated: false,
      loginField: "",
      loginValue: "",
      invalidLogin: false,
      isSms: false,
    };
  },
  methods: {
    twofaError(data) {
      this.invalidLogin = true;
    },
    onBlurInputHandler() {
      if (this.loginField === "") {
        this.logFieldFocused = false;
      }
    },
    validateLoginField() {
      this.invalidLogin = false;
      this.isLoginFieldValidated = false;
      let regCheckForPhone = /^\+?7?\s?\(?\d{3}.*/;
      // let regCheckForPhone = /^\d{3}/;
      this.loginValue = this.loginField.replace(/\D/g, "").substr(0, 11);
      if (
        regCheckForPhone.test(this.loginField.slice(0, 7)) &&
        this.loginValue.length >= 3
      ) {
        // console.log('the login field must be the phone number');
        this.loginField = this.replaceNumberForInput(this.loginField);
        if (this.loginValue.length === 11) {
          this.isLoginFieldValidated = true;
          this.isSms = true;
          this.$store.commit("changeEnteredLogin", this.loginField);
        } else {
          this.isLoginFieldValidated = false;
          this.isSms = false;
        }
      } else if (this.loginField.length >= 5) {
        // console.log('the login field must be the email');
        if (this.validateEmailBool(this.loginField)) {
          this.isLoginFieldValidated = true;
          this.isSms = false;
        } else {
          this.isLoginFieldValidated = false;
        }
      }
    },
  },
  computed: {},
  watch: {},
  mounted() {
    if (this.$store.state.enteredLogin) {
      this.logFieldFocused = true;
      this.loginField = this.$store.state.enteredLogin;
      this.validateLoginField();
    }
  },
};
</script>

<style></style>
