// import state from "./state";
import * as getters from "./getters";
import * as mutations from "./mutations";
import * as actions from "./actions";

const state = function () {
  return {
    blocks: [],
  };
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
