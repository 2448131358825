import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueTimeago from "vue-timeago";
import VueCompositionAPI from "@vue/composition-api";
import components from "@/components/UI";
// import VueTree from "@ssthouse/vue-tree-chart";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/ru";
import vuescroll from "vuescroll";

Vue.config.productionTip = false;

import InlineSvg from "vue-inline-svg";
import VueClipboards from "vue-clipboards";
import VueNativeSock from "vue-native-websocket";
Vue.use(VueNativeSock, process.env.VUE_APP_WS_URL, {
  store: store,
  format: "json",
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  reconnectionAttempts: parseInt(process.env.VUE_APP_WS_RECONNECT_COUNT, 10), // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: parseInt(process.env.VUE_APP_WS_RECONNECT_DELAY, 10), // (Number) how long to initially wait before attempting a new (1000)
});

import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";
import Axios from "axios";
Vue.prototype.$http = Axios;
Vue.prototype.$http.defaults.baseURL = process.env.VUE_APP_API_URL;
// Vue.prototype.$http.defaults.withCredentials = true;

const token = localStorage.getItem("token") || "";
if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
  console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "token", token);
}
if (token) {
  store.commit("loaderOn");
  Vue.prototype.$http.defaults.headers.common["Authorization"] = token;
  Promise.all([
    store.dispatch("updateUser"),
    store.dispatch("notifications/fetch"),
    store.dispatch("wallet/fetch"),
  ])
    .catch(() => {
      if (isLogLevel(LOG_LVL_LIST.ERROR)) {
        console.log(`[ ${LOG_LVL_LIST.ERROR} ]	`, "main", "fetch", err);
      }
    })
    .finally(() => {
      store.commit("loaderOff");
    });
}
// else if (process.env.NODE_ENV === "development") {
//   localStorage.setItem("token", "dev token");
// }
Vue.use(VueTimeago, {
  name: "Timeago",
  locale: "ru",
  locales: {
    ru: require("date-fns/locale/ru"),
  },
});
Vue.use(VueClipboards);
Vue.use(VueCompositionAPI);
Vue.use(vuescroll, {
  ops: {
    // The global config
    // mode: 'slide',
    scrollPanel: {
      initialScrollY: false,
      initialScrollX: false,
      scrollingX: false,
      scrollingY: true,
      speed: 300,
      easing: undefined,
      verticalNativeBarPos: "right",
    },
    rail: {
      background: "#DFE1E3",
      // background: '#01a99a',
      opacity: 0,
      size: "8px",
      specifyBorderRadius: false,
      gutterOfEnds: null,
      gutterOfSide: "2px",
      // gutterOfEnds: '100px',
      gutterOfSide: "4px",
      keepShow: false,
      detectResize: true,
      // keepShow: true,
    },
    bar: {
      showDelay: 1000,
      onlyShowBarOnScroll: true,
      keepShow: false,
      background: "#DFE1E3",
      opacity: 1,
      hoverStyle: false,
      specifyBorderRadius: "10px",
      // specifyBorderRadius: false,
      minSize: 0,
      size: "8px",
      disable: false,
    },
  },
});

import "@/styles/style.scss";
import "vue2-datepicker/index.css";

components.forEach((component) => {
  Vue.component(component.name, component);
});
// Vue.component("vue-tree", VueTree);
Vue.component("inline-svg", InlineSvg);
Vue.component("date-picker", DatePicker);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
