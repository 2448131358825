export default {
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    onResize() {
      if (window.matchMedia("(max-width: 767px)").matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
};
