import Vue from "vue";
import Vuex from "vuex";
import router from "@/router/index";
import axios from "axios";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

Vue.use(Vuex);
const context = require.context("/src/store/modules", true, /index.js/);
const modules = {};

context.keys().forEach((file) => {
  // create the module name from file
  const moduleName = file.replace(/(\.\/|\/index\.js$)/g, "");

  // register file context under module name
  modules[moduleName] = context(file).default || context(file);

  // override namespaced option
  modules[moduleName].namespaced = true;
});

let loaderCount = 0;
export default new Vuex.Store({
  state: {
    loader: false,
    code: localStorage.getItem("invite") || "",
    enteredLogin: "",
    status: "",
    token: localStorage.getItem("token") || "",
    user: {
      id: 1,
      name: "",
      avatar: "",
      surname: "",
      nickname: "",
      fathername: "",
      birthday: "",
      address: "",
      country: "",
      rank: "",
      invite: {
        link: "",
        count: null,
        lastuser: "asdf",
      },
      visits: null,
      email: "",
      phone: "",
      verify: false,
    },
    socket: {
      isConnected: false,
      message: "",
      reconnectError: false,
    },
  },
  mutations: {
    changeEnteredLogin(state, value) {
      state.enteredLogin = value;
    },
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, data) {
      const token = data.token;
      const user = data.user;
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "auth_success: ", token, user);
      }
      state.status = "success";
      localStorage.setItem("token", token || "");
      axios.defaults.headers.common["Authorization"] = token || "";
      this.commit("setToken", token || "");
      this.commit("setUser", user);
    },
    setToken(state, token) {
      state.token = token;
    },
    setUser(state, user) {
      Vue.set(state, "user", { ...state.user, ...user });
    },
    invite_success(state, code) {
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "invite_success: ", code);
      }
      localStorage.setItem("invite", code);
      state.code = code;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
    },
    loaderOn(state) {
      loaderCount += 1;
      state.loader = true;
      // console.log("on", loaderCount);
    },
    loaderOff(state) {
      if (loaderCount > 0) loaderCount -= 1;
      if (loaderCount === 0) state.loader = false;
      // console.log("off", loaderCount);
    },
    SOCKET_ONOPEN(state, ws) {
      state.socket.isConnected = true;
      state.socket.reconnectError = false;
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "SOCKET_ONOPEN");
      }
      Vue.prototype.$socket.sendObj({ type: "auth", token: state.token });
      Vue.prototype.$socket.sendObj({
        type: "subscribe_events",
        token: state.token,
        data_type: "notification-item",
        namespace: "notifications",
        mutation: "SOCKET_ADD",
      });
      Vue.prototype.$socket.sendObj({
        type: "subscribe_events",
        token: state.token,
        data_type: "chat-list-item",
        namespace: "chatlist",
        mutation: "SOCKET_SET",
      });
      Vue.prototype.$socket.sendObj({
        type: "subscribe_events",
        token: state.token,
        data_type: "wallet-data",
        namespace: "wallet",
        mutation: "SOCKET_FETCH_ALL",
      });
      Vue.prototype.$socket.sendObj({
        type: "subscribe_events",
        token: state.token,
        data_type: "invite",
        mutation: "SOCKET_UPD_INVITE",
      });
    },
    SOCKET_ONCLOSE(state, ws) {
      state.socket.isConnected = false;
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "SOCKET_ONCLOSE");
      }
    },
    SOCKET_RECONNECT(state, count) {
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "SOCKET_RECONNECT", count);
      }
    },
    SOCKET_RECONNECT_ERROR(state) {
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "SOCKET_RECONNECT_ERROR");
      }
      state.socket.reconnectError = true;
    },
    SOCKET_ONERROR(state, ws) {
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "SOCKET_ONERROR");
      }
    },
    SOCKET_ONMESSAGE(state, message) {
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "SOCKET_ONMESSAGE", message);
      }
      state.socket.message = message;
    },
    SOCKET_UPD_INVITE(state, data) {
      this.commit("setUser", { invite: data.value });
    },
  },
  actions: {
    login({ commit }, data) {
      return new Promise((resolve, reject) => {
        const url = "/api/login/";
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "POST", url, data);
        }
        commit("auth_request", data);
        commit("changeEnteredLogin", data?.login);
        axios({ method: "POST", url, data })
          .then((resp) => {
            if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
              console.log(
                `[ ${LOG_LVL_LIST.DEBUG} ]	`,
                url,
                "POST SUCCESS: ",
                resp
              );
            }
            commit("auth_success", { ...resp.data });
            resolve(resp);
          })
          .catch((err) => {
            if (isLogLevel(LOG_LVL_LIST.ERROR)) {
              console.log(
                `[ ${LOG_LVL_LIST.ERROR} ]	`,
                url,
                err,
                "POST RESPONSE: ",
                err.response
              );
            }
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    invite({ commit, state }, code) {
      return new Promise((resolve, reject) => {
        const data = { login: state.enteredLogin, code };
        const url = "/api/checkinvite/";
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "POST", url, data);
        }
        axios({
          method: "POST",
          url,
          data,
        })
          .then((resp) => {
            if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
              console.log(
                `[ ${LOG_LVL_LIST.DEBUG} ]	`,
                url,
                "POST SUCCESS: ",
                resp
              );
            }
            commit("invite_success", code);
            resolve(resp);
          })
          .catch((err) => {
            if (isLogLevel(LOG_LVL_LIST.ERROR)) {
              console.log(
                `[ ${LOG_LVL_LIST.ERROR} ]	`,
                url,
                err,
                "POST RESPONSE: ",
                err.response
              );
            }
            reject(err);
          });
      });
    },
    register({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        const url = "/api/register/";
        commit("auth_request");
        commit("changeEnteredLogin", data.email || data.phone);
        data.code = state.code;
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "POST", url, data);
        }
        axios({
          method: "POST",
          url,
          data,
        })
          .then((resp) => {
            if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
              console.log(
                `[ ${LOG_LVL_LIST.DEBUG} ]	`,
                url,
                "POST SUCCESS: ",
                resp
              );
            }
            resolve(resp);
          })
          .catch((err) => {
            if (isLogLevel(LOG_LVL_LIST.ERROR)) {
              console.log(
                `[ ${LOG_LVL_LIST.ERROR} ]	`,
                url,
                err,
                "POST RESPONSE: ",
                err.response
              );
            }
            commit("auth_error", err);
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    logout({ commit }) {
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "logout");
      }
      return new Promise((resolve, reject) => {
        commit("logout");
        localStorage.removeItem("token");
        delete axios.defaults.headers.common["Authorization"];
        router.push("/login");
        resolve();
      });
    },
    updateUser({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        const url = "/api/user/";
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "GET", url);
        }
        axios({ method: "GET", url })
          .then((resp) => {
            if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
              console.log(
                `[ ${LOG_LVL_LIST.DEBUG} ]	`,
                url,
                "GET SUCCESS: ",
                resp
              );
            }
            const user = resp.data;
            commit("setUser", user);
            resolve(resp);
          })
          .catch((err) => {
            const resp = err?.response || null;
            if (isLogLevel(LOG_LVL_LIST.ERROR)) {
              console.log(
                `[ ${LOG_LVL_LIST.ERROR} ]	`,
                url,
                err,
                "GET RESPONSE: ",
                resp
              );
            }
            if (resp.status === 401) {
              dispatch("logout");
            }
            reject(err);
          });
      });
    },
    setMetaItem({ commit, state, dispatch }, data) {
      const url = "/api/personalmeta/";
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "POST", url, data);
      }
      return axios({ method: "POST", url, data })
        .then((resp) => {
          if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
            console.log(
              `[ ${LOG_LVL_LIST.DEBUG} ]	`,
              url,
              "POST SUCCESS: ",
              resp
            );
          }
          const token = resp.data?.token || "";
          if (token) {
            commit("setToken", token);
          }
          commit("setUser", data);
          return Promise.resolve();
        })
        .catch((err) => {
          const resp = err?.response || null;
          if (isLogLevel(LOG_LVL_LIST.ERROR)) {
            console.log(
              `[ ${LOG_LVL_LIST.ERROR} ]	`,
              url,
              err,
              "POST RESPONSE: ",
              resp
            );
          }
          if (resp.status === 401) {
            dispatch("logout");
          }
          return Promise.reject(err?.response?.data?.statusText);
        });
    },
    newPassword({ commit, dispatch }, password) {
      const url = "/api/password/";
      const data = { new: password };
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "POST", url, data);
      }
      return new Promise((resolve, reject) => {
        axios({ method: "POST", url, data })
          .then((resp) => {
            if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
              console.log(
                `[ ${LOG_LVL_LIST.DEBUG} ]	`,
                url,
                "POST SUCCESS: ",
                resp
              );
            }
            const token = resp.data.token;
            if (token) {
              commit("setToken", token);
            }
            resolve();
          })
          .catch((err) => {
            const resp = err?.response || null;
            if (isLogLevel(LOG_LVL_LIST.ERROR)) {
              console.log(
                `[ ${LOG_LVL_LIST.ERROR} ]	`,
                url,
                err,
                "POST RESPONSE: ",
                resp
              );
            }
            if (resp.status === 401) {
              dispatch("logout");
            }
            reject(resp);
          });
      });
    },
  },
  getters: {
    wsIsConnected: (state) => state?.socket?.isConnected || false,
    loader: (state) => state?.loader || false,
    verify: (state) => state?.user?.verify || false,
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    token: (state) => state.token,
    avatar: (state) => state?.user?.avatar || "",
    user: (state) => {
      return state.user;
    },
    title: (state) => {
      return state.user.nickname
        ? state.user.nickname
        : `${state.user.name} ${state.user.surname}`;
    },
    me: (state) => {
      const surname = state.user.surname.slice(0, 1).toUpperCase();
      return {
        username: state.user.nickname || `${state.user.name} ${surname}`,
        avatar: state.user.avatar,
      };
    },
    address: (state) => {
      return state.user.address || "";
    },
  },
  modules: {
    ...modules,
  },
});
