import axios from "axios";
import { isLogLevel, LOG_LVL_LIST } from "/src/store/const";

export function fetch({ commit }) {
  const url = "/api/notifications/";
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "GET", url);
  }

  return new Promise((resolve, reject) => {
    axios({ method: "GET", url })
      .then((resp) => {
        const data = resp.data;
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, url, "GET SUCCESS", data);
        }
        commit("FETCH_ALL", data);
        resolve();
      })
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(
            `[ ${LOG_LVL_LIST.ERROR} ]	`,
            url,
            err,
            "GET RESPONSE: ",
            err.response
          );
        }
        reject();
      });
  });
}

export function read({ commit, getters }, index = -1) {
  if (index === -1) {
    commit("read");
  } else if (getters.notifyItem(index)) {
    commit("read", index);
    const data = getters.notifyItem(index);
    return new Promise((resolve, reject) => {
      const url = "/api/notifications/";
      if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
        console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "PUT", url, data);
      }
      axios({ method: "PUT", url, data })
        .then((resp) => {
          if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
            console.log(
              `[ ${LOG_LVL_LIST.DEBUG} ]	`,
              url,
              "PUT SUCCESS: ",
              resp
            );
          }
          resolve();
        })
        .catch((err) => {
          if (isLogLevel(LOG_LVL_LIST.ERROR)) {
            console.log(
              `[ ${LOG_LVL_LIST.ERROR} ]	`,
              url,
              err,
              "PUT RESPONSE: ",
              err.response
            );
          }
          reject();
        });
    });
  }
}

export function remove({ commit, getters }, index = -1) {
  const url = "/api/notifications/";
  let data = {};
  if (index !== -1) {
    data = { ...getters.notifyItem(index) };
  }
  if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
    console.log(`[ ${LOG_LVL_LIST.DEBUG} ]	`, "DELETE", url, data);
  }
  return new Promise((resolve, reject) => {
    axios({ method: "DELETE", url, data })
      .then((resp) => {
        if (isLogLevel(LOG_LVL_LIST.DEBUG)) {
          console.log(
            `[ ${LOG_LVL_LIST.DEBUG} ]	`,
            url,
            "DELETE SUCCESS: ",
            resp
          );
        }
        if (data.hasOwnProperty("title")) {
          commit("REMOVE", index);
        } else {
          commit("CLEAR_ALL");
        }
        resolve();
      })
      .catch((err) => {
        if (isLogLevel(LOG_LVL_LIST.ERROR)) {
          console.log(
            `[ ${LOG_LVL_LIST.ERROR} ]	`,
            url,
            err,
            "DELETE RESPONSE: ",
            err.response
          );
        }
        reject();
      });
  });
}
