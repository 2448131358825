import { render, staticRenderFns } from "./PersonalityEditingStep2.vue?vue&type=template&id=754a5208&scoped=true&lang=pug&"
import script from "./PersonalityEditingStep2.vue?vue&type=script&lang=js&"
export * from "./PersonalityEditingStep2.vue?vue&type=script&lang=js&"
import style0 from "./PersonalityEditingStep2.vue?vue&type=style&index=0&id=754a5208&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "754a5208",
  null
  
)

export default component.exports