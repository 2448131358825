<template lang="pug">
  button.button(
    @click="click"
    )
    svg(
      v-if="icon"
      class="icon"
      :class="[`icon-${icon}`]"
      )
      use(:xlink:href="require(`@/assets/images/sprite/sprite-inline.svg`) + '#' + icon")
    <slot></slot>
</template>

<script>
export default {
  name: "button-base",
  props: {
    icon: {
      type: String,
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>

<style></style>
