export default {
  data() {
    return {
      seconds: 60,
      resendLock: false,
    };
  },
  methods: {
    resendCode() {
      if (!this.resendLock) {
        this.resendLock = true;
        let timer = setInterval(() => {
          if (this.seconds > 1) {
            this.seconds--;
          } else {
            clearInterval(timer);
            this.resendLock = false;
            this.seconds = 60;
          }
        }, 1000);
      }
    },
  },
};
